import { Box, Grid } from "@mui/material";
import { GREY_100 } from "../../../theme/colors";
import { formatChargeDate, formatter } from "../utilities";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";

type PastDueNextPaymentComponentProps = {
    student: PaymentsStudent;
};
/**
 * Small helper component to render next payment information.
 *
 * Only intended to be displayed on the billing overview page if student status
 * is past due and they also have an upcoming next payment date.
 */
export const PastDueNextPaymentComponent = ({ student }: PastDueNextPaymentComponentProps) => {
    return (
        <Box
            sx={{
                paddingTop: "1rem",
            }}
        >
            <Grid
                container
                justifyContent="space-between"
                sx={{
                    paddingTop: "0.75rem",
                    borderTop: `1px solid ${GREY_100}`,
                }}
            >
                <Grid item>
                    Next payment -{" "}
                    {formatChargeDate(student.nextPaymentDueDate!, {
                        dateOnly: true,
                        useShortMonthFormat: true,
                    })}
                </Grid>
                <Grid item>{formatter.format(student.nextPaymentAmount)}</Grid>
            </Grid>
        </Box>
    );
};

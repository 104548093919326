import { SvgIcon, SvgIconProps } from "@mui/material";

export const CloseIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg width="20" height="20" viewBox="0 0 16 16" fill="none">
            <path
                d="M14 2L2 14M2 2L14 14"
                stroke={"currentColor"}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

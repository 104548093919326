import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import arrowLeft from "../../assets/arrow_left.svg";
import MeadowContainer from "../container";
import { useNavigate } from "react-router-dom";
import { BLUE_700 } from "../../theme/colors";
import { getLocationOrigin } from "../../services/utilities";

export const TermsOfUse = () => {
    const navigate = useNavigate();

    const privacyLinkPath = "/legal/privacy";
    const privacyLinkCopy = getLocationOrigin().concat(privacyLinkPath);

    return (
        <MeadowContainer fullWidth sx={{ padding: "1.5rem" }}>
            <Box>
                <Box>
                    <Button sx={{ justifyContent: "left" }} onClick={() => navigate(-1)}>
                        <img src={arrowLeft} />
                    </Button>
                    <Typography variant="h1">Terms & Conditions</Typography>
                </Box>
                <Typography variant="body2">
                    <p>Meadow Technologies, Inc.</p>
                    <p>Terms of Service</p>
                    <p>Last updated: July 6th, 2023</p>
                    <p>
                        These Terms of Service constitute a legally binding agreement between you
                        and Meadow Technologies, Inc. (together with its affiliates, “Meadow”, “we,”
                        “our” or “us”) governing your use of our products, services, and website
                        (the “Site” and collectively with the foregoing, the “Services”).{" "}
                    </p>
                    <p>
                        YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING ON THE “I AGREE” OR SIMILAR
                        BUTTON, REGISTERING FOR AN ACCOUNT OR ACCESSING OR USING THE SERVICES, YOU
                        ARE INDICATING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE
                        TERMS OF SERVICE, WHETHER OR NOT YOU HAVE REGISTERED WITH THE SITE. IF YOU
                        DO NOT AGREE TO THESE TERMS OF SERVICE, THEN YOU HAVE NO RIGHT TO ACCESS OR
                        USE THE SERVICES. These Terms of Service are effective as of the date you
                        first click “I agree” (or similar button or checkbox) or use or access the
                        Services, whichever is earlier. If you accept or agree to these Terms of
                        Service on behalf of your employer or another legal entity, you represent
                        and warrant that (i) you have full legal authority to bind your employer or
                        such entity to these Terms of Services; (ii) you have read and understand
                        these Terms of Service; and (iii) you agree to these Terms of Service on
                        behalf of the party that you represent. In such event, “you” and “your” will
                        refer and apply to your employer or such other legal entity.{" "}
                    </p>
                    <p>
                        Any personal data you submit to us or which we collect about you is governed
                        by our Privacy Policy (“Privacy Policy”), available at{" "}
                        <a
                            href={privacyLinkPath}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: BLUE_700 }}
                        >
                            {privacyLinkCopy}.
                        </a>{" "}
                        You acknowledge that by using the Services, you have reviewed the Privacy
                        Policy. The Privacy Policy is incorporated by reference into these Terms of
                        Service and together form and are hereinafter referred to as this
                        “Agreement.”
                    </p>
                    <p>
                        PLEASE NOTE: THIS AGREEMENT GOVERNS HOW DISPUTES BETWEEN YOU AND MEADOW CAN
                        BE RESOLVED. IT CONTAINS A BINDING AND FINAL ARBITRATION PROVISION AND CLASS
                        ACTION WAIVER (SECTION 10). PLEASE READ CAREFULLY AS IT AFFECTS YOUR LEGAL
                        RIGHTS, INCLUDING, IF APPLICABLE, YOUR RIGHT TO OPT OUT OF ARBITRATION.
                    </p>
                    <p>1. Our Services and Payments</p>
                    <p>
                        Meadow is an integrated student financial engagement solution that helps
                        institutions resolve student balances sooner by enhancing student
                        information systems with personalized communications, a net price
                        calculator, student-friendly bill presentment, and mobile payments for a
                        superior student billing experience.
                    </p>
                    <p>
                        All payments made by you or your trusted allies in connection with the
                        Services are made via a third-party payment processing system (the “PSP”),
                        and not by or through Meadow. You and/or your trusted allies may be required
                        to register with the PSP, agree to terms of service of the PSP, provide your
                        payment or remittance details to the PSP and go through a vetting process at
                        the request of the PSP to set up an account with the PSP (the “PSP Services
                        Agreement”). Please note that Meadow is not a party to the PSP Services
                        Agreement and that you, your trusted allies, the PSP and any other parties
                        listed in the PSP Services Agreement are the parties to the PSP Services
                        Agreement and that Meadow has no obligations, responsibility or liability to
                        you or any other party under the PSP Services Agreement.
                    </p>
                    <p>2. Account, Password, Security, and Mobile Phone Use</p>
                    <p>
                        You must register with Meadow and create an account to use the Services (an
                        “Account”) and as part of that process you will be requested to provide
                        certain information, including without limitation your name, phone number
                        and email address. By using the Services, you agree to provide true,
                        accurate, current and complete information as prompted by the registration
                        process and to maintain and promptly update the Account information to keep
                        it accurate, current and complete. You are the sole authorized user of your
                        Account. You are responsible for maintaining the confidentiality of any
                        log-in, password, and Account number provided by you or given to you by
                        Meadow for accessing the Services. You are solely and fully responsible for
                        all activities that occur under your password or Account, even if not
                        authorized by you. Meadow has no control over the use of any user’s Account
                        and expressly disclaims any liability derived therefrom. Should you suspect
                        that any unauthorized party may be using your password or Account or you
                        suspect any other breach of security, you agree to contact Meadow
                        immediately.
                    </p>
                    <p>
                        The person signing up for the Services will be the contracting party
                        (“Account Owner”) for the purposes of these Terms of Service and will be the
                        person who is authorized to use any corresponding Account we provide to the
                        Account Owner in connection with the Services. As the Account Owner, you are
                        solely responsible for complying with these Terms of Service and only you
                        are entitled to all benefits accruing thereto. Your Account is not
                        transferable to any other person or account. You must immediately notify us
                        of any unauthorized use of your password or identification or any other
                        breach or threatened breach of our security or the security of your Account.{" "}
                    </p>
                    <p>
                        By providing your mobile phone number and using the Services, you hereby
                        affirmatively consent to Meadow’s use of your mobile phone number for calls
                        and recurring texts, (including with an autodialer and/or prerecorded voice)
                        in order to (i) perform and improve upon the Services, (ii) facilitate the
                        carrying out our Services, and (iii) provide you with information and
                        reminders regarding your registration, changes and updates, service outages
                        or alterations. Meadow will not assess any charges for calls or texts, but
                        standard message, data or other charges from your wireless carrier may
                        apply. You may opt-out of receiving text messages from us by modifying your
                        Account settings on the Services, texting “STOP” in response to any texts,
                        or by emailing support@meadowfi.com and specifying you want to opt-out of
                        texts. You may opt-out of receiving calls from us by stating that you no
                        longer wish to receive calls during any call with us, or by emailing
                        support@meadowfi.com and specifying you want to opt-out of calls. You
                        understand that we may send you a text confirming any opt-out by you.
                    </p>
                    <p>
                        By providing your email address and using the Services, you hereby
                        affirmatively consent to Meadow’s use of your email address to send messages
                        to you in order to (i) perform and improve upon the Services, (ii)
                        facilitate the carrying out our Services, and (iii) provide you with
                        information and reminders regarding your registration, changes and updates,
                        service outages or alterations. You may opt-out of receiving emails from us
                        by contact us at support@meadowfi.com and specifying you want to opt-out of
                        emails. You understand that we may send you an email confirming any opt-out
                        by you.
                    </p>
                    <p>3. Representations and Warranties </p>
                    <p>
                        You represent and warrant that: (i) you (a) are 18 years of age or older,
                        (b) are at least of the legally required age in the jurisdiction in which
                        you reside, and are otherwise capable of entering into binding contracts or
                        (c) are at least 13 years of age and are using the Services with your
                        parents’ or legal guardians’ knowledge, involvement and permission, (ii)
                        you, or your parents or guardians, as applicable, have the right, authority
                        and capacity to enter into this Agreement and to abide by the terms and
                        conditions of this Agreement, and that you will so abide and (iii) you have
                        not previously been suspended or removed from the Services. Where you enter
                        into this Agreement on behalf of a company or other organization, you
                        represent and warrant that you have authority to act on behalf of that
                        entity and to bind that entity to this Agreement.
                    </p>
                    <p>
                        You further represent and warrant that (i) you have read, understand, and
                        agree to be bound by these Terms of Service and the Privacy Policy in order
                        to access and use the Services and (ii) when using or accessing the
                        Services, you will act in accordance with any applicable local, state, or
                        federal law or custom and in good faith.
                    </p>
                    <p>
                        You agree not to engage in any of the following prohibited activities, among
                        others: (i) copying, distributing, or disclosing any part of the Services in
                        any medium other than as allowed by the Services and these Terms of Service;
                        (ii) using any automated system (other than any functionalities of the
                        Services), including without limitation “robots,” “spiders,” “offline
                        readers,” etc., to access the Services; (iii) transmitting spam, chain
                        letters, or other unsolicited email or attempting to phish, pharm, pretext,
                        spider, crawl, or scrape; (iv) attempting to interfere with, compromise the
                        system integrity or security or decipher any transmissions to or from the
                        servers running the Services or manipulating the system or its features to
                        gain unfair advantages or exploit vulnerabilities; (v) violating any
                        international, federal, provincial or state regulations, rules, laws, or
                        local ordinances; (vi) conducting any unlawful purposes or soliciting others
                        to perform or participate in any unlawful acts; (vii) using the system to
                        launder money, evade taxes, hide the origin of funds or facilitate, support
                        any activities related to terrorism or provide funding to terrorist
                        organizations; (viii) uploading invalid data, viruses, worms, or other
                        software agents through the Services; (ix) infringing upon or violate our
                        intellectual property rights or the intellectual property rights of others;
                        (x) impersonating another person or otherwise misrepresenting your
                        affiliation with a person or entity (or your endorsement or sponsorship
                        thereby), conducting fraud, hiding or attempting to hide your identity or
                        using stolen payment information; (xi) harassing, insulting, harming,
                        abusing, defaming, abusing, harassing, stalking, threatening, intimidating
                        or otherwise violating the legal rights (such as of privacy and publicity)
                        of any other users or visitors of the Services or staff member of Meadow;
                        (xii) interfering with or any activity that threatens the performance,
                        security or proper functioning of the Services; (xiii) uploading or
                        transmitting viruses or any other type of malicious code; (xiv) attempting
                        to decipher, decompile, disassemble or reverse engineer any of the software
                        or algorithms used to provide the Services; (xv) bypassing the security
                        features or measures we may use to prevent or restrict access to the
                        Services, including without limitation features that prevent or restrict use
                        or copying of any content or enforce limitations on use of the Services or
                        the content therein; (xvi) attempting to access unauthorized Accounts or to
                        collect or track the personal information of others; (xvii) using the
                        Services for any purpose or in any manner that infringes the rights of any
                        third party; or (xviii) encouraging or enabling any other individual to do
                        any of the foregoing.{" "}
                    </p>
                    <p>
                        You hereby warrant and represent that, other than as fully and promptly
                        disclosed to Meadow as set forth below, you do not have any motivation,
                        status, or interest which Meadow may reasonably wish to know about in
                        connection with the Services, including without limitation, if you are using
                        or will or intend to use the Services for any journalistic, investigative,
                        or unlawful purpose. You hereby warrant and represent that you will promptly
                        disclose to Meadow in writing any such motivation, status or interest,
                        whether existing prior to registration or as arises during your use of the
                        Services.
                    </p>
                    <p>4. Termination and Suspension</p>
                    <p>
                        Unless otherwise agreed to in writing between you and Meadow, either party
                        may terminate these Terms of Service for any or no cause, at any time. You
                        may cancel and delete your Account at any time by either using the features
                        on the Services to do so (if applicable and available) or by written notice
                        to support@meadowfi.com. After cancellation, you will no longer have access
                        to your Account, your profile or any other information through the Services.
                        The provisions of these Terms of Service which by their intent or meaning
                        intended to survive such termination, including without limitation the
                        provisions relating to disclaimer of warranties, limitations of liability,
                        and indemnification, shall survive the any termination of these Terms of
                        Service and any termination of your use of or subscription to the Services
                        and shall continue to apply indefinitely.{" "}
                    </p>
                    <p>
                        We reserve the right to refuse the Services to anyone for any reason at any
                        time. Meadow may terminate or limit your right to use the Services in the
                        event that we are investigating or believe that you have breached any
                        provision of this Agreement, by providing you with written or email notice.
                        Such termination or limitation will be effective immediately upon delivery
                        of such notice. If Meadow terminates or limits your right to use the
                        Services pursuant to this section, you are prohibited from registering and
                        creating a new Account under your name, a fake or borrowed name, or the name
                        of any third party, even if you may be acting on behalf of the third party.{" "}
                    </p>
                    <p>
                        Even after your right to use the Services is terminated or limited, this
                        Agreement will remain enforceable against you. Meadow reserves the right to
                        take appropriate legal action, including but not limited to pursuing
                        arbitration in accordance with Section 10 of these Terms of Service.
                    </p>
                    <p>
                        Meadow reserves the right to modify or discontinue, temporarily or
                        permanently, all or any portion of the Services at its sole discretion.
                        Meadow is not liable to you for any modification or discontinuance of all or
                        any portion of the Services. Meadow has the right to restrict anyone from
                        completing registration as a user if Meadow believes such person may
                        threaten the safety and integrity of the Services, or if, in Meadow’s
                        discretion, such restriction is necessary to address any other reasonable
                        business concern.
                    </p>
                    <p>
                        Following the termination or cancellation of your Account (as defined
                        below), we reserve the right to delete all your data, in the normal course
                        of operation. Your data cannot be recovered once your Account is terminated
                        or cancelled.
                    </p>
                    <p>5. Links to Third-Party Websites and Services</p>
                    <p>
                        The Services may contain links (such as hyperlinks) to third-party websites
                        and services. Such links do not constitute endorsement by Meadow or
                        association with those websites and services, their content or their
                        operators. Such links (including without limitation external websites that
                        are framed by the Services as well as any advertisements displayed in
                        connection therewith) are provided as an information service, for reference
                        and convenience only. Meadow does not control any such websites or services,
                        and is not responsible for their (i) availability or accuracy, or (ii)
                        content, advertising, products, or services. It is your responsibility to
                        evaluate the content and usefulness of the information obtained from other
                        websites. You acknowledge and agree that Meadow is not involved in the
                        creation or development of third-party websites or services and disclaims
                        any responsibility for third-party websites and services, and cannot be
                        liable for claims arising out of or relating to third-party websites or
                        services. Further, you acknowledge and agree that Meadow has no obligation
                        to monitor, review, or remove links to third-party websites and services,
                        but reserves the right to limit or remove links to third-party websites or
                        services on the Services at its sole discretion.
                    </p>
                    <p>
                        The use of any website or service controlled, owned or operated by third
                        parties is governed by the terms and conditions of use and privacy policies
                        for those websites or services. You access such third-party websites or
                        services at your own risk. Meadow expressly disclaims any liability arising
                        in connection with your use and/or viewing of any websites, services or
                        other material associated with links that may appear on the Services. You
                        hereby agree to hold Meadow harmless from any liability that may result from
                        the use of links that may appear on the Services.
                    </p>
                    <p>6. Intellectual Property Rights</p>
                    <p>
                        Subject to your complete and ongoing compliance with these Terms of Service,
                        Meadow grants you, solely for your personal, non-commercial use, a limited,
                        non-exclusive, non-transferable, non-sublicensable, revocable license to
                        access and use the Services.
                    </p>
                    <p>
                        Student Data (defined below) provided to Meadow by use of the Services is
                        governed by the terms of the agreement between Meadow and the educational
                        institution relevant to your use of the Services. As between Meadow and you,
                        you and/or the educational institution own all right, title and interest to
                        all Student Data you provide or otherwise make available to us, and we do
                        not own, control, or license such Student Data, except so as to provide the
                        Services to you and to the educational institution that provides the
                        products and controls Student Data from the educational records, if
                        applicable, and as described herein. “Student Data” is any information (in
                        any format) that is directly related to an identifiable current or former
                        student that is maintained by a school or related entity or organization, or
                        by us, as part of the provision of the Services. Student Data may include
                        educational records as defined by the Family Educational Rights and Privacy
                        Act (FERPA), 20 U.S.C. 1232(g). Meadow agrees to treat Student Data as
                        confidential and not to share it with third parties other than as described
                        in Meadow’s agreement with the applicable educational institution.
                    </p>
                    <p>
                        All text, graphics, editorial content, data, formatting, graphs, designs,
                        HTML, look and feel, photographs, music, sounds, images, software, videos,
                        designs, trademarks, logos, typefaces and other content (collectively
                        “Proprietary Material”) that users see or read through the Services is owned
                        by Meadow. Proprietary Material is protected in all forms, media and
                        technologies now known or hereinafter developed. Meadow owns all Proprietary
                        Material, as well as the coordination, selection, arrangement and
                        enhancement of such Proprietary Materials as a Collective Work under the
                        United States Copyright Act, as amended. Proprietary Material is protected
                        by the domestic and international laws governing copyright, patents, and
                        other proprietary rights. You may not copy, download, use, redesign,
                        reconfigure, or retransmit anything from the Services without Meadow’s
                        express prior written consent.{" "}
                    </p>
                    <p>
                        Any use of such Proprietary Material, other than as permitted therein, is
                        expressly prohibited without the prior permission of Meadow.
                    </p>
                    <p>
                        The service marks and trademarks of Meadow, including without limitation
                        Meadow and Meadow logos, are service marks owned by Meadow. Any other
                        trademarks, service marks, logos and/or trade names appearing via the
                        Services are the property of their respective owners. You may not copy or
                        use any of these marks, logos or trade names without the express prior
                        written consent of the owner.
                    </p>
                    <p>
                        Additionally, you may choose to or we may invite you to submit comments,
                        ideas, or feedback about the Services, including without limitation about
                        how to improve our services or our products (“Feedback”). By submitting any
                        Feedback, you agree that your disclosure is gratuitous, unsolicited, and
                        without restriction and will not place Meadow under any fiduciary or other
                        obligation, and that we are free to use the Feedback without any additional
                        compensation to you, and/or to disclose the Feedback on a non-confidential
                        basis or otherwise to anyone. You further acknowledge that, by acceptance of
                        your submission, Meadow does not waive any rights to use similar or related
                        Feedback previously known to Meadow, developed by its employees, or obtained
                        from sources other than you. You acknowledge that all email and other
                        correspondence that you submit to us shall become our sole and exclusive
                        property.
                    </p>
                    <p>7. Confidential Information</p>
                    <p>
                        You acknowledge that Confidential Information (as defined below) is a
                        valuable, special and unique asset of Meadow and agree that you will not
                        disclose, transfer, use (or seek to induce others to disclose, transfer or
                        use) any Confidential Information for any purpose other than using the
                        Services in accordance with these Terms of Service. If relevant, you may
                        disclose the Confidential Information to your authorized employees and
                        agents provided that they are also bound to maintain the confidentiality of
                        Confidential Information. You shall promptly notify Meadow in writing of any
                        circumstances that may constitute unauthorized disclosure, transfer, or use
                        of Confidential Information. You shall use best efforts to protect
                        Confidential Information from unauthorized disclosure, transfer or use. You
                        shall return all originals and any copies of any and all materials
                        containing Confidential Information to Meadow upon termination of this
                        Agreement for any reason whatsoever.
                    </p>
                    <p>
                        The term “Confidential Information” shall mean any and all of Meadow’s trade
                        secrets, confidential and proprietary information, and all other information
                        and data of Meadow that is not generally known to the public or other third
                        parties who could derive value, economic or otherwise, from its use or
                        disclosure. Confidential Information shall be deemed to include technical
                        data, know-how, research, product plans, products, services, customers,
                        markets, software, developments, inventions, processes, formulas,
                        technology, designs, drawings, engineering, hardware configuration
                        information, marketing, finances, strategic and other proprietary and
                        confidential information relating to Meadow or Meadow’s business, operations
                        or properties, including information about Meadow’s staff, users or
                        partners, or other business information disclosed directly or indirectly in
                        writing, orally or by drawings or observation.
                    </p>
                    <p>8. Disclaimer of Warranties</p>
                    <p>
                        THE SERVICES IS PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OR
                        CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                        LIMITED TO, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. MEADOW MAKES NO WARRANTIES OR
                        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT PROVIDED
                        THROUGH THE SERVICES OR THE CONTENT OF ANY SITES LINKED TO THE SERVICES AND
                        ASSUMES NO LIABILITY OR RESPONSIBILITY IN CONTRACT, WARRANTY OR IN TORT FOR
                        ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY
                        OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
                        AND USE OF THE SERVICES, (III) ANY ACCESS TO OR USE OF OUR SECURE SERVERS
                        AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
                        THEREIN; AND (IV) EVENTS BEYOND OUR REASONABLE CONTROL. MEADOW MAKES NO
                        REPRESENTATION THAT THE SERVICES ARE APPROPRIATE OR AVAILABLE FOR USE
                        OUTSIDE OF THE UNITED STATES. MEADOW IS UNDER NO OBLIGATION TO PROVIDE
                        SUPPORT FOR THE SERVICES. IN INSTANCES WHERE MEADOW MAY OFFER SUPPORT FOR
                        THE SERVICES, SUCH SUPPORT WILL BE SUBJECT TO PUBLISHED POLICIES.
                    </p>
                    <p>
                        UNDER NO CIRCUMSTANCES WILL MEADOW AND AFFILIATES OR THEIR CORPORATE
                        PARTNERS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, ACTUAL,
                        CONSEQUENTIAL, ECONOMIC, SPECIAL OR EXEMPLARY DAMAGES (INCLUDING BUT NOT
                        LIMITED TO LOST PROFITS, LOSS OF DATA, LOSS OF GOODWILL, SERVICE
                        INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE, FAILURE TO STORE ANY
                        INFORMATION OR OTHER CONTENT MAINTAINED OR TRANSMITTED BY MEADOW, OR THE
                        COST OF SUBSTITUTE PRODUCTS OR SERVICES) ARISING IN CONNECTION WITH YOUR USE
                        OF OR INABILITY TO USE THE SERVICES, EVEN IF ADVISED OF THE POSSIBILITY OF
                        THE SAME. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                        INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY
                        TO YOU IN THEIR ENTIRETY.
                    </p>
                    <p>
                        IF, NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED THAT MEADOW
                        AND AFFILIATES OR THEIR CORPORATE PARTNERS ARE LIABLE FOR DAMAGES, IN NO
                        EVENT WILL THE AGGREGATE LIABILITY, WHETHER ARISING IN CONTRACT, TORT,
                        STRICT LIABILITY OR OTHERWISE, EXCEED THE LOWER OF (I) THE TOTAL FEES PAID
                        BY YOU TO MEADOW DURING THE SIX MONTHS PRIOR TO THE TIME SUCH CLAIM AROSE OR
                        (II) ONE HUNDRED DOLLARS ($100), TO THE EXTENT PERMITTED BY APPLICABLE LAW.
                    </p>
                    <p>9. Indemnification</p>
                    <p>
                        You hereby agree to indemnify, defend, and hold harmless Meadow and its
                        officers, directors, employees, agents, attorneys, insurers, successors and
                        assigns (the “Indemnified Parties”) from and against any and all Liabilities
                        incurred in connection with (i) your use or inability to use the Services,
                        or (ii) your breach or violation of this Agreement; (iii) your violation of
                        any law, or the rights of any user or third party and (iv) any content
                        submitted by you or using your Account to the Services, including, but not
                        limited to the extent such content may infringe on the intellectual rights
                        of a third party or otherwise be illegal or unlawful. You also agree to
                        indemnify the Indemnified Parties for any Liabilities resulting from your
                        use of software robots, spiders, crawlers, or similar data gathering and
                        extraction tools, or any other action you take that imposes an unreasonable
                        burden or loan on our infrastructure. Meadow reserves the right, in its own
                        sole discretion, to assume the exclusive defense and control at its own
                        expense of any matter otherwise subject to your indemnification. You will
                        not, in any event, settle any claim or matter without the prior written
                        consent of Meadow.
                    </p>
                    <p>10. Dispute Resolution – Arbitration & Class Action Waiver </p>
                    <p>
                        PLEASE READ THIS SECTION CAREFULLY — IT AFFECTS YOUR LEGAL RIGHTS AND
                        GOVERNS HOW YOU AND MEADOW CAN BRING CLAIMS AGAINST EACH OTHER. THIS SECTION
                        WILL, WITH LIMITED EXCEPTION, REQUIRE YOU AND MEADOW TO SUBMIT CLAIMS
                        AGAINST EACH OTHER TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS.
                    </p>
                    <p>
                        You agree that, in the event any dispute or claim arises out of or relating
                        to your use of the Services, you will contact us at support@meadowfi.com and
                        you and Meadow will attempt in good faith to negotiate a written resolution
                        of the matter directly. You agree that if the matter remains unresolved for
                        30 days after notification (via certified mail or personal delivery), such
                        matter will be deemed a “Dispute” as defined below. Except for the right to
                        seek injunctive or other equitable relief described under the “Binding
                        Arbitration” section below, should you file any arbitration claims, or any
                        administrative or legal actions without first having attempted to resolve
                        the matter by mediation, then you agree that you will not be entitled to
                        recover attorneys&apos; fees, even if you may have been entitled to them
                        otherwise.
                    </p>
                    <p>
                        Binding Arbitration. You and Meadow agree that any dispute, claim or
                        controversy arising out of or relating to this Agreement or to your use of
                        the Services (collectively “Disputes”) will be settled by binding
                        arbitration, except that each party retains the right to seek injunctive or
                        other equitable relief in a court of competent jurisdiction to prevent the
                        actual or threatened infringement, misappropriation, or violation of a
                        party&apos;s copyrights, trademarks, trade secrets, patents, or other
                        intellectual property rights. This means that you and Meadow both agree to
                        waive the right to a trial by jury. Notwithstanding the foregoing, you may
                        bring a claim against Meadow in “small claims” court, instead of by
                        arbitration, but only if the claim is eligible under the rules of the small
                        claims court and is brought in an individual, non-class, and
                        non-representative basis, and only for so long as it remains in the small
                        claims court and in an individual, non-class, and non-representative basis.
                    </p>
                    <p>
                        Class Action Waiver. You and Meadow agree that any proceedings to resolve
                        Disputes will be conducted on an individual basis and not in a class,
                        consolidated, or representative action. This means that you and Meadow both
                        agree to waive the right to participate as a plaintiff as a class member in
                        any class action proceeding. Further, unless you and Meadow agree otherwise
                        in writing, the arbitrator in any Dispute may not consolidate more than one
                        person’s claims and may not preside over any form of class action
                        proceeding.
                    </p>
                    <p>
                        Arbitration Administration and Rules. The arbitration will be administered
                        by the American Arbitration Association (“AAA”) in accordance with the
                        Commercial Arbitration Rules and the Supplementary Procedures for Consumer
                        Related Disputes (the “AAA Rules”) then in effect, except as modified by
                        this “Dispute Resolution’ section. (The AAA Rules are available at
                        http://www.adr.org or by calling the AAA at 1-800-778-7879).
                    </p>
                    <p>
                        Arbitration Process. A party who desires to initiate the arbitration must
                        provide the other party with a written Demand for Arbitration as specified
                        in the AAA Rules. The arbitrator will be either a retired judge or an
                        attorney licensed to practice law in the state of New York and will be
                        selected by the parties from the AAA’s roster of arbitrators with relevant
                        experience. If the parties are unable to agree upon an arbitrator within
                        seven days of delivery of the Demand for Arbitration, then the AAA will
                        appoint the arbitrator in accordance with AAA Rules.
                    </p>
                    <p>
                        Arbitration Location and Procedure. Unless you and Meadow agree otherwise,
                        the seat of the arbitration shall be in New York, New York. If your claim
                        does not exceed USD$10,000, then the arbitration will be conducted solely on
                        the basis of documents you and Meadow submit to the arbitrator, unless you
                        request a hearing and the arbitrator then determines that a hearing is
                        necessary. If your claim exceeds USD$10,000, your right to a hearing will be
                        determined by AAA Rules. Subject to AAA Rules, the arbitrator will have the
                        discretion to direct a reasonable exchange of information by the parties,
                        consistent with the expedited nature of the arbitration. Hearings may be
                        conducted by telephone or video conference, if requested and agreed to by
                        the parties.
                    </p>
                    <p>
                        Arbitrator’s Decision and Governing Law. The arbitrator shall apply New York
                        law consistent with the Federal Arbitration Act and applicable statutes of
                        limitations, and shall honor claims of privilege recognized by law. The
                        arbitrator will render an award within the timeframe specified in the AAA
                        Rules. Judgment on the arbitration may be entered in any court having
                        jurisdiction thereof. Any award of damages by an arbitrator must be
                        consistent with the “Disclaimers and Limitations of Liability” section
                        above. The arbitrator may award declaratory or injunctive relief in favor of
                        the claimant only to the extent necessary to provide relief warranted by the
                        claimant’s individual claim.
                    </p>
                    <p>
                        Fees. Each party’s responsibility to pay the arbitration filing,
                        administrative and arbitrator fees will depend on the circumstances of the
                        arbitration and are set forth in the AAA Rules.
                    </p>
                    <p>11. Governing Law</p>
                    <p>
                        Except as provided in Section 10 or expressly provided in writing otherwise,
                        this Agreement and your use of the Services will be governed by, and will be
                        construed under, the laws of the State of New York, without regard to choice
                        of law principles. This choice of law provision is only intended to specify
                        the use of New York law to interpret this Agreement.
                    </p>
                    <p>12. No Agency; No Employment</p>
                    <p>
                        No agency, partnership, joint venture, employer-employee or
                        franchiser-franchisee relationship is intended or created by this Agreement.
                    </p>
                    <p>13. General Provisions</p>
                    <p>
                        Failure by Meadow to enforce any provision(s) of this Agreement will not be
                        construed as a waiver of any provision or right. This Agreement constitutes
                        the complete and exclusive agreement between you and Meadow with respect to
                        its subject matter, and supersedes and governs any and all prior agreements
                        or communications. The provisions of this Agreement are intended to be
                        interpreted in a manner which makes them valid, legal, and enforceable.
                        Except for the “Class Action Waiver” in Section 10, in the event any
                        provision is found to be partially or wholly invalid, illegal or
                        unenforceable, (i) such provision shall be modified or restructured to the
                        extent and in the manner necessary to render it valid, legal, and
                        enforceable or, (ii) if such provision cannot be so modified or
                        restructured, it shall be excised from the Agreement without affecting the
                        validity, legality or enforceability of any of the remaining provisions.
                        This Agreement may not be assigned or transferred by you without our prior
                        written approval. We may assign or transfer this Agreement without your
                        consent, including but not limited to assignments: (1) to a parent or
                        subsidiary, (2) to an acquirer of assets, or (3) to any other successor or
                        acquirer. Any assignment in violation of this section shall be null and
                        void. This Agreement will inure to the benefit of Meadow, its successors and
                        assigns.
                    </p>
                    <p>14. Changes to this Agreement and the Services</p>
                    <p>
                        Meadow reserves the right, at its sole and absolute discretion, to change,
                        modify, add to, supplement, suspend, discontinue, or delete any of the terms
                        and conditions of this Agreement (including these Terms of Service and
                        Privacy Policy) and review, improve, modify or discontinue, temporarily or
                        permanently, the Services or any content or information through the Services
                        at any time, effective with or without prior notice and without any
                        liability to Meadow. Meadow will endeavor to notify you of material changes
                        by email, but will not be liable for any failure to do so. If any future
                        changes to this Agreement are unacceptable to you or cause you to no longer
                        be in compliance with this Agreement, you must terminate, and immediately
                        stop using, the Services. Your continued use of the Services following any
                        revision to this Agreement constitutes your complete and irrevocable
                        acceptance of any and all such changes. Meadow may also impose limits on
                        certain features or restrict your access to part or all of the Services
                        without notice or liability.
                    </p>
                    <p>15. No Rights of Third Parties</p>
                    <p>
                        None of the terms of this Agreement are enforceable by any persons who are
                        not a party to this Agreement.
                    </p>
                    <p>16. Notices and Consent to Receive Notices Electronically</p>
                    <p>
                        You consent to receive any agreements, notices, disclosures and other
                        communications (collectively, “Notices”) to which this Agreement refers
                        electronically including without limitation by email or by posting Notices
                        on this Site. You agree that all Notices that we provide to you
                        electronically satisfy any legal requirement that such communications be in
                        writing. Unless otherwise specified in this Agreement, all notices under
                        this Agreement will be in writing and will be deemed to have been duly given
                        when received, if personally delivered or sent by certified or registered
                        mail, return receipt requested; when receipt is electronically confirmed, if
                        transmitted by facsimile or email; or the day after it is sent, if sent for
                        next day delivery by a recognized overnight delivery service.
                    </p>
                    <p>17. Contacting Us</p>
                    <p>
                        If you have any questions about these Terms of Service or about the
                        Services, please contact us by email at support@meadowfi.com or by mail to
                        Meadow Technologies, Inc. at 175 Varick, New York, NY 10014.
                    </p>
                </Typography>
            </Box>
        </MeadowContainer>
    );
};

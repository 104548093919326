import { GREY_PLACEHOLDER } from "../../theme/colors";

export const typographySX = (isMobile: boolean) => ({
    margin: "1rem auto",
    paddingLeft: isMobile ? "1.5rem" : "0rem",
    paddingRight: isMobile ? "1.5rem" : "0rem",
});

export const dividerSX = (isMobile: boolean) => ({
    width: isMobile ? "100%" : "auto",
    color: GREY_PLACEHOLDER,
    margin: "1rem auto",
});

export const flexEndContainer: React.CSSProperties = {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
};

export const containerPadding = (isMobile: boolean) => ({
    paddingLeft: isMobile ? "1.5rem" : "0rem",
    paddingRight: isMobile ? "1.5rem" : "0rem",
});

export const footer = (isMobile: boolean) => ({
    marginTop: "auto",
    paddingLeft: isMobile ? "1.5rem" : "0rem",
    paddingRight: isMobile ? "1.5rem" : "0rem",
});

export const dividerWrapperMobileSx = {
    width: "100%",
    padding: "0rem",
};
export const dividerWrapperDesktopSx = {
    padding: "0rem",
};
export const containerSX = (isMobile: boolean) => ({
    width: isMobile ? "100%" : "24.375rem",
    /**
     * These styles are marked !important because we want them to
     * take precedence over the padding defined in MuiContainer.root
     * which is marked with left and right padding of 1.5 rem.
     */
    padding: "0rem 0rem 1rem 0rem !important",
});

import { SvgIcon, SvgIconProps } from "@mui/material";

export const CheckIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.3028 8.07824C14.6222 7.77291 14.6336 7.2665 14.3282 6.94715C14.0229 6.6278 13.5165 6.61643 13.1972 6.92176L8.63606 11.2826L6.79018 9.59284C6.46428 9.2945 5.95824 9.31685 5.65991 9.64275C5.36158 9.96865 5.38392 10.4747 5.70982 10.773L8.10776 12.9681C8.41842 13.2525 8.89637 13.2473 9.20079 12.9563L14.3028 8.07824Z"
                fill="#0A6B45"
            />
        </svg>
    </SvgIcon>
);

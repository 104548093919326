import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoutIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.3992 16.985V21.6002C14.3992 21.9185 14.2728 22.2237 14.0477 22.4487C13.8227 22.6738 13.5175 22.8002 13.1992 22.8002H2.39922C2.08096 22.8002 1.77573 22.6738 1.55069 22.4487C1.32565 22.2237 1.19922 21.9185 1.19922 21.6002V2.4002C1.19922 2.08194 1.32565 1.77671 1.55069 1.55167C1.77573 1.32662 2.08096 1.2002 2.39922 1.2002H13.1992C13.5175 1.2002 13.8227 1.32662 14.0477 1.55167C14.2728 1.77671 14.3992 2.08194 14.3992 2.4002V7.01539M8.39922 12.0002H22.7992M22.7992 12.0002L19.1992 8.40019M22.7992 12.0002L19.1992 15.6002"
                stroke="black"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

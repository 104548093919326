import { SvgIcon, SvgIconProps } from "@mui/material";

export const BackIcon = (props: SvgIconProps) => {
    const hoverSx = {
        "&:hover": {
            cursor: "pointer",
        },
    };
    return (
        <SvgIcon sx={hoverSx} {...props}>
            <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 7.00021C0 6.52682 0.383756 6.14307 0.857143 6.14307H23.1429C23.6162 6.14307 24 6.52682 24 7.00021C24 7.4736 23.6162 7.85735 23.1429 7.85735H0.857143C0.383756 7.85735 0 7.4736 0 7.00021Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.46323 0.394118C7.79797 0.728853 7.79797 1.27157 7.46323 1.6063L2.06933 7.00021L7.46323 12.3941C7.79797 12.7289 7.79797 13.2716 7.46323 13.6063C7.1285 13.941 6.58579 13.941 6.25105 13.6063L0.251051 7.6063C-0.0836838 7.27157 -0.0836838 6.72885 0.251051 6.39412L6.25105 0.394118C6.58579 0.0593826 7.1285 0.0593826 7.46323 0.394118Z"
                    fill="black"
                />
            </svg>
        </SvgIcon>
    );
};

import BaseBanner from "./Base";
import { BLUE_100, BLUE_700 } from "../../../theme/colors";
import { PendingInfoIcon } from "../../icons/PendingInfoIcon";

const PendingPaymentBanner: React.FC = () => {
    return (
        <BaseBanner
            color={BLUE_700}
            backgroundColor={BLUE_100}
            message="Thank you for your payment! It will be processed within 24 hours."
            Icon={PendingInfoIcon}
        />
    );
};

export default PendingPaymentBanner;

import { useEffect, useState } from "react";
import { BillingCharge, FormattedChargesProps, FormattedChargesInterface } from "../Billing.model";
import { Box, Typography } from "@mui/material";
import { GREY_50, GREY_100 } from "../../../theme/colors";
import { formatChargeDate, formatCharges, formatter } from "../utilities";

export const FormattedCharges = ({ subcategory }: FormattedChargesProps) => {
    const [formattedCharges, setFormattedCharges] = useState<FormattedChargesInterface[]>([]);

    const dateWrapperSx = {
        backgroundColor: GREY_50,
        borderBottom: `1px solid ${GREY_100}`,
        padding: "0.75rem 1rem",
        display: "flex",
        justifyContent: "space-between",
    };

    useEffect(() => {
        const chargesByDate = formatCharges(subcategory.charges);

        setFormattedCharges(chargesByDate);
    }, []);

    return (
        <>
            {formattedCharges.map((formattedCharge, index) => {
                return (
                    <Box key={index}>
                        <Box sx={dateWrapperSx}>
                            <Typography>{formatChargeDate(formattedCharge.date, {})}</Typography>
                        </Box>
                        {formattedCharge.charges.map((charge: BillingCharge, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "0.75rem 1rem",
                                        borderBottom: `1px solid ${GREY_100}`,
                                    }}
                                >
                                    <Typography>{charge.title}</Typography>
                                    <Typography sx={{ whiteSpace: "nowrap" }}>
                                        {formatter.format(charge.amount)}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                );
            })}
        </>
    );
};

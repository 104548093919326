import { GREY_100, BLACK_000, BLUE_500, RED_500 } from "../../../theme/colors";

export const bodySx = {
    padding: "1.5rem 1rem",
    borderRadius: 0,
    boxShadow: "none",
    borderBottom: `1px solid ${GREY_100}`,
};
export const rowWrapperSx = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
};
export const rowSx = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};
export const rowTitleSx = {
    fontWeight: 500,
    color: BLACK_000,
    fontSize: "0.875rem",
    lineHeight: "1rem",
};
export const editButtonSx = {
    color: BLUE_500,
    fontWeight: 700,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    minWidth: "unset",
};
export const rowSubTitleSx = {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: BLACK_000,
    paddingRight: "0.5rem",
};
export const rowSubTitleTooltipSx = {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: BLACK_000,
};
export const boldRowSubTitleSx = {
    fontWeight: 700,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    textTransform: "capitalize",
    paddingRight: "0.5rem",
};
export const footerSx = (isMobile: boolean) => ({
    padding: "1.5rem 1rem",
    border: isMobile ? "none" : `1px solid ${GREY_100}`,
    borderTop: `1px solid ${GREY_100}`,
});
export const errorLabelWrapperSX = {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    paddingBottom: "1rem",
    paddingTop: "0.5rem",
};
export const amountErrorWrapperSx = {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
};
export const errorLabelSX = {
    color: RED_500,
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.25rem",
};
export const toolTipWrapperSx = {
    display: "flex",
    alignItems: "center",
};

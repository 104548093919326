import { Box, Typography } from "@mui/material";
import autopayRefresh from "../../../assets/autopay_refresh.svg";
import { GREEN_400 } from "../../../theme/colors";

interface AutopayTokenProps {
    showColon?: boolean;
}
export const AutopayToken = ({ showColon = false }: AutopayTokenProps) => {
    const autopayTokenWrapperSx = {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
    };

    const autopayTokenSx = {
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: "1rem",
        letterSpacing: "0.01em",
        color: GREEN_400,
    };

    return (
        <Box data-testid="autopay-token" sx={autopayTokenWrapperSx}>
            <img src={autopayRefresh} alt="" />
            <Typography sx={autopayTokenSx}>{showColon ? "Autopay on:" : "Autopay on"}</Typography>
        </Box>
    );
};

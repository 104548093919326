import { GetConfigResponse } from "./Config.model";
import { IMeadowHttpFactory, meadowHttpFactory } from "./meadowHttpFactory";
import defaultSchoolLogo from "../assets/defaultSchoolLogo.svg";
import * as Sentry from "@sentry/react";
import React from "react";

const tilaDisclosureText =
    "The one-time start fee of $50.00 is required to start the payment plan and is non-refundable.\nIf your student account balance changes for the the plan’s period, your plan will automatically adjust to reflect the new balance.\nPre-payment: If you pay off early, you will not be entitled to a refund of the one-time start fee.\nLate charge: If a payment is late, you will be charged a fee as specified in the terms & conditions.";
const tilaDisclosureTextElements = tilaDisclosureText.split("\n").map((line, index) => (
    <React.Fragment key={index}>
        {line}
        <br />
        <br />
    </React.Fragment>
));
const DEFAULT_CONFIG = {
    // `defaultSchoolLogo` is not a valid URL, but it's a valid value for the `src` attribute of an `img` tag.
    id: 0,
    logoURL: defaultSchoolLogo,
    abbreviation: "",
    schoolName: "Meadow University",
    isSsoEnabled: false, // if we can't get the config info, than SSO is just disabled
    isLoading: false,
    tilaDisclosureText: tilaDisclosureTextElements,
    isAutopayEnabled: false,
    isTilaEnabled: false,
    shouldHideBillDetailsIfNoBalanceDue: false,
    shouldShowDueDates: true,
};

export type IConfigService = {
    getConfig(hostname: string): Promise<GetConfigResponse>;
};

export class ConfigService implements IConfigService {
    private http: IMeadowHttpFactory;
    public constructor(http?: IMeadowHttpFactory) {
        this.http = http || meadowHttpFactory({});
    }

    public async getConfig(hostname: string): Promise<GetConfigResponse> {
        try {
            const result = await this.http.get(`/v1/config?hostname=${hostname}`);
            return result.data;
        } catch (err) {
            Sentry.captureException(err);
            return DEFAULT_CONFIG;
        }
    }
}

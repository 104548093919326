import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MeadowContainer from "../../container";
import { GREEN_400, GREEN_700 } from "../../../theme/colors";
import { Box, Button, Typography } from "@mui/material";
import success from "../../../assets/success.svg";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";

export const GrantAccountAccessSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [student] = useState<PaymentsStudent | undefined>(location?.state?.data);

    useEffect(() => {
        // If the user navigates to this page without a student, redirect them to the billing page
        if (!student) {
            navigate("/billing");
        }
    }, []);

    return (
        <MeadowContainer
            fullWidth
            hideHeader
            sx={{
                backgroundColor: GREEN_700,
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    maxWidth: "24rem",
                    gap: "1.25rem",
                }}
            >
                <Box>
                    <img src={success} />
                    <Typography
                        sx={{
                            color: GREEN_400,
                            fontSiz: "1.75rem",
                            fontWeight: 500,
                            lineHeight: "2.25rem",
                        }}
                        variant="h1"
                    >
                        Your account access invite has been sent!
                    </Typography>

                    <Typography
                        sx={{
                            color: GREEN_400,
                            fontSize: "1rem",
                            fontWeight: 400,
                            lineHeight: "1.5rem",
                        }}
                    >
                        You will receive an email notification when the invite has been accepted.
                    </Typography>
                </Box>
                <Button
                    sx={{ maxWidth: "10.5rem" }}
                    onClick={() => navigate("/billing")}
                    variant="primary"
                >
                    Back to home
                </Button>
            </Box>
        </MeadowContainer>
    );
};

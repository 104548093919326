import { Card, ListItem } from "@mui/material";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";
import { AccessControls } from "../AccessControls";

type AccountAccessCardProps = {
    student: PaymentsStudent;
};
export default function AccountAccessCard({ student }: AccountAccessCardProps) {
    const { isMobile } = useBreakpoint();
    const cardSx = {
        padding: "1rem",
        width: isMobile ? "100%" : "50.5rem",
        boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        borderRadius: "0.5rem",
    };
    return (
        <ListItem sx={{ padding: 0 }}>
            <Card sx={cardSx}>
                <AccessControls student={student} />
            </Card>
        </ListItem>
    );
}

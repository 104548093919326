import { SvgIcon, SvgIconProps } from "@mui/material";

export const ContactUsIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0ZM10 5C10.6902 5 11.25 5.55977 11.25 6.25C11.25 6.94023 10.6902 7.5 10 7.5C9.30977 7.5 8.75 6.94141 8.75 6.25C8.75 5.55859 9.30859 5 10 5ZM11.5625 15H8.4375C7.92188 15 7.5 14.582 7.5 14.0625C7.5 13.543 7.91992 13.125 8.4375 13.125H9.0625V10.625H8.75C8.23242 10.625 7.8125 10.2051 7.8125 9.6875C7.8125 9.16992 8.23438 8.75 8.75 8.75H10C10.5176 8.75 10.9375 9.16992 10.9375 9.6875V13.125H11.5625C12.0801 13.125 12.5 13.5449 12.5 14.0625C12.5 14.5801 12.082 15 11.5625 15Z"
                fill="#5B616E"
            />
        </svg>
    </SvgIcon>
);

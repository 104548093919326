import { Typography } from "@mui/material";
import confusedDark from "./assets/confused_icon_dark.svg";
import MeadowContainer from "./components/container";
import { ContactLink } from "./components/account/ContactLink";
import { BLACK_000, GREY_500 } from "./theme/colors";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

function ErrorPage() {
    useEffect(() => {
        Sentry.captureMessage("ErrorPage rendered");
    }, []);

    return (
        <MeadowContainer
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2rem",
            }}
        >
            <img style={{ height: "9.625rem" }} src={confusedDark} alt="confused" />
            <Typography variant="body2" sx={{ fontSize: "1rem", color: GREY_500 }}>
                Please try again or <ContactLink textColor={BLACK_000} />
            </Typography>
        </MeadowContainer>
    );
}

export default ErrorPage;

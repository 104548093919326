import { Box, FormLabel, TextField, InputAdornment } from "@mui/material";
import { NumericFormatter } from "../NumericFormatter.js";
import {
    otherAmountContainerSx,
    dollarSignSx,
    disabledInputLabelSx,
    disabledAccountInputSx,
} from "./styles";
import { useContext } from "react";
import { PaymentContext } from "../../../providers/PaymentsProvider";

interface DisabledInputProps {
    setDisplayAmountToPay: (amount: string) => void;
    isAmountError: boolean;
    errorMessage: string;
    displayAmountToPay: string;
    enableDecimalFormatting: boolean;
    setEnableDecimalFormatting: (value: boolean) => void;
    validatePaymentAmount: (amount: number) => void;
}

export default function DisabledInput({
    setDisplayAmountToPay,
    isAmountError,
    errorMessage,
    displayAmountToPay,
    enableDecimalFormatting,
    setEnableDecimalFormatting,
    validatePaymentAmount,
}: DisabledInputProps) {
    const { currentStudentId, setPartialPaymentAmount, setAmountToPay } =
        useContext(PaymentContext);
    return (
        <Box sx={otherAmountContainerSx}>
            <FormLabel sx={disabledInputLabelSx}>Enter amount:</FormLabel>
            <TextField
                sx={disabledAccountInputSx}
                onChange={(e) => {
                    const values = e.target.value as any;
                    if (typeof values?.floatValue !== "number") return;

                    if (currentStudentId) {
                        setPartialPaymentAmount(currentStudentId, values.floatValue);
                        setAmountToPay(values.floatValue, currentStudentId);
                    }
                    setDisplayAmountToPay(values.formattedValue);
                    validatePaymentAmount(values.floatValue);
                }}
                autoFocus={true}
                inputMode="numeric"
                placeholder="0.00"
                error={isAmountError}
                helperText={errorMessage}
                onFocus={() => {
                    //Disable decimal formatting when user clicks on the input field
                    setEnableDecimalFormatting(false);
                }}
                onBlur={() => setEnableDecimalFormatting(true)}
                value={displayAmountToPay}
                inputProps={{
                    enableDecimalFormatting,
                }}
                InputProps={{
                    inputComponent: NumericFormatter as any,
                    startAdornment: (
                        <InputAdornment position="start">
                            <div style={dollarSignSx}>$</div>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
}

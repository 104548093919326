import { Button, SxProps } from "@mui/material";
import { FormEvent } from "react";
import { AllLoggingEvents } from "src/enums/logging";
import { Logging } from "../../services/Logging";

interface MeadowButtonProps {
    onClick: (e?: FormEvent) => void;
    variant?: "primary" | "secondary" | "tertiary";
    mixpanelEvent?: AllLoggingEvents;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    sx?: SxProps;
    children?: React.ReactNode;
}
export const MeadowButton = (props: MeadowButtonProps) => {
    const { onClick, variant, mixpanelEvent, type, disabled, sx, children } = props;
    const handleClick = () => {
        if (mixpanelEvent) {
            Logging.track(mixpanelEvent, {});
        }
        onClick();
    };
    const styles = {
        ...sx,
    };
    return (
        <Button
            sx={styles}
            className="meadow-button"
            onClick={handleClick}
            variant={variant ? variant : "primary"}
            type={type ? type : "button"}
            disabled={disabled}
        >
            {children}
        </Button>
    );
};

import { Logging } from "../../services/Logging";
import { parseJwt, sessionStorageGet } from "../../services/utilities";

export async function setUserData() {
    const encodedToken = sessionStorageGet("authToken");

    if (typeof encodedToken === "string") {
        const userData = parseJwt(encodedToken);
        Logging.login(userData.userId);
        return userData;
    }

    return null;
}

import { Box, Grid } from "@mui/material";
import { GREY_100 } from "../../../theme/colors";
import { capitalizeFirstLetter, formatter } from "../utilities";
import { StudentPaymentPlan } from "../Student.model";

type RemainingBalanceProps = {
    applicablePaymentPlan: StudentPaymentPlan;
};

export const RemainingBalance = ({ applicablePaymentPlan }: RemainingBalanceProps) => {
    return (
        <Box
            sx={{
                paddingTop: "1rem",
            }}
        >
            <Grid
                container
                justifyContent="space-between"
                sx={{
                    paddingTop: "0.75rem",
                    borderTop: `1px solid ${GREY_100}`,
                }}
            >
                <Grid item>
                    Remaining balance for{" "}
                    {capitalizeFirstLetter(applicablePaymentPlan?.periodOfEnrollment)}
                </Grid>
                <Grid item>{formatter.format(applicablePaymentPlan?.remainingAmount || 0)}</Grid>
            </Grid>
        </Box>
    );
};

import { GetSchoolResponse } from "./School.model";
import { IMeadowHttpFactory, meadowHttpFactory } from "./meadowHttpFactory";

export type ISchoolService = {
    getSchool(studentId: number): Promise<GetSchoolResponse>;
};

export class SchoolService implements ISchoolService {
    private http: IMeadowHttpFactory;
    public constructor(http?: IMeadowHttpFactory) {
        this.http = http || meadowHttpFactory({});
    }

    public async getSchool(studentId: number): Promise<GetSchoolResponse> {
        const result = await this.http.get(`/v1/school?studentId=${studentId}`);
        return result.data;
    }
}

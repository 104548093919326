import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import * as Sentry from "@sentry/react";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import { randomId, sessionStorageSet, sessionStorageGet } from "./services/utilities";

const sentrySampleRate = parseInt(import.meta.env.VITE_SENTRY_SAMPLE_RATE ?? "1", 10);

const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT ?? "development";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllInputs: true,
        }),
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
    ],
    tracesSampleRate: sentrySampleRate,
    debug: environment === "development" && !!import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_COMMIT_SHA,
    environment,
    replaysSessionSampleRate: environment === "development" ? 0 : 10,
    replaysOnErrorSampleRate: environment === "development" ? 0 : 1.0,
});

let meadowSessionId = sessionStorageGet("meadowSessionId");
// No session ID in session storage, so this is the beginning of a new session.
if (typeof meadowSessionId !== "string") {
    meadowSessionId = "MEADOWID-S" + randomId(10);
    sessionStorageSet("meadowSessionId", meadowSessionId);
    Sentry.addBreadcrumb({
        category: "lifecycle",
        message: `App loaded, start of new session.
        Meadow Session ID: ${meadowSessionId}. 
        The Meadow Request IDs for all requests in this session will begin with this prefix.`,
        level: "log",
    });
}
// Session ID in session storage, so this is a continuation of an existing session after a page reload.
else {
    Sentry.addBreadcrumb({
        category: "lifecycle",
        message: `App loaded, continuing existing session.
        Meadow Session ID: ${meadowSessionId}.`,
        level: "log",
    });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
);

// White / Black / Grey
export const WHITE_000 = "#FFFFFF";
export const BLACK_000 = "#000000";
export const GREY_50 = "#F7F9FA";
export const GREY_85 = "#D9D9D9";
export const GREY_100 = "#E6EBF3";
export const GREY_200 = "#D5DCE6";
export const GREY_400 = "#B4BCD1";
export const GREY_500 = "#5B616E";
export const GREY_600 = "#393E48";
export const GREY_800 = "#F2F4F5";
export const GREY_PLACEHOLDER = "#939BAC";
export const GREY_MANATEE = "#8B93A6";
// Blue
export const BLUE_100 = "#D9DFF7";
export const BLUE_200 = "#B7C4F5";
export const BLUE_500 = "#425fd7";
export const BLUE_700 = "#2746C5";

// Red
export const RED_200 = "#F3D6D9";
export const RED_500 = "#C13042";
export const RED_700 = "#A81628";

// Yellow
export const YELLOW_200 = "#FFECC7";
export const YELLOW_500 = "#FFC043";
export const YELLOW_700 = "#684E00";

// Green
export const GREEN_400 = "#0A6B45";
export const GREEN_500 = "#0F8A59";
export const GREEN_700 = "#CFE8DE";

// Cyan
export const CYAN_100 = "#D5E3E6";
export const CYAN_200 = "#B9DCE4";
export const CYAN_500 = "#44BDD7";

// Lemon
export const LEMON_100 = "#F8F5EE";
export const LEMON_200 = "#F8E8CD";
export const LEMON_500 = "#F7C844";

// Salmon
export const SALMON_100 = "#EFDCDA";
export const SALMON_200 = "#F49395";
export const SALMON_500 = "#F55D60";

// Purple
export const PURPLE_100 = "#E3E0FD";
export const PURPLE_200 = "#C4BAFA";
export const PURPLE_500 = "#9D84F4";

// Chart colors
export const CHART_1 = "#4661D0";
export const CHART_2 = "#B95FBF";
export const CHART_3 = "#ED6B9A";
export const CHART_4 = "#F09078";
export const CHART_5 = "#F7C46D";
export const CHART_6 = "#B4CB63";

import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const makeNetworkErrorInterceptor = () => (error: AxiosError | AxiosRequestConfig) => {
    const hostname = window.location.hostname;

    if (
        axios.isAxiosError(error) &&
        !error.response &&
        error.message === "Network Error" &&
        error.config &&
        !error.config.url?.includes("localhost") &&
        (error.config.url?.includes("/v1/auth/otp_send_challenge") ||
            error.config.url?.includes(`/v1/config?hostname=${hostname}`))
    ) {
        window.location.href = "/network-error";
        // keep waiting until the network error page is loaded
        return new Promise(() => {});
    }

    return Promise.reject(error);
};

import { GREY_100 } from "../../../theme/colors";

export const nameSx = {
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    marginBottom: "0.5rem",
};

export const moneySx = {
    fontWeight: 500,
    fontSize: "2rem",
    lineHeight: "2.75rem",
};

export const adminAccountRowWrapperSx = {
    display: "flex",
    justifyContent: "space-between",
};
export const adminRemoveAllyButtonSx = {
    background: "transparent",
    border: "none",
    boxShadow: "none",
    maxWidth: "6.5rem",
    "&:hover": {
        background: GREY_100,
    },
};

export const removeBoxSx = {
    display: "flex",
    padding: "0 1rem",
    gap: "0.5rem",
};

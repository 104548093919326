import zIndex from "@mui/material/styles/zIndex.js";
import { BLUE_500, BLUE_200 } from "../../theme/colors";

export const adminBannerSx = {
    backgroundColor: BLUE_200,
    width: "100%",
    height: "0.375rem",
    display: "flex",
    justifyContent: "center",
};

export const adminBannerTextSx = {
    color: BLUE_500,
    fontSize: "0.75rem",
    fontWeight: "bold",
    letterSpacing: "0.05rem",
    textTransform: "uppercase",
    padding: "0.25rem 0.5rem",
    backgroundColor: BLUE_200,
    height: "19px",
    borderRadius: "0px 0px 4px 4px;",
    zIndex: 1,
};

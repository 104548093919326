import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { YELLOW_200, YELLOW_700 } from "../../theme/colors";
import { XIcon } from "../icons/XIcon";
import { WarningIcon } from "../icons/WarningIcon";

export const WarningBanner = ({
    showMessage,
    message,
}: {
    showMessage: boolean;
    message: string;
}) => {
    const [isHidden, setIsHidden] = useState(showMessage);
    if (!isHidden)
        return (
            <Box
                sx={{
                    height: 0,
                }}
            />
        );
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                backgroundColor: YELLOW_200,
                padding: "1rem",
                marginTop: "1rem",
                marginBottom: "1rem",
                textAlign: "left",
                gap: "0.5rem",
                borderRadius: "0.5rem",
            }}
        >
            <WarningIcon />
            <Typography
                variant="toast"
                sx={{
                    color: YELLOW_700,
                }}
            >
                {message}
            </Typography>
            <XIcon
                sx={{ cursor: "pointer" }}
                stroke={YELLOW_700}
                onClick={() => setIsHidden(false)}
            />
        </Box>
    );
};

import { useEffect, useContext } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import ConfirmAccount from "../components/account/ConfirmAccount";
import UserContextProvider, { UserContext } from "../context/UserContext";
import { PaymentProvider } from "../providers/PaymentsProvider";
import { AccountSettings } from "../components/account/AccountSettings";
import Home from "../Home";
import ScrollToTop from "../components/utils/ScrollToTop";
import PaymentMethod from "../components/payment/PaymentMethod";
import PaymentSummary from "../components/payment/PaymentSummary";
import PaymentSuccess from "../components/payment/PaymentSuccess";
import PaymentAmount from "../components/payment/PaymentAmount";
import CreditCard from "../components/payment/payment-methods/CreditCard";
import BankAccount from "../components/payment/payment-methods/BankAccount";
import SignIn from "../components/account/SignIn";
import Billing from "../components/billing/Billing";
import GetConfig from "../components/utils/GetConfig";
import ConfigContextProvider from "../context/ConfigContext";
import { BillingDetails } from "../components/billing/details/BillingDetails";
import { BillingChargeBreakdown } from "../components/billing/details/BillingChargeBreakdown";
import Permalink from "../components/account/Permalink";
import { ManageAccountAccess } from "../components/billing/account-access/ManageAccountAccess";
import { GrantAccountAccess } from "../components/billing/account-access/GrantAccountAccess";
import { GrantAccountAccessSuccess } from "../components/billing/account-access/GrantAccountAccessSuccess";
import ConfirmAllyAccount from "../components/account/ConfirmAllyAccount";
import { Logging } from "../services/Logging";
import { TermsOfUse } from "../components/account/TermsOfUse";
import { PrivacyPolicy } from "../components/account/PrivacyPolicy";
import { ROUTER_PATHS } from "../constants";
import SelfServiceSendInvite from "../components/account/SelfServiceSendInvite";
import SelfServiceSuccess from "../components/account/SelfServiceSuccess";
import SSOCallback from "../components/account/SSOCallback";
import PlanDetails from "../components/billing/payment-plans/PlanDetails";
import { PaymentPlanLegal } from "../components/billing/payment-plans/PaymentPlanLegal";
import ErrorPage from "../ErrorPage";

function RequireAuth() {
    const { user } = useContext(UserContext);
    const location = useLocation();

    if (!user) {
        return <Navigate to="/signin" state={{ from: location }} />;
    }
    return <Outlet />;
}

const AppRoutes = () => {
    const location = useLocation();

    useEffect(() => {
        Logging.trackPageview();
    }, [location]);

    return (
        <ConfigContextProvider>
            <UserContextProvider>
                <PaymentProvider>
                    <Routes>
                        <Route element={<GetConfig />}>
                            <Route element={<ScrollToTop />}>
                                <Route path={ROUTER_PATHS.NETWORK_ERROR} element={<ErrorPage />} />
                                <Route path={ROUTER_PATHS.HOME} element={<Home />} />
                                <Route
                                    path={ROUTER_PATHS.CONFIRM_ACCOUNT_STUDENT}
                                    element={<ConfirmAccount />}
                                />
                                <Route
                                    path={ROUTER_PATHS.CONFIRM_ACCOUNT_ALLY}
                                    element={<ConfirmAllyAccount />}
                                />
                                <Route path={ROUTER_PATHS.TERMS_OF_USE} element={<TermsOfUse />} />
                                <Route
                                    path={ROUTER_PATHS.PRIVACY_POLICY}
                                    element={<PrivacyPolicy />}
                                />
                                <Route path={ROUTER_PATHS.SIGN_IN} element={<SignIn />} />
                                <Route path={"/l/:permalink"} element={<Permalink />} />
                                <Route
                                    path={ROUTER_PATHS.SELF_SERVICE_SIGNUP}
                                    element={<SelfServiceSendInvite />}
                                />
                                <Route
                                    path={ROUTER_PATHS.SELF_SERVICE_SIGNUP_SUCCESS}
                                    element={<SelfServiceSuccess />}
                                />
                                <Route path="/sso/callback" element={<SSOCallback />} />
                                <Route element={<RequireAuth />}>
                                    <Route path={ROUTER_PATHS.BILLING} element={<Billing />} />
                                    <Route
                                        path={ROUTER_PATHS.BILLING_DETAILS}
                                        element={<BillingDetails />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.BILLING_DETAILS_CHARGES}
                                        element={<BillingChargeBreakdown />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.PAYMENT_AMOUNT}
                                        element={<PaymentAmount />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.PAYMENT_METHOD}
                                        element={<PaymentMethod />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.PAYMENT_BANK}
                                        element={<BankAccount />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.PAYMENT_CARD}
                                        element={<CreditCard />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.PAYMENT_SUMMARY}
                                        element={<PaymentSummary />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.PAYMENT_SUCCESS}
                                        element={<PaymentSuccess />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.PAYMENT_PLAN_DETAILS}
                                        element={<PlanDetails />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.PAYMENT_PLAN_LEGAL}
                                        element={<PaymentPlanLegal />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.ACCOUNT}
                                        element={<AccountSettings />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.ACCT_ACCESS_MANAGE}
                                        element={<ManageAccountAccess />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.ACCT_ACCESS_GRANT}
                                        element={<GrantAccountAccess />}
                                    />
                                    <Route
                                        path={ROUTER_PATHS.ACCT_ACCESS_GRANTED_SUCCESS}
                                        element={<GrantAccountAccessSuccess />}
                                    />
                                </Route>
                            </Route>
                        </Route>
                    </Routes>
                </PaymentProvider>
            </UserContextProvider>
        </ConfigContextProvider>
    );
};

export default AppRoutes;

import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { GREY_100, GREY_500, RED_500, WHITE_000 } from "../../../theme/colors";
import MeadowContainer from "../../container";
import { useEffect, useState } from "react";
import { SharingList } from "./SharingList";
import MeadowInput from "../../form/MeadowInput";
import PoweredByMeadow from "../../footer/PoweredByMeadow";
import { ErrorTypes } from "../../../enums/errors";
import validator from "validator";
import { AuthService } from "../../../services/AuthService";
import { parseError } from "../../../services/utilities";
import LoadingDialog from "../../modal/LoadingDialog";
import { OverdueInfoIcon } from "../../icons/OverdueInfoIcon";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";
import * as Sentry from "@sentry/react";

export const GrantAccountAccess = () => {
    const { isMobile } = useBreakpoint();
    const navigate = useNavigate();
    const location = useLocation();
    const [student] = useState<PaymentsStudent | undefined>(location?.state?.data);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstNameValue, setFirstNameValue] = useState<string>("");
    const [firstNameErrored, setFirstNameErrored] = useState<boolean>(false);
    const [lastNameValue, setLastNameValue] = useState<string>("");
    const [lastNameErrored, setLastNameErrored] = useState<boolean>(false);
    const [emailValue, setEmailValue] = useState<string>("");
    const [emailErrored, setEmailErrored] = useState<boolean>(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
    const [networkError, setNetworkError] = useState<string>("");

    useEffect(() => {
        // If the user navigates to this page without a student, redirect them to the billing page
        if (!student) {
            navigate("/billing");
        }
    }, []);
    const innerContainerSx = {
        width: isMobile ? "100%" : "24.5rem",
    };
    const isEmailEmptyOrInvalid = emailErrored
        ? ErrorTypes.ALLY_EMAIL
        : isEmailInvalid
        ? ErrorTypes.INVALID
        : ErrorTypes.NULL;

    const clearErrors = () => {
        setFirstNameErrored(false);
        setLastNameErrored(false);
        setEmailErrored(false);
        setIsEmailInvalid(false);
    };

    const handleSubmit = async () => {
        let error = false;
        clearErrors();

        // verify inputs are valid

        if (firstNameValue.length === 0) {
            error = true;
            setFirstNameErrored(true);
        }

        if (lastNameValue.length === 0) {
            error = true;
            setLastNameErrored(true);
        }

        if (emailValue.length === 0) {
            error = true;
            setEmailErrored(true);
        }

        // verify it's a valid email
        const isEmail = validator.isEmail(emailValue);
        if (!isEmail) {
            error = true;
            setEmailErrored(true);
        } else {
            setEmailErrored(false);
        }

        if (error) {
            return;
        }

        try {
            setIsLoading(true);
            const authService = new AuthService();
            await authService.inviteAlly(
                emailValue,
                firstNameValue,
                lastNameValue,
                student!.studentId
            );
            navigate("/access/grant/success", { state: { data: student } });
        } catch (error) {
            setIsLoading(false);
            setNetworkError(parseError(error));
            Sentry.captureException(error);
        }
    };
    return (
        <MeadowContainer
            fullWidth
            showSimpleHeader={true}
            simpleHeaderText="Grant account access"
            noPadding
            lightSimpleHeader
            sx={{
                backgroundColor: WHITE_000,
                alignItems: "center",
                paddingBottom: "1rem",
            }}
        >
            {student ? (
                <Box sx={innerContainerSx}>
                    <Box
                        sx={{
                            border: `1px solid ${GREY_100}`,
                            borderLeft: "none",
                            borderRight: "none",
                            padding: "1rem",
                        }}
                    >
                        <Typography sx={{ fontWeight: 500 }}>
                            {student.firstName} {student.lastName}
                        </Typography>
                        <Typography>
                            Student account:{" "}
                            <span style={{ fontWeight: 500 }}>{student.accountNumber}</span>
                        </Typography>
                    </Box>
                    <Box sx={{ borderBottom: `1px solid ${GREY_100}` }}>
                        <SharingList />
                        <Typography
                            sx={{
                                fontSize: "0.875rem",
                                lineHeight: "1.5rem",
                                color: GREY_500,
                                padding: "1rem",
                            }}
                        >
                            Your account information is protected by the Family Educational Rights
                            and Privacy Act (FERPA). It cannot be disclosed to anyone without your
                            consent. By granting access, you consent to the designated individual
                            accessing your account for the specified purposes. This authorization
                            will remain valid until you remove the individual as an authorized user.
                        </Typography>
                    </Box>
                    <Box sx={{ padding: "1rem" }}>
                        <Typography sx={{ paddingBottom: "1rem" }}>
                            Enter their information:
                        </Typography>
                        {networkError && (
                            <Box sx={{ color: RED_500, margin: "1rem 0", display: "flex" }}>
                                <OverdueInfoIcon />
                                {networkError}
                            </Box>
                        )}
                        <form onSubmit={handleSubmit}>
                            <MeadowInput
                                label="First name"
                                onChange={(e) => setFirstNameValue(e.target.value)}
                                onBlur={() => setFirstNameValue(firstNameValue.trim())}
                                value={firstNameValue}
                                autoFocus={false}
                                errorType={
                                    firstNameErrored ? ErrorTypes.FIRST_NAME : ErrorTypes.NULL
                                }
                                isError={firstNameErrored}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "1rem",
                                }}
                            />
                            <MeadowInput
                                label="Last name"
                                onChange={(e) => setLastNameValue(e.target.value)}
                                onBlur={() => setLastNameValue(lastNameValue.trim())}
                                value={lastNameValue}
                                autoFocus={false}
                                errorType={lastNameErrored ? ErrorTypes.LAST_NAME : ErrorTypes.NULL}
                                isError={lastNameErrored}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "1rem",
                                }}
                            />
                            <MeadowInput
                                label="Email"
                                onChange={(e) => setEmailValue(e.target.value)}
                                onBlur={() => setEmailValue(emailValue.trim())}
                                value={emailValue}
                                autoFocus={false}
                                errorType={isEmailEmptyOrInvalid}
                                isError={emailErrored || isEmailInvalid}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "1rem",
                                }}
                            />
                        </form>
                        <Button
                            sx={{ marginBottom: "0.5rem" }}
                            type="submit"
                            onClick={() => handleSubmit()}
                            variant="primary"
                        >
                            Grant Access
                        </Button>
                    </Box>
                </Box>
            ) : (
                <></>
            )}
            <PoweredByMeadow />
            {isLoading ? <LoadingDialog open={isLoading} /> : <></>}
        </MeadowContainer>
    );
};

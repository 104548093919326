interface ImageWithFallbackInterface {
    src: string;
    alt: string;
    height: number;
    fallback: string;
}

const ImageWithFallback = ({ src, alt, height, fallback }: ImageWithFallbackInterface) => {
    return (
        <img
            alt={alt}
            src={src}
            height={height}
            onError={(e) => {
                e.currentTarget.src = fallback;
            }}
        />
    );
};

export default ImageWithFallback;

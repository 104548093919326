import { Box, Button, Card, ListItem, Typography } from "@mui/material";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { BLACK_000 } from "../../../theme/colors";
import calendar from "../../../assets/calendar_dark.svg";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";
import { PaymentPlanService } from "../../../services/PaymentPlanService";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PaymentContext } from "../../../providers/PaymentsProvider";
import { Logging } from "../../../services/Logging";
import * as Sentry from "@sentry/react";

type EnrollmentCardProps = {
    student: PaymentsStudent;
};
export default function PaymentPlanEnrollmentCard({ student }: EnrollmentCardProps) {
    const { isMobile } = useBreakpoint();
    const { setCurrentStudentId } = useContext(PaymentContext);
    const navigate = useNavigate();
    const [paymentPlanService] = useState(() => new PaymentPlanService());
    const [isStartingPaymentPlan, setIsStartingPaymentPlan] = useState<boolean>(false);

    const cardSx = {
        padding: "1rem",
        width: isMobile ? "100%" : "50.5rem",
        boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        borderRadius: "0.5rem",
    };

    const handleCreatePaymentPlan = async () => {
        setIsStartingPaymentPlan(true);
        setCurrentStudentId(student.studentId);
        try {
            const paymentPlanId = await paymentPlanService.createPaymentPlan(student);
            setIsStartingPaymentPlan(false);
            navigate("/payment-plan/details", {
                state: { data: student, paymentPlanId },
            });
        } catch (error) {
            Sentry.captureException(error);
            setIsStartingPaymentPlan(false);
        }
    };

    useEffect(() => {
        Logging.trackPaymentPlanCardShown(student.studentId);
    }, []);

    return (
        <ListItem sx={{ padding: 0 }}>
            <Card sx={cardSx}>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.75rem",
                        }}
                    >
                        <Box sx={{ display: "flex", gap: "0.75rem" }}>
                            <img src={calendar} alt="" />
                            <Typography
                                sx={{
                                    color: BLACK_000,
                                    fontSize: "0.875rem",
                                    fontWeight: 500,
                                    lineHeight: "1.25rem",
                                }}
                            >
                                Need to pay in installments?
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            Pay your {student.eligiblePaymentPlans[0].academicTermDescription} bill
                            in monthly installments.
                        </Typography>

                        <Button
                            sx={{ maxWidth: isMobile ? "auto" : "16.25rem" }}
                            variant="secondary"
                            onClick={() => handleCreatePaymentPlan()}
                            disabled={isStartingPaymentPlan}
                        >
                            Start a payment plan
                        </Button>
                    </Box>
                </Box>
            </Card>
        </ListItem>
    );
}

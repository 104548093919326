import { Box } from "@mui/material";
import { GREEN_500, GREY_85, GREY_PLACEHOLDER } from "../../../theme/colors";
import faceSmile from "../../../assets/face-smile-new.svg";
import { StudentPaymentPlan } from "../Student.model";

interface PaymentTimelineProps {
    paymentPlan: StudentPaymentPlan;
}
export default function PaymentTimeline({ paymentPlan }: PaymentTimelineProps) {
    const timelineSx = {
        height: "4px",
        borderRight: `1px solid ${GREY_PLACEHOLDER}`,
        borderRadius: "30px",
        width: "100%",
        "&:last-child": {
            borderRight: "none",
        },
    };
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    borderRadius: "30px",
                    height: "4px",
                    background: GREY_85,
                    width: "100%",
                }}
            >
                {paymentPlan.installments.map((_, index) => (
                    <Box
                        sx={{
                            ...timelineSx,
                            background: index < paymentPlan.installmentsPaid ? GREEN_500 : GREY_85,
                        }}
                        key={index}
                    ></Box>
                ))}
            </Box>
            <img src={faceSmile} />
        </Box>
    );
}

import { Box, Typography } from "@mui/material";
import useBreakpoint from "../../hooks/useBreakpoint";

export interface FormHeaderProps {
    headerText: string;
    subText: string;
}

export default function FormHeader({ headerText, subText }: FormHeaderProps) {
    const { isMobile } = useBreakpoint();
    const desktopHeaderSX = {
        textAlign: "center",
        paddingTop: "2.5rem",
        paddingBottom: "0.5rem",
    };
    const mobileHeaderSX = {
        textAlign: "center",
    };
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={isMobile ? mobileHeaderSX : desktopHeaderSX} variant="h1">
                {headerText}
            </Typography>
            <Typography sx={{ paddingTop: "1rem", paddingBottom: "2.5rem" }} variant="body1">
                {subText}
            </Typography>
        </Box>
    );
}

import { Box, Button, Card, ListItem, Typography } from "@mui/material";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { CalendarIcon } from "../../icons/CalendarIcon";
import PaymentTimeline from "./PaymentTimeline";
import { formatChargeDate } from "../utilities";
import {
    determineInstallmentIcon,
    determineInstallmentStatement,
    getInstallmentAmountElement,
    getInstallmentStatusElement,
} from "./utilities";
import { BLACK_000, GREY_100, GREY_500 } from "../../../theme/colors";
import { useContext, useState } from "react";
import { StudentPaymentPlan } from "../Student.model";
import {
    autopayFooterSx,
    autopayFooterTextSx,
    cardSx,
    contactUsSx,
    paymentPlanInnerWrapperSx,
    titleSx,
    visibleSx,
} from "./styles";
import { AutopayToken } from "../../payment/payment-methods/AutopayToken";
import { PaymentInstrumentType } from "../../../reducers/PaymentsReducer";
import { ConfigContext } from "../../../context/ConfigContext";

interface PaymentPlanCardProps {
    paymentPlan: StudentPaymentPlan;
}
export default function PaymentPlanCard({ paymentPlan }: PaymentPlanCardProps) {
    const { isMobile } = useBreakpoint();
    const { config } = useContext(ConfigContext);
    const [viewAll, setViewAll] = useState<boolean>(true);

    return (
        <>
            <ListItem sx={{ padding: 0 }}>
                <Card sx={cardSx}>
                    <Box sx={paymentPlanInnerWrapperSx}>
                        <Box sx={titleSx}>
                            <CalendarIcon sx={{ height: "1rem", width: "1rem" }} />
                            <Typography
                                sx={{
                                    fontSize: "0.875rem",
                                    fontWeight: 500,
                                    lineHeight: "1rem",
                                    color: BLACK_000,
                                }}
                                data-testid="payment-plan-card-heading"
                            >
                                Your payment plan
                            </Typography>
                        </Box>

                        <Box>
                            <PaymentTimeline paymentPlan={paymentPlan} />
                        </Box>

                        <Box>
                            {paymentPlan.installments.map((installment, index) => {
                                // this is another easier way to accomplish the opening and closing,
                                // leaving here for feedback, but will delete before merging
                                // if (!viewAll && index > 0) return null;
                                return (
                                    <Box
                                        sx={{
                                            ...visibleSx,
                                            visibility:
                                                !viewAll && index > 0 ? "hidden" : "visible",
                                            borderBottom: !viewAll
                                                ? `1px solid ${GREY_100}`
                                                : "none",
                                            transition: "all 0.1s ease-in-out",
                                            opacity: !viewAll && index > 0 ? 0 : 1,
                                            height: !viewAll && index > 0 ? 0 : "auto",
                                            padding: !viewAll && index > 0 ? 0 : "0.75rem 0",
                                        }}
                                        key={index}
                                        data-testid="payment-plan-card-installment"
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                gap: "1rem",
                                            }}
                                        >
                                            {determineInstallmentIcon(installment.status)}
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "1rem",
                                                }}
                                                data-testid="payment-plan-card-installment-status"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "0.875rem",
                                                        lineHeight: "1rem",
                                                        color: BLACK_000,
                                                    }}
                                                    data-testid="payment-plan-card-installment-status-date"
                                                >
                                                    {formatChargeDate(
                                                        installment.dueDate.toString(),
                                                        {
                                                            dateOnly: true,
                                                            useShortMonthFormat: true,
                                                        }
                                                    )}
                                                </Typography>
                                                {getInstallmentStatusElement(installment.status)}
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "1rem",
                                            }}
                                            data-testid="payment-plan-card-installment-amount"
                                        >
                                            {getInstallmentAmountElement(installment)}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                        <Typography
                            sx={{
                                fontSize: "0.875rem",
                                fontWeight: 400,
                                lineHeight: "1.25rem",
                                textAlign: "center",
                                color: GREY_500,
                            }}
                        >
                            {determineInstallmentStatement(paymentPlan)}
                        </Typography>

                        <Button
                            sx={{ borderRadius: "3rem" }}
                            onClick={() => setViewAll(!viewAll)}
                            variant="tertiary"
                        >
                            {viewAll ? "View less" : "View all"}
                        </Button>
                    </Box>
                    {paymentPlan.isAutopayEnabled && (
                        <Box sx={autopayFooterSx(isMobile)}>
                            <Box sx={{ display: "flex", gap: "0.25rem" }}>
                                <AutopayToken showColon={true} />
                                <Typography sx={autopayFooterTextSx}>
                                    {paymentPlan.autopayPaymentInstrumentType &&
                                    paymentPlan.autopayPaymentInstrumentType ===
                                        PaymentInstrumentType.PAYMENT_CARD
                                        ? `Card ending in ${
                                              paymentPlan.autopayCardLastFour || "****"
                                          }`
                                        : `Bank account ending in ${
                                              paymentPlan.autopayBankAccountNumberLastFour || "****"
                                          }`}
                                </Typography>
                            </Box>

                            <Box>
                                <Typography sx={autopayFooterTextSx}>
                                    Need to make changes?{" "}
                                    <a
                                        style={contactUsSx}
                                        href={`https://help.meadowpay.com/${config?.abbreviation}#paymentPlans`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Contact us
                                    </a>
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Card>
            </ListItem>
        </>
    );
}

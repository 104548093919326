import { Alert } from "@mui/material";
import {
    BLUE_100,
    BLUE_700,
    GREEN_400,
    GREEN_700,
    RED_200,
    RED_700,
    YELLOW_200,
    YELLOW_700,
} from "../../theme/colors";
import { PaymentsStudent } from "../../reducers/PaymentsReducer";
import { STUDENT_STATUS } from "../../constants";
import { SuccessIcon } from "../icons/SuccessIcon";
import { CalendarIcon } from "../icons/CalendarIcon";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
interface BillDueBannerInterface {
    student: PaymentsStudent;
}
export function BillDueBanner({ student }: BillDueBannerInterface) {
    interface AlertComponentProps {
        backgroundColor: string;
        color: string;
        statusLabel: string;
        icon: React.ReactNode;
    }
    const AlertComponent = ({ backgroundColor, color, statusLabel, icon }: AlertComponentProps) => (
        <Alert
            sx={{
                backgroundColor: backgroundColor,
                borderRadius: "0.5rem",
                padding: "0.25rem 0.5rem",
                height: "1.5rem",
                width: "fit-content",
                fontWeight: 500,
                fontSize: "0.75rem",
                lineHeight: "1rem",
                alignItems: "center",
                marginTop: "0.5rem",
                color: color,
                "& .MuiAlert-icon": {
                    marginRight: "4px",
                },
                "& .MuiSvgIcon-root": { fontSize: 16 },
            }}
            icon={icon}
            severity="warning"
            data-testid="bill-due-banner"
        >
            {statusLabel}
        </Alert>
    );
    return (
        <>
            {student.status === STUDENT_STATUS.PAYMENT_DUE && (
                <AlertComponent
                    backgroundColor={YELLOW_200}
                    color={YELLOW_700}
                    statusLabel={student.statusLabel}
                    icon={<CalendarIcon sx={{ color: YELLOW_700 }} />}
                />
            )}
            {student.status === STUDENT_STATUS.PAYMENT_DUE_TODAY && (
                <AlertComponent
                    backgroundColor={RED_200}
                    color={RED_700}
                    statusLabel={student.statusLabel}
                    icon={<CalendarIcon sx={{ color: RED_700 }} />}
                />
            )}
            {student.status === STUDENT_STATUS.PENDING && (
                <AlertComponent
                    backgroundColor={BLUE_100}
                    color={BLUE_700}
                    statusLabel={student.statusLabel}
                    icon={<AccessTimeIcon sx={{ color: BLUE_700 }} />}
                />
            )}
            {student.status === STUDENT_STATUS.NO_PAYMENT_DUE && (
                <AlertComponent
                    backgroundColor={GREEN_700}
                    color={GREEN_400}
                    statusLabel={student.statusLabel}
                    icon={<SuccessIcon sx={{ color: GREEN_400 }} />}
                />
            )}
            {student.status === STUDENT_STATUS.PAST_DUE && (
                <AlertComponent
                    backgroundColor={RED_200}
                    color={RED_700}
                    statusLabel={student.statusLabel}
                    icon={<CalendarIcon sx={{ color: RED_700 }} />}
                />
            )}
            {student.status === STUDENT_STATUS.BILLING_DISABLED && (
                <AlertComponent
                    backgroundColor={BLUE_100}
                    color={BLUE_700}
                    statusLabel={student.statusLabel}
                    icon={<AccessTimeIcon sx={{ color: BLUE_700 }} />}
                />
            )}
        </>
    );
}

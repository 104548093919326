import { GREY_100 } from "../../../theme/colors";

export const gridSx = {
    display: "grid",
    gap: "1rem",
    width: "100%",
};

export const wrapperSx = (isMobile: boolean) => ({
    padding: 0,
    width: isMobile ? "100%" : "50.5rem",
});

export const contentWrapperSx = {
    display: "grid",
    gap: "1rem",
    width: "100%",
    borderTop: `1px solid ${GREY_100}`,
    marginTop: "1rem",
};

export const makeAPaymentButtonSx = (isMobile: boolean) => ({
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    width: isMobile ? "100%" : "16.25rem",
});

export const securePaymentSx = (isMobile: boolean) => ({
    justifyContent: isMobile ? "center" : "flex-start",
});

export const nameSx = {
    fontWeight: 500,
    fontSize: "0.875",
    lineHeight: "1.25rem",
    marginBottom: "0.5rem",
};
export const cardSx = {
    padding: "1rem",
    boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    borderRadius: "0.5rem",
};

export const accountNumberSx = {
    ...nameSx,
    fontWeight: 400,
};

export const boldSx = {
    fontWeight: 500,
};

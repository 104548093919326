import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import AppRoutes from "./routes/AppRoutes";
import { Box } from "@mui/material";
import { Logging } from "./services/Logging";
import ErrorPage from "./ErrorPage";
Logging.init();

function App() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <ErrorBoundary showDialog={true} fallback={<ErrorPage />}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "100vh",
                            overflow: "auto",
                        }}
                    >
                        <AppRoutes />
                    </Box>
                </ErrorBoundary>
            </BrowserRouter>
        </React.StrictMode>
    );
}

export default App;

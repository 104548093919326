import { BLACK_000, GREY_400, RED_500 } from "../../../theme/colors";
import { WEBSAFE_FONT_FAMILY } from "../../../theme/fonts";

export const inputStyle = {
    color: `${BLACK_000}`,
    padding: "0.5rem 1rem",
    fontFamily: WEBSAFE_FONT_FAMILY,
    fontSize: "1rem",
    border: "none",
};

export const errorInputStyle = {
    color: `${BLACK_000}`,
    padding: "0.5rem 1rem",
    fontFamily: WEBSAFE_FONT_FAMILY,
    fontSize: "1rem",
    border: `2px solid ${RED_500}`,
    borderRadius: "10px",
};

export const fieldIframeSx = {
    iframe: {
        height: "45px",
        width: "100%",
        border: `2px solid ${GREY_400}`,
        borderRadius: "10px",
    },
};

export const errorFieldIframeSx = {
    iframe: {
        height: "45px",
        width: "100%",
        border: `2px solid ${RED_500}`,
        borderRadius: "10px",
    },
};
export const selectSx = {
    height: "45px",
    width: "100%",
    color: `${BLACK_000}`,
    padding: "0.5rem 1rem",
    fontFamily: WEBSAFE_FONT_FAMILY,
    fontSize: "1rem",
    marginTop: "0.25rem",
    border: `2px solid ${GREY_400}`,
    borderRadius: "10px",
};

export const errorSelectSx = {
    height: "45px",
    color: `${BLACK_000}`,
    padding: "0.5rem 1rem",
    fontFamily: WEBSAFE_FONT_FAMILY,
    fontSize: "1rem",
    marginTop: "0.25rem",
    border: `2px solid ${RED_500}`,
    borderRadius: "10px",
};

export const fieldHolderSx = {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    ".MuiOutlinedInput-notchedOutline": {
        border: "none",
        padding: 0,
    },
    ".MuiOutlinedInput-input": {
        border: "none",
        padding: 0,
    },
};

export const errorSx = {
    marginTop: "-1.25rem",
    marginBottom: "1rem",
    color: RED_500,
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
};

export const selectErrorSx = {
    marginTop: "-0.875rem",
    marginBottom: "1rem",
    color: RED_500,
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1rem",
};

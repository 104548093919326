import { parseJwt, sessionStorageGet, sessionStorageRemove } from "./utilities";
import { Logging } from "./Logging";

export const makeAuthRequestBodyInterceptor = () => async (config: any) => {
    const authToken = sessionStorageGet("authToken");

    if (typeof authToken !== "string") {
        return config;
    }
    const decodedToken = parseJwt(authToken);
    const expiresAtDate = new Date(decodedToken.expiresAt);
    const todaysDate = new Date();

    if (todaysDate > expiresAtDate) {
        Logging.authTokenExpired();
        sessionStorageRemove("authToken");
        window.location.href = "/signin?expired=true";
        return config;
    }

    config.headers["x-auth-token"] = authToken;

    return config;
};

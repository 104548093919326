export enum LoggingEvents {
    SIGN_UP = "User Sign Up",
    LOG_OUT = "User Log Out",
    AUTH_TOKEN_EXPIRED = "Auth Token Expired",
    CONTACT_US = "Contact Us",
    HELP = "Help",
    VIEW_BILL = "View Bill",
    LOGIN = "User Login",
    STUDENT_ACCOUNT_IMPRESSION = "Student Account Impression",
}

export enum PaymentPlanLoggingEvents {
    PAYMENT_PLAN_CARD_SHOWN = "Payment Plan Card Shown",
}

export type AllLoggingEvents = LoggingEvents | PaymentPlanLoggingEvents;

import { useContext, useEffect, useState } from "react";
import StudentCard from "../student-card/StudentCard";
import { PaymentContext } from "../../../providers/PaymentsProvider";
import MeadowContainer from "../../container";
import { GREY_100, GREY_800 } from "../../../theme/colors";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { Box } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { BillingService } from "../../../services/BillingService";
import { BillingTerm } from "../Billing.model";
import { BillingDetailsFooter } from "./BillingDetailsFooter";
import { Detail } from "./Detail";
import { SchoolService } from "../../../services/SchoolService";
import { BackIcon } from "../../icons/BackIcon";
import LoadingDialog from "../../modal/LoadingDialog";
import { Logging } from "../../../services/Logging";
import * as Sentry from "@sentry/react";

export const BillingDetails = () => {
    const [billingService] = useState(() => new BillingService());
    const [schoolService] = useState(() => new SchoolService());
    const { getCurrentStudent, setStudentSchool } = useContext(PaymentContext);
    const student = getCurrentStudent();
    const { isMobile } = useBreakpoint();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [billingDetails, setBillingDetails] = useState<BillingTerm[] | null>(null);
    const navigate = useNavigate();
    const wrapperSx = {
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile ? "unset" : "center",
    };
    const cardWrapperSx = {
        paddingBottom: "1rem",
        paddingLeft: isMobile ? "1rem" : 0,
        paddingRight: isMobile ? "1rem" : 0,
        paddingTop: 0,
        borderBottom: `1px solid ${GREY_100}`,
        "&:last-child": {
            borderBottom: "none",
        },
    };
    const simpleHeaderSx = {
        padding: "1rem",
        backgroundColor: GREY_800,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: isMobile ? "unset" : "50.5rem",
    };

    useEffect(() => {
        async function fetchBillingDetails() {
            try {
                if (student) {
                    setIsLoading(true);
                    const billingResponse = await billingService.getBillingDetails(
                        student.studentId
                    );
                    setBillingDetails(billingResponse);

                    const schoolResponse = await schoolService.getSchool(student.studentId);
                    setStudentSchool(schoolResponse);
                    Logging.viewBill(student, schoolResponse.id);
                }
            } catch (err) {
                Sentry.captureException(err);
            }
        }
        fetchBillingDetails().finally(() => {
            setIsLoading(false);
        });
    }, []);

    if (!student) {
        return <Navigate to="/billing" />;
    }

    const shouldRenderBillingDetails = !isLoading && billingDetails;
    return (
        <MeadowContainer
            fullWidth
            noPadding
            sx={{
                backgroundColor: GREY_800,
                height: "100%",
            }}
        >
            <Box sx={wrapperSx}>
                <Box sx={simpleHeaderSx}>
                    <BackIcon
                        data-testid="back-icon-desktop"
                        sx={{
                            "&:hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={() => navigate(-1)}
                    />
                </Box>
                {isMobile ? (
                    // on mobile safari, the student name font size was being increased for some unknown reason :thinking:
                    <>
                        <Box sx={{ padding: "1rem" }}>
                            <StudentCard student={student} makeAPayment={true} />
                        </Box>

                        {shouldRenderBillingDetails ? (
                            billingDetails.map((detail: BillingTerm, index) => {
                                return (
                                    <Box key={index} sx={cardWrapperSx}>
                                        <Detail detail={detail} />
                                    </Box>
                                );
                            })
                        ) : (
                            <LoadingDialog open={isLoading} />
                        )}
                    </>
                ) : (
                    <Box sx={{ width: "50.5rem" }}>
                        <StudentCard student={student} makeAPayment={true} />
                        {shouldRenderBillingDetails ? (
                            billingDetails.map((detail: BillingTerm, index) => {
                                return (
                                    <Box key={index} sx={cardWrapperSx}>
                                        <Detail detail={detail} />
                                    </Box>
                                );
                            })
                        ) : (
                            <LoadingDialog open={isLoading} />
                        )}
                    </Box>
                )}
            </Box>
            <Box sx={{ ...wrapperSx, padding: 0 }}>
                <Box sx={{ width: isMobile ? "auto" : "50.5rem" }}>
                    <BillingDetailsFooter />
                </Box>
            </Box>
        </MeadowContainer>
    );
};

import { Button, Typography } from "@mui/material";
import { BLUE_700 } from "../../theme/colors";
import { useState } from "react";
import AddAccountModal from "./AddAccountModal";
import { AddIcon } from "../icons/AddIcon.js";
import useBreakpoint from "../../hooks/useBreakpoint";

export const AddAccountButton = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { isMobile } = useBreakpoint();
    const addIconSx = {
        fill: BLUE_700,
        height: "1rem",
    };
    return (
        <>
            <Button
                data-testid="add-account-button"
                onClick={() => setIsDialogOpen(true)}
                variant="secondary"
                sx={{
                    display: "flex",
                    gap: "0.25rem",
                    alignItems: "center",
                    width: isMobile ? "100%" : "fit-content",
                    justifyContent: "center",
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
            >
                <AddIcon sx={addIconSx} />
                <Typography sx={{ color: BLUE_700 }}>Add account</Typography>
            </Button>

            {isDialogOpen && (
                <AddAccountModal open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
            )}
        </>
    );
};

/**
 * When SSO is not enabled at a school, this is the component
 * that a student sees when navigating to `pay.wbu.edu` (or
 * equivalent for other schools).
 */

import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { GREY_PLACEHOLDER } from "../../theme/colors";
import useBreakpoint from "../../hooks/useBreakpoint";
import homeicon from "../../assets/homeicon.svg";

export default function Welcome() {
    const { isMobile } = useBreakpoint();

    const welcomeElementSX = {
        width: isMobile ? "100%" : "24.375rem",
        margin: ".5rem auto",
    };
    const dividerSX = {
        width: isMobile ? "100%" : "24.375rem",
        color: GREY_PLACEHOLDER,
        margin: ".5rem auto",
    };
    const containerSX = {
        padding: "1.5rem",
    };

    return (
        <Box sx={containerSX}>
            <img alt="Home icon" src={homeicon} />
            <Box>
                <Typography sx={welcomeElementSX} variant="h1">
                    Pay your school bills online easily and securely.
                </Typography>
                <Link to="/signin" state={{ shouldHideSSO: true }}>
                    <Button sx={welcomeElementSX} variant="primary">
                        Sign in
                    </Button>
                </Link>
                <Divider sx={dividerSX}>OR</Divider>
                <Link to="/signup">
                    <Button sx={welcomeElementSX} variant="secondary">
                        Create Account
                    </Button>
                </Link>
            </Box>
        </Box>
    );
}

import { meadowHttpFactory, IMeadowHttpFactory } from "./meadowHttpFactory";

export type IAdminService = {
    addAdminAlly(schoolStudentId: string): Promise<any>;
};

export class AdminService implements IAdminService {
    private http: IMeadowHttpFactory;
    public constructor(http?: IMeadowHttpFactory) {
        this.http = http || meadowHttpFactory({});
    }

    // adds a admin ally
    public async addAdminAlly(schoolStudentId: string): Promise<any> {
        let result;
        try {
            result = await this.http.post("/v2/auth/admin_ally", {
                schoolStudentId,
            });
        } catch (error: any) {
            console.error("error in adding admin ally - v2: " + schoolStudentId);
            console.error(error.response.data.error);
            throw error;
        }

        return result.data;
    }
}

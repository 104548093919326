export enum ErrorTypes {
    NULL = "",
    EMPTY = "Please enter email address.",
    INVALID = "This is not a valid email address. Please fix and try again.",
    TERMS = "You must agree to the terms and conditions and privacy policy.",
    TERMS_AND_CONDITIONS = "You must agree to the terms and conditions.",
    GENERIC = "Something went wrong. Please try again.",
    EMPTY_CODE = "Please enter the code we sent you.",
    FIRST_NAME = "A first name is required for the ally you want to invite.",
    LAST_NAME = "A last name is required for the ally you want to invite.",
    ALLY_EMAIL = "A valid email address is required for the ally you want to invite.",
    INCORRECT_STUDENT_ID = "The Student ID you entered is incorrect, please try again.",
    BANK_ACCOUNT_ERROR = "There is a problem with your bank account information. Please remove spaces from your routing number and confirm that your account number is correct. Please note that only U.S. bank accounts are supported.",
}

import { TooltipProps, Tooltip, IconButton, ClickAwayListener } from "@mui/material";
import { GREY_600, WHITE_000 } from "../../theme/colors";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useState } from "react";
import tooltip from "../../assets/tooltip.svg";

export const MeadowTooltip = ({ className, ...props }: TooltipProps) => {
    const { isMobile } = useBreakpoint();
    const [open, setOpen] = useState<boolean>(false);

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
                <Tooltip
                    arrow
                    {...props}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    open={open}
                    onClose={() => setOpen(false)}
                    classes={{ popper: className }}
                    enterTouchDelay={0}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: GREY_600,
                                color: WHITE_000,
                                padding: "0.75rem",
                                fontSize: "0.875rem",
                                fontFamily: "Tisa Sans Pro",
                                width: "235px",
                                lineHeight: "1.25rem",
                                marginLeft: isMobile ? "1rem" : 0,
                                "& .MuiTooltip-arrow": {
                                    "&:before": {
                                        marginLeft: isMobile ? "-0.5rem" : 0,
                                    },
                                    color: GREY_600,
                                    overflow: "visible",
                                },
                            },
                        },
                    }}
                >
                    <IconButton
                        sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            " &:hover": { background: "none" },
                        }}
                        onClick={() => setOpen(!open)}
                    >
                        <img src={tooltip} />
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

import * as Sentry from "@sentry/react";
import { Box, Button, FormGroup } from "@mui/material";
import PoweredByMeadow from "../footer/PoweredByMeadow";
import { FormEvent, useContext, useEffect, useState } from "react";
import FormHeader from "../form/FormHeader";
import CheckboxRow from "../form/CheckboxRow";
import validator from "validator";
import { BLUE_700, GREY_MANATEE } from "../../theme/colors";
import { ErrorTypes } from "../../enums/errors";
import MeadowContainer from "../container";
import MeadowInput from "../form/MeadowInput";
import { AuthService } from "../../services/AuthService";
import maskEmail from "../utils/maskEmail";
import { UserContext } from "../../context/UserContext";
import { parseError } from "../../services/utilities";
import { Link, useNavigate } from "react-router-dom";
import { setUserData } from "../utils/setUserData";
import { Logging } from "../../services/Logging";
import { ContactUs } from "./ContactUs";

export default function ConfirmAllyAccount() {
    const [emailValue, setEmailValue] = useState<string>("");
    const [maskedEmail, setMaskedEmail] = useState<string>("");
    const [errorType, setErrorType] = useState<ErrorTypes | string>(ErrorTypes.NULL);
    const [isCheckedError, setIsCheckedError] = useState<boolean>(false);
    const [termsCheck, setTermsCheck] = useState<boolean>(false);
    const [codeValue, setCodeValue] = useState<string>("");
    const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [onboardChallenge, setOnboardChallenge] = useState<string>("");
    const [authService] = useState(() => new AuthService());
    const { setUser } = useContext(UserContext);
    const resendButtonSX = {
        borderBottom: `1px solid ${GREY_MANATEE}`,
        color: GREY_MANATEE,
        borderRadius: 0,
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1rem",
    };
    const navigate = useNavigate();
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const onboardChallengeValue = params.get("onboardChallenge");
        if (onboardChallengeValue) setOnboardChallenge(onboardChallengeValue);
    }, []);
    const labelString = () => (
        <>
            I have read, understood, and agree to the{" "}
            <Link style={{ textDecoration: "none", color: BLUE_700 }} to="/legal/terms">
                terms of use
            </Link>{" "}
            and{" "}
            <Link style={{ textDecoration: "none", color: BLUE_700 }} to="/legal/privacy">
                privacy policy.
            </Link>
        </>
    );

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (emailValue.length === 0) setErrorType(ErrorTypes.EMPTY);
        if (emailValue.length > 0) {
            const emailToMask = maskEmail(emailValue);

            setMaskedEmail(emailToMask);
            const isEmail = validator.isEmail(emailValue);

            if (!isEmail) {
                setErrorType(ErrorTypes.INVALID);
            } else {
                setErrorType(ErrorTypes.NULL);
            }
            if (!termsCheck) {
                setIsCheckedError(true);
                return;
            } else {
                setIsCheckedError(false);
            }
            try {
                if (isEmail) {
                    setIsLoading(true);
                    await authService.allySendOtp(emailValue, onboardChallenge);
                    setIsCodeSent(true);
                    setIsLoading(false);
                }
            } catch (error) {
                setErrorType(parseError(error));
                setIsLoading(false);
                Sentry.captureException(error);
            }
        }
    };

    const handleSubmitCode = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await authService.allyConfirmOtp(emailValue, onboardChallenge, codeValue);
            const userData = await setUserData();
            setUser(userData);
            Logging.signup();
            setIsLoading(false);
            navigate("/billing");
        } catch (error) {
            setErrorType(parseError(error));
            Sentry.captureException(error);
            setIsLoading(false);
        }
    };

    return (
        <MeadowContainer sx={{ padding: "1.5rem" }}>
            <Box>
                <>
                    <FormGroup>
                        <form>
                            <FormHeader
                                headerText={"Sign in"}
                                subText={
                                    isCodeSent
                                        ? `Please enter the code that was sent to ${maskedEmail}`
                                        : "To sign in please enter your email to receive a one-time verification code."
                                }
                            />
                            {isCodeSent ? (
                                <MeadowInput
                                    label="Enter code"
                                    value={codeValue}
                                    onChange={(e) => setCodeValue(e.target.value)}
                                    errorType={errorType}
                                    isError={errorType.length > 0}
                                    inputMode="numeric"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                />
                            ) : (
                                <MeadowInput
                                    label="Email"
                                    onChange={(e) => setEmailValue(e.target.value)}
                                    value={emailValue}
                                    onBlur={() => setEmailValue(emailValue.trim())}
                                    errorType={errorType}
                                    inputMode="email"
                                    isError={errorType.length > 0}
                                    autoComplete={"email"}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingBottom: 0,
                                    }}
                                />
                            )}
                            {!isCodeSent && (
                                <Box sx={{ margin: "1.5rem 0" }}>
                                    <CheckboxRow
                                        isChecked={termsCheck}
                                        onCheck={() => {
                                            setTermsCheck(!termsCheck);
                                        }}
                                        error={isCheckedError}
                                        label={labelString}
                                    />
                                </Box>
                            )}
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={isLoading}
                                sx={{
                                    marginTop: isCodeSent ? "1.75rem" : 0,
                                }}
                                onClick={
                                    isCodeSent ? (e) => handleSubmitCode(e) : (e) => handleSubmit(e)
                                }
                            >
                                {isCodeSent ? "Sign in" : "Send code"}
                            </Button>
                            {isCodeSent && (
                                <>
                                    <Button
                                        type="submit"
                                        variant="secondary"
                                        disabled={false}
                                        sx={{
                                            marginTop: isCodeSent ? "1.75rem" : 0,
                                        }}
                                        onClick={() => {
                                            setErrorType(ErrorTypes.NULL);
                                            setIsCodeSent(false);
                                            setEmailValue("");
                                            setCodeValue("");
                                        }}
                                    >
                                        {"Back"}
                                    </Button>
                                    <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
                                        <Button
                                            onClick={(e) => handleSubmit(e)}
                                            sx={resendButtonSX}
                                        >
                                            Resend code
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </form>
                    </FormGroup>
                </>
            </Box>
            <ContactUs />
            <PoweredByMeadow />
        </MeadowContainer>
    );
}

import { useContext, useEffect } from "react";
import { PaymentContext } from "../providers/PaymentsProvider";
import { useNavigate } from "react-router-dom";

const useAmountToPay = (utmSource: string) => {
    // if not amount to pay, redirect to billing page
    const navigate = useNavigate();
    const { getAmountToPay } = useContext(PaymentContext);
    useEffect(() => {
        const amountToPay = getAmountToPay();

        if (amountToPay === undefined) {
            navigate(`/billing?utm_source=${utmSource}`);
        }
    }, []);
};

export default useAmountToPay;

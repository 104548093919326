import clock from "../../../assets/clock.svg";
import clockRed from "../../../assets/clock_red.svg";
import checkmarkOutline from "../../../assets/checkmark_outline.svg";
import infoRed from "../../../assets/info_red.svg";
import plus from "../../../assets/plus.svg";
import { InstallmentStatus, PaymentPlanInstallment, StudentPaymentPlan } from "../Student.model";
import { capitalizeFirstLetter, formatter } from "../utilities.js";
import { BLACK_000, GREY_500, RED_700 } from "../../../theme/colors.js";
import { Box, Typography } from "@mui/material";

export const determineInstallmentIcon = (installmentStatus: InstallmentStatus) => {
    const installmentIconStyle = {
        height: "100%",
    };

    if (installmentStatus === "Upcoming") {
        return (
            <img
                src={clock}
                style={installmentIconStyle}
                data-testid="payment-plan-card-installment-icon-upcoming"
            />
        );
    }

    if (installmentStatus === "Past Due") {
        return (
            <img
                src={clockRed}
                style={installmentIconStyle}
                data-testid="payment-plan-card-installment-icon-past-due"
            />
        );
    }

    if (installmentStatus === "Due Today") {
        return (
            <img
                src={clockRed}
                style={installmentIconStyle}
                data-testid="payment-plan-card-installment-icon-due-today"
            />
        );
    }

    if (installmentStatus === "Paid") {
        return (
            <img
                src={checkmarkOutline}
                style={installmentIconStyle}
                data-testid="payment-plan-card-installment-icon-paid"
            />
        );
    }

    if (installmentStatus === "Partially Remaining") {
        return (
            <img
                src={plus}
                style={installmentIconStyle}
                data-testid="payment-plan-card-installment-icon-partially-remaining"
            />
        );
    }

    if (installmentStatus === "Partially Past Due") {
        return (
            <img
                src={infoRed}
                style={installmentIconStyle}
                data-testid="payment-plan-card-installment-icon-partially-past-due"
            />
        );
    }

    if (installmentStatus === "Partially Due Today") {
        return (
            <img
                src={infoRed}
                style={installmentIconStyle}
                data-testid="payment-plan-card-installment-icon-partially-due-today"
            />
        );
    }
};

export const determineInstallmentStatement = (paymentPlan: StudentPaymentPlan) => {
    if (paymentPlan.installmentsRemaining === 1) {
        return `You have ${paymentPlan.installmentsRemaining} payment remaining for
        ${capitalizeFirstLetter(paymentPlan.periodOfEnrollment)}`;
    }

    if (paymentPlan.installmentsRemaining > 1) {
        return `You have ${paymentPlan.installmentsRemaining} payments remaining for
        ${capitalizeFirstLetter(paymentPlan.periodOfEnrollment)}`;
    }

    return `Payment due for ${paymentPlan.periodOfEnrollment}.`;
};

const installmentStatusMap = {
    Upcoming: GREY_500,
    "Past Due": RED_700,
    "Due Today": RED_700,
    Paid: GREY_500,
    "Partially Due Today": RED_700,
    "Partially Past Due": RED_700,
    "Partially Remaining": GREY_500,
};

export const getInstallmentStatusElement = (installmentStatus: InstallmentStatus): JSX.Element => {
    const installmentStatusElementSx = {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "1rem",
        color: installmentStatusMap[installmentStatus],
    };

    if (!installmentStatus.startsWith("Partially")) {
        return (
            <Typography
                sx={installmentStatusElementSx}
                data-testid="payment-plan-card-nonpartial-installment-status"
            >
                {installmentStatus}
            </Typography>
        );
    }

    const baseStatus = installmentStatus.replace("Partially ", "");
    return (
        <>
            <Typography
                sx={{ ...installmentStatusElementSx, color: GREY_500 }}
                data-testid="payment-plan-card-partial-installment-paid-status"
            >
                Paid
            </Typography>
            <Typography
                sx={installmentStatusElementSx}
                data-testid="payment-plan-card-partial-installment-base-status"
            >
                {baseStatus}
            </Typography>
        </>
    );
};

export const getInstallmentAmountElement = (installment: PaymentPlanInstallment): JSX.Element => {
    const installmentAmountElementSx = {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "1rem",
        color: installmentStatusMap[installment.status],
        textAlign: "right",
    };

    if (!installment.status.startsWith("Partially")) {
        return (
            <Typography
                sx={{ ...installmentAmountElementSx, color: BLACK_000 }}
                data-testid="payment-plan-card-nonpartial-installment-amount"
            >
                {formatter.format(installment.totalAmount)}
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            }}
        >
            <Typography
                sx={{ ...installmentAmountElementSx, color: BLACK_000 }}
                data-testid="payment-plan-card-partial-installment-total-amount"
            >
                {formatter.format(installment.totalAmount)}
            </Typography>
            <Typography
                sx={{ ...installmentAmountElementSx, color: GREY_500 }}
                data-testid="payment-plan-card-partial-installment-paid-amount"
            >
                {formatter.format(-installment.paidAmount)}
            </Typography>
            <Typography
                sx={installmentAmountElementSx}
                data-testid="payment-plan-card-partial-installment-remaining-amount"
            >
                {formatter.format(installment.remainingAmount)}
            </Typography>
        </Box>
    );
};

export const heightMinusHeader = "calc(100vh - 70px)";

// finix form errors
export const ACCOUNT_HOLDER_NAME_EMPTY = "Please enter account holder name.";
export const ROUTING_NUMBER_EMPTY = "Please enter routing number.";
export const ACCOUNT_TYPE_EMPTY = "Please select account type.";
export const ACCOUNT_NUMBER_EMPTY = "Please confirm account number.";
export const EXPIRATION_DATE_INCORRECT =
    "The expiration date is incorrect. Please fix and try again.";
export const CARD_NUMBER_INCORRECT = "The card number is incorrect. Please fix and try again.";
export const SECURITY_CODE_INCORRECT = "The security code is incorrect. Please fix and try again.";
export const ZIP_CODE_INCORRECT = "The zip code is incorrect. Please fix and try again.";
// finix fields
export const FINIX_ROUTING_NUMBER_FIELD = "bank_code";
export const FINIX_NAME_FIELD = "name";
export const FINIX_ACCOUNT_NUMBER_FIELD = "account_number";
export const FINIX_ACCOUNT_TYPE_FIELD = "account_type";
export const FINIX_CC_NUMBER_FIELD = "number";
export const FINIX_CC_EXPIRATION_FIELD = "expiration_date";
export const FINIX_CC_SECURITY_CODE_FIELD = "security_code";
export const FINIX_ZIP_CODE_FIELD = "address.postal_code";
export const AMOUNT_ERROR = "Please select an amount to pay.";
export const AXIOS_NETWORK_ERROR = "ERR_NETWORK";
export const finixBankAccountKeyArray: string[] = [
    FINIX_NAME_FIELD,
    FINIX_ACCOUNT_NUMBER_FIELD,
    FINIX_ROUTING_NUMBER_FIELD,
];
export const finixCreditCardKeyArray: string[] = [
    FINIX_NAME_FIELD,
    FINIX_CC_NUMBER_FIELD,
    FINIX_CC_EXPIRATION_FIELD,
    FINIX_CC_SECURITY_CODE_FIELD,
    FINIX_ZIP_CODE_FIELD,
];
// Payment due date status
export const STUDENT_STATUS = {
    PAYMENT_DUE: "paymentDue",
    PAYMENT_DUE_TODAY: "paymentDueToday",
    NO_PAYMENT_DUE: "noPaymentDue",
    PAST_DUE: "pastDue",
    DISABLED: "disabled",
    PENDING: "paymentPending",
    BILLING_DISABLED: "billingDisabled",
};

// account access status
export const ACCOUNT_ACCESS_STATUS = {
    ACCEPTED: "Accepted",
    PENDING: "Pending",
    EXPIRED: "Expired",
};

// defining this with `as const` and then re-typing
// below just allows us safe access while also being able
// to leverge the template literal type for values.
export const ROUTER_PATHS = {
    HOME: "/",
    CONFIRM_ACCOUNT_STUDENT: "/confirm",
    CONFIRM_ACCOUNT_ALLY: "/ally/confirm",
    TERMS_OF_USE: "/legal/terms",
    PRIVACY_POLICY: "/legal/privacy",
    SIGN_IN: "/signin",
    BILLING: "/billing",
    BILLING_DETAILS: "/billing/details",
    BILLING_DETAILS_CHARGES: "/billing/details/charges",
    PAYMENT_AMOUNT: "/payment/amount",
    PAYMENT_PLAN_DETAILS: "/payment-plan/details",
    PAYMENT_PLAN_LEGAL: "/payment-plan/legal",
    PAYMENT_METHOD: "/payment/method",
    PAYMENT_BANK: "/payment/bank",
    PAYMENT_CARD: "/payment/card",
    PAYMENT_SUMMARY: "/payment/summary",
    PAYMENT_SUCCESS: "/payment/success",
    ACCOUNT: "/account",
    ACCT_ACCESS_MANAGE: "/access/manage",
    ACCT_ACCESS_GRANT: "/access/grant",
    ACCT_ACCESS_GRANTED_SUCCESS: "/access/grant/success",
    SELF_SERVICE_SIGNUP: "/signup",
    SELF_SERVICE_SIGNUP_SUCCESS: "/signup/success",
    NETWORK_ERROR: "/network-error",
} as const;

export const paymentPlanFeeTooltipText =
    "In order to activate a payment plan a non-refundable, one time start fee is required. ";

import BaseBanner from "./Base";
import { RED_200, RED_700 } from "../../../theme/colors";
import { OverdueInfoIcon } from "../../icons/OverdueInfoIcon";
import { determineOrdinal } from "../utilities";
import { StudentPaymentPlan } from "../Student.model";

export default function PaymentPlanDueBanner({
    applicablePaymentPlan,
}: {
    applicablePaymentPlan: StudentPaymentPlan;
}) {
    return (
        <BaseBanner
            color={RED_700}
            backgroundColor={RED_200}
            message={`Your ${determineOrdinal(
                applicablePaymentPlan.installmentsPaid + 1
            )} payment is due today`}
            Icon={OverdueInfoIcon}
        />
    );
}

import * as Sentry from "@sentry/react";
import FormHeader from "../form/FormHeader";
import { FormEvent, useState, FC, useContext, useEffect } from "react";
import { Button, FormGroup, Typography } from "@mui/material";
import MeadowInput from "../form/MeadowInput";
import MeadowContainer from "../container";
import PoweredByMeadow from "../footer/PoweredByMeadow";
import { AuthService } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

import { parseError } from "../../services/utilities";
import { ContactUs } from "./ContactUs";
import { ErrorTypes } from "../../enums/errors";
import { UserContext } from "../../context/UserContext";

const SelfServiceSendInvite: FC = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [studentId, setStudentId] = useState<string>("");
    const [authService] = useState<AuthService>(new AuthService());
    const [error, setError] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            navigate("/billing");
        }
    }, []);

    const handleSubmit = async (e: FormEvent) => {
        setError(undefined);
        e.preventDefault();
        setIsLoading(true);
        try {
            if (studentId.length > 0) {
                await authService.selfServiceSendInvite(studentId);
                setIsLoading(false);
                navigate("/signup/success");
            } else {
                setIsLoading(false);
                setError("Please enter your Student ID.");
            }
        } catch (error) {
            const errorMessage = parseError(error);
            // reroute if this account is already claimed
            if (errorMessage === "Student account already claimed.") {
                navigate("/signin");
            }
            // if API returns no error message or generic API error message, render frontend generic message.
            // otherwise, flatten to error from Figma spec.
            setError(
                !errorMessage || errorMessage.startsWith("Something unexpected")
                    ? ErrorTypes.GENERIC
                    : ErrorTypes.INCORRECT_STUDENT_ID
            );
            setIsLoading(false);
            Sentry.captureException(error);
        }
    };

    return (
        <MeadowContainer>
            <FormHeader
                headerText={"View, share and pay your school bills"}
                subText={"Please enter your Student ID to get access to your account."}
            />
            <FormGroup>
                <form>
                    <MeadowInput
                        label="Student ID"
                        value={studentId}
                        onChange={(e) => setStudentId(e.target.value)}
                        errorType={error}
                        isError={!!error}
                        inputMode="text"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    />
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={isLoading}
                        sx={{
                            marginTop: "1.75rem",
                        }}
                        onClick={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        {"Submit"}
                    </Button>
                </form>
            </FormGroup>
            <Typography
                variant="body1"
                sx={{
                    paddingTop: "1rem",
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        fontWeight: 500,
                    }}
                    component="span"
                >
                    For parents, guardians, and non-students:
                </Typography>{" "}
                Your student must first sign up and invite you before you can access your
                student&apos;s account.
            </Typography>
            <ContactUs />
            <PoweredByMeadow />
        </MeadowContainer>
    );
};

export default SelfServiceSendInvite;

import * as Sentry from "@sentry/react";
import { Box, Divider, Typography } from "@mui/material";
import PoweredByMeadow from "../footer/PoweredByMeadow";
import { useContext, useEffect, useState } from "react";
import validator from "validator";
import { BLUE_700 } from "../../theme/colors";
import LoadingDialog from "../modal/LoadingDialog";
import { ErrorTypes } from "../../enums/errors";
import MeadowContainer from "../container";
import { AuthService } from "../../services/AuthService";
import maskEmail from "../utils/maskEmail";
import { UserContext } from "../../context/UserContext";
import { parseError } from "../../services/utilities";
import { Link, useNavigate } from "react-router-dom";
import { setUserData } from "../utils/setUserData";
import { Logging } from "../../services/Logging";
import { ContactUs } from "./ContactUs";
import { ROUTER_PATHS } from "../../constants";
import { ConfigContext } from "../../context/ConfigContext";

import { SignInOTP } from "./SignInOTP";
import { SignInSSO } from "./SignInSSO";
import { SignInEmail } from "./SignInEmail";
import useBreakpoint from "../../hooks/useBreakpoint";
import {
    typographySX,
    dividerSX,
    flexEndContainer,
    containerPadding,
    footer,
    dividerWrapperMobileSx,
    dividerWrapperDesktopSx,
    containerSX,
} from "./styles";

export default function ConfirmAccount() {
    const { isMobile } = useBreakpoint();
    const [emailValue, setEmailValue] = useState<string>("");
    const [maskedEmail, setMaskedEmail] = useState<string>("");
    const [sendChallengeError, setSendChallengeError] = useState<ErrorTypes | string>(
        ErrorTypes.NULL
    );
    const [verifyChallengeError, setVerifyChallengeError] = useState<ErrorTypes | string>(
        ErrorTypes.NULL
    );
    const [hasSsoError, setHasSsoError] = useState<boolean>(false);
    const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [permalink, setPermalink] = useState<string>("");
    const [pageContent, setPageContent] = useState<JSX.Element>();
    const [authService] = useState(() => new AuthService());
    const { config, isLoading: isLoadingConfig } = useContext(ConfigContext);
    const { setUser } = useContext(UserContext);

    const navigate = useNavigate();

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const permalinkValue = params.get("permalink");
        if (permalinkValue) setPermalink(permalinkValue);

        if (isCodeSent) {
            setPageContent(
                <SignInOTP
                    handleSubmit={handleRegisterVerifyChallenge}
                    error={verifyChallengeError}
                    maskedEmail={maskedEmail}
                    onBackClick={onCodeBackClick}
                    onResend={onResend}
                />
            );
        } else if (config?.isSsoEnabled) {
            setPageContent(
                <SignInSSO
                    handleSubmit={handleRegisterSendChallenge}
                    onSsoClick={onSsoClick}
                    error={sendChallengeError}
                    hasSsoError={hasSsoError}
                    isLoading={isLoading}
                    clearError={clearSsoError}
                />
            );
        } else {
            setPageContent(
                <SignInEmail
                    handleSubmit={handleRegisterSendChallenge}
                    error={sendChallengeError}
                />
            );
        }
    }, [isCodeSent, config, sendChallengeError, maskedEmail, hasSsoError, permalink]);

    const onCodeBackClick = () => {
        setSendChallengeError(ErrorTypes.NULL);
        setVerifyChallengeError(ErrorTypes.NULL);
        setIsCodeSent(false);
        setEmailValue("");
    };

    const onSsoClick = async () => {
        if (config?.id) {
            try {
                setIsLoading(true);
                const result = await authService.getSsoRequestUrl(config.id);
                window.location.href = result.ssoUrl;
            } catch (err) {
                setHasSsoError(true);
                Sentry.captureException(err);
            }
        } else {
            setHasSsoError(true);
        }

        setIsLoading(false);
    };

    const clearSsoError = () => {
        setHasSsoError(false);
    };

    const onResend = async () => {
        await handleRegisterSendChallenge(emailValue);
    };

    const handleRegisterSendChallenge = async (email: string) => {
        setSendChallengeError(ErrorTypes.NULL);

        if (email.length === 0) setSendChallengeError(ErrorTypes.EMPTY);
        if (email.length > 0) {
            const emailToMask = maskEmail(email);
            setMaskedEmail(emailToMask);

            const isEmail = validator.isEmail(email);

            if (!isEmail) {
                setSendChallengeError(ErrorTypes.INVALID);
            } else {
                setSendChallengeError(ErrorTypes.NULL);
            }

            try {
                if (isEmail) {
                    setEmailValue(email);
                    setIsLoading(true);
                    await authService.otpRegisterSendChallenge(email, permalink);
                    setIsCodeSent(true);
                    setIsLoading(false);
                }
            } catch (error) {
                setSendChallengeError(parseError(error));
                setIsLoading(false);
                Sentry.captureException(error);
            }
        }
    };

    const handleRegisterVerifyChallenge = async (code: string) => {
        setIsLoading(true);
        try {
            await authService.otpRegisterVerifyChallenge(emailValue, permalink, code);
            const userData = await setUserData();
            setUser(userData);
            Logging.signup();
            setIsLoading(false);
            navigate(ROUTER_PATHS.BILLING);
        } catch (error) {
            setVerifyChallengeError(parseError(error));
            Sentry.captureException(error);
            setIsLoading(false);
        }
    };

    return (
        <MeadowContainer noPadding sx={containerSX(isMobile)}>
            {!isLoadingConfig ? (
                <>
                    <div style={flexEndContainer}>
                        <div style={containerPadding(isMobile)}>{pageContent}</div>
                        {!isCodeSent && (
                            <>
                                <Box
                                    sx={isMobile ? dividerWrapperMobileSx : dividerWrapperDesktopSx}
                                >
                                    <Divider sx={dividerSX(isMobile)} />
                                </Box>
                                <Typography sx={typographySX(isMobile)} variant="body1">
                                    By using Meadow Pay, you are agreeing to our{" "}
                                    <Link
                                        style={{ textDecoration: "none", color: BLUE_700 }}
                                        to="/legal/terms"
                                    >
                                        terms of use
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        style={{ textDecoration: "none", color: BLUE_700 }}
                                        to="/legal/privacy"
                                    >
                                        privacy policy.
                                    </Link>
                                </Typography>
                            </>
                        )}
                        <div style={footer(isMobile)}>
                            <ContactUs />
                            <PoweredByMeadow />
                        </div>
                    </div>
                    {isLoading && <LoadingDialog open={isLoading} />}
                </>
            ) : (
                <></>
            )}
        </MeadowContainer>
    );
}

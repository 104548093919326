import { Card, Box, Typography } from "@mui/material";
import { GREY_100 } from "../../../theme/colors";
import { MeadowTooltip } from "../../icons/MeadowTooltip";
import { BillingCategory, BillingSubcategory } from "../Billing.model";
import { formatter } from "../utilities";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "../../../hooks/useBreakpoint";
import {
    categoryTitleSx,
    detailsTitleWrapperSx,
    subcategorySx,
    subcategoryWrapperSx,
} from "./styles";
import rightArrow from "../../../assets/right_arrow.svg";
import { Subcategory } from "./Subcategory";

interface CategoryProps {
    category: BillingCategory;
}
export const Category = ({ category }: CategoryProps) => {
    const navigate = useNavigate();
    const { isMobile } = useBreakpoint();
    const cardSx = {
        marginBottom: isMobile ? "1rem" : "1.5rem",
        width: isMobile ? "100%" : "50.5rem",
        boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        borderRadius: "0.5rem",
    };
    const handleSubcategories = (subcategories: BillingSubcategory[]) => {
        if (subcategories.length === 1 && subcategories[0].title === "Other") {
            // create a copy so order of original charges array is not affected.
            return [...subcategories[0].charges]
                .sort((charge1, charge2) => {
                    const titleSort = charge1.title.localeCompare(charge2.title);
                    if (titleSort === 0) {
                        return charge1.date.localeCompare(charge2.date);
                    } else {
                        return titleSort;
                    }
                })
                .map((charge, index) => {
                    return (
                        <Box
                            key={index}
                            sx={subcategoryWrapperSx}
                            onClick={() => {
                                navigate("/billing/details/charges", {
                                    state: { category },
                                });
                            }}
                        >
                            <Typography sx={subcategorySx}>{charge.title}</Typography>
                            <Typography sx={{ ...subcategorySx, whiteSpace: "nowrap" }}>
                                {formatter.format(charge.amount)}
                            </Typography>
                        </Box>
                    );
                });
        } else {
            return subcategories.map((subcategory, index) => {
                return <Subcategory key={index} category={category} subcategory={subcategory} />;
            });
        }
    };
    return (
        <Card sx={cardSx}>
            <Box
                sx={{
                    padding: 0,
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
            >
                <Box
                    onClick={() => {
                        navigate("/billing/details/charges", {
                            state: { category },
                        });
                    }}
                    sx={detailsTitleWrapperSx}
                >
                    <Box sx={{ display: "flex" }}>
                        <Typography sx={categoryTitleSx}>{category.title}</Typography>
                        {category.description && (
                            <div onClick={(e) => e.stopPropagation()}>
                                <MeadowTooltip
                                    title={category.description}
                                    sx={{
                                        marginLeft: "1rem",
                                    }}
                                >
                                    <></>
                                </MeadowTooltip>
                            </div>
                        )}
                    </Box>

                    <img src={rightArrow} />
                </Box>
            </Box>
            {handleSubcategories(category.subcategories)}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderTop: `1px solid ${GREY_100}`,
                    padding: "0.75rem 1rem",
                }}
            >
                <Typography sx={categoryTitleSx}>Total</Typography>
                <Typography sx={categoryTitleSx}>{formatter.format(category.total)}</Typography>
            </Box>
        </Card>
    );
};

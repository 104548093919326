import { Dialog, DialogTitle, Typography } from "@mui/material";
import LoadingIcon from "../utils/LoadingIcon";

interface LoadingModalProps {
    open: boolean;
}

export default function LoadingDialog({ open }: LoadingModalProps) {
    return (
        <Dialog
            PaperProps={{
                sx: {
                    padding: "2rem",
                    width: "20.5rem",
                    borderRadius: "0.5rem",
                    boxShadow:
                        "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
                },
            }}
            sx={{ textAlign: "center" }}
            open={open}
        >
            <DialogTitle sx={{ padding: 0 }}>
                <LoadingIcon />
            </DialogTitle>
            <Typography variant="h1">We are processing your information</Typography>
            <Typography variant="body1">This may take a few seconds.</Typography>
        </Dialog>
    );
}

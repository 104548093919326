import { Alert, Box } from "@mui/material";
import { GREY_50, GREY_500 } from "../../theme/colors";
import { ContactUsIcon } from "../icons/ContactUsIcon";
import { ContactLink } from "./ContactLink";

export const ContactUs = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "end",
                flex: "10",
                justifyContent: "center",
            }}
        >
            <Alert
                severity="info"
                sx={{
                    backgroundColor: GREY_50,
                    color: GREY_500,
                    fontWeight: 400,
                    fontSize: "0.875rem",
                    borderRadius: "0.5rem",
                    padding: "1rem",
                    textAlign: "left",
                    "& .MuiAlert-icon": {
                        marginRight: "4px",
                        padding: 0,
                    },
                    "& .MuiAlert-message": {
                        // we need a small amount of padding to account for the underline of the hyperlink
                        paddingBottom: "2px",
                        paddingTop: 0,
                    },
                }}
                icon={<ContactUsIcon />}
            >
                If you have any trouble accessing your account, please{" "}
                <ContactLink textColor={GREY_500} />
            </Alert>
        </Box>
    );
};

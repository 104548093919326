import { Box, Card, Typography } from "@mui/material";
import { BillingCategory } from "../Billing.model";
import { BLACK_000, GREY_100, GREY_800 } from "../../../theme/colors";
import { formatter } from "../utilities";
import { useLocation, useNavigate } from "react-router-dom";
import MeadowContainer from "../../container";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { FormattedCharges } from "./FormattedCharges";
import { useEffect } from "react";

interface BillingChargeBreakdownProps {
    category: BillingCategory;
}
export const BillingChargeBreakdown = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        // If the state is not present, navigate to the billing page
        if (!state) {
            navigate("/billing");
            return;
        }
    }, [state, navigate]);

    if (!state) {
        // This check ensures that the component does not try to access properties of an undefined state
        return null;
    }
    const { category } = state as BillingChargeBreakdownProps;

    const titleWrapperSx = {
        borderBottom: `1px solid ${GREY_100}`,
        padding: "0.75rem 1rem",
        display: "flex",
        justifyContent: "space-between",
        "&:last-child": {
            borderBottom: "none",
        },
    };
    const totalWrapperSx = {
        ...titleWrapperSx,
        borderBottom: 0,
    };

    const chargeTitleSx = {
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        color: BLACK_000,
    };
    const wrapperSx = {
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile ? "unset" : "center",
    };
    const simpleHeaderSx = {
        width: "50.5rem",
        margin: "0 auto",
    };

    return (
        <MeadowContainer
            fullWidth
            showSimpleHeader={true}
            simpleHeaderText={`${category.title}`}
            simpleHeaderOverrideStyles={simpleHeaderSx}
            sx={{
                backgroundColor: GREY_800,
                height: "100%",
                padding: "1rem",
                paddingTop: "0",
            }}
        >
            <Box sx={wrapperSx}>
                <Box
                    sx={{
                        width: isMobile ? "auto" : "50.5rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: isMobile ? "1rem" : "1.5rem",
                    }}
                >
                    {category.subcategories.map((subcategory, index) => {
                        return (
                            <Card
                                sx={{
                                    boxShadow:
                                        "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
                                }}
                                key={index}
                            >
                                <Box sx={titleWrapperSx}>
                                    <Typography sx={chargeTitleSx}>{subcategory.title}</Typography>
                                </Box>
                                <FormattedCharges subcategory={subcategory} key={index} />
                                <Box sx={totalWrapperSx}>
                                    <Typography sx={chargeTitleSx}>Total</Typography>
                                    <Typography sx={chargeTitleSx}>
                                        {formatter.format(subcategory.total)}
                                    </Typography>
                                </Box>
                            </Card>
                        );
                    })}
                    {category.subcategories.length > 1 && (
                        <Card
                            sx={{
                                boxShadow:
                                    "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
                            }}
                        >
                            <Box sx={titleWrapperSx}>
                                <Typography sx={chargeTitleSx}>
                                    Total {category.title.toLowerCase()}
                                </Typography>
                                <Typography sx={{ ...chargeTitleSx, whiteSpace: "nowrap" }}>
                                    {formatter.format(category.total)}
                                </Typography>
                            </Box>
                        </Card>
                    )}
                </Box>
            </Box>
        </MeadowContainer>
    );
};

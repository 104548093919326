import { Box, Button, Typography } from "@mui/material";
import { BLACK_000 } from "../../theme/colors";
import useBreakpoint from "../../hooks/useBreakpoint";
import grantAccountAccess from "../../assets/grant_account_access.svg";
import manageAccountAccess from "../../assets/manage_account_access.svg";
import { PaymentsStudent } from "../../reducers/PaymentsReducer";
import { Link } from "react-router-dom";
import sendMail from "../../assets/send_mail.svg";
interface AccessControlsProps {
    student: PaymentsStudent;
}
export const AccessControls = ({ student }: AccessControlsProps) => {
    const { isMobile } = useBreakpoint();

    return (
        <Box>
            {student.accountAccessList.length > 0 ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.75rem",
                    }}
                >
                    <Box sx={{ display: "flex", gap: "0.75rem" }}>
                        <img src={sendMail} alt="" />
                        <Typography
                            sx={{
                                color: BLACK_000,
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                lineHeight: "1.25rem",
                            }}
                        >
                            Someone else paying?
                        </Typography>
                    </Box>
                    <Link
                        style={{ textDecoration: "none" }}
                        to={"/access/manage"}
                        state={{ data: student }}
                    >
                        <Button
                            sx={{ maxWidth: isMobile ? "auto" : "16.25rem" }}
                            variant="secondary"
                        >
                            <img
                                style={{ marginRight: "0.5rem" }}
                                src={manageAccountAccess}
                                alt="Manage account access"
                            />
                            Manage account access
                        </Button>
                    </Link>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.75rem",
                    }}
                >
                    <Box sx={{ display: "flex", gap: "0.75rem" }}>
                        <img src={sendMail} alt="" />
                        <Typography
                            sx={{
                                color: BLACK_000,
                                fontSize: "0.875rem",
                                fontWeight: 500,
                                lineHeight: "1.25rem",
                            }}
                        >
                            Someone else paying?
                        </Typography>
                    </Box>
                    <Link
                        style={{ textDecoration: "none" }}
                        to={"/access/grant"}
                        state={{ data: student }}
                    >
                        <Button
                            sx={{ maxWidth: isMobile ? "auto" : "16.25rem" }}
                            variant="secondary"
                        >
                            <img
                                style={{ marginRight: "0.5rem" }}
                                src={grantAccountAccess}
                                alt="Grant account access"
                            />
                            Grant account access
                        </Button>
                    </Link>
                </Box>
            )}
        </Box>
    );
};

import { Box, SxProps, TextField } from "@mui/material";
import { ErrorTypes } from "../../enums/errors";

interface MeadowInputProps {
    label: string;
    sx?: SxProps;
    onChange: (e: any) => void;
    value: string;
    errorType?: ErrorTypes | string;
    isError?: boolean;
    inputMode?: React.HTMLAttributes<HTMLLIElement>["inputMode"];
    autoCapitalize?: string;
    autoComplete?: string;
    placeholder?: string;
    autoFocus?: boolean;
    onBlur?: () => void;
}
export default function MeadowInput({
    label,
    sx = {},
    onChange,
    value,
    errorType,
    isError = false,
    inputMode = "text",
    autoCapitalize = "none",
    autoComplete = "off",
    placeholder = "",
    autoFocus = true,
    onBlur = () => {},
}: MeadowInputProps) {
    const labelStyle = { fontWeight: 500, fontSize: "1rem", lineHeight: "1.5rem" };
    return (
        <Box sx={{ ...sx }}>
            <label style={labelStyle}>{label}</label>
            <TextField
                autoFocus={autoFocus}
                variant="standard"
                sx={{ padding: 0 }}
                onChange={onChange}
                onBlur={onBlur}
                error={isError}
                helperText={errorType}
                placeholder={placeholder}
                value={value}
                inputProps={{
                    inputMode,
                    autoCapitalize,
                    autoComplete,
                }}
                InputProps={{
                    disableUnderline: true,
                }}
            />
        </Box>
    );
}

import { createContext, useEffect, useState } from "react";
import { parseJwt, sessionStorageGet } from "../services/utilities";
import { AuthSession } from "../models/AuthSession.model";
import { Logging } from "../services/Logging";
import * as Sentry from "@sentry/react";

interface ContextProps {
    children: React.ReactNode;
}
interface UserContextType {
    user: AuthSession | null;
    setUser: (user: AuthSession | null, updatedFromUserAction?: boolean) => void;
}

export const UserContext = createContext<UserContextType>({
    user: null,
    setUser: () => {},
});

export default function UserContextProvider({ children }: ContextProps) {
    const [user, setUserState] = useState<AuthSession | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const setUser = (userData: AuthSession | null, triggeredByEffect = false) => {
        // set or clear logging depending on auth state.
        // since auth state persists on the browser we could misattribute tracks
        // if we only relied on user actions to update logging auth state.
        if (userData) {
            Sentry.addBreadcrumb({
                category: "lifecycle",
                message: `User has logged in.
                userId: ${userData.userId}
                isAdmin: ${userData.isAdmin}
                studentIds: ${userData.students.map((student) => student.id).join(", ")}`,
                level: "log",
            });
            Logging.identify(userData.userId, !triggeredByEffect);
        } else {
            Logging.reset();
        }
        setUserState(userData);
    };

    useEffect(() => {
        const getToken = async () => {
            const encodedToken = sessionStorageGet("authToken");
            if (typeof encodedToken === "string") {
                const userData = parseJwt(encodedToken) as AuthSession;
                setUser(userData, true);
            }
        };
        getToken().finally(() => {
            setIsLoading(false);
        });
    }, []);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
            }}
        >
            {!isLoading && children}
        </UserContext.Provider>
    );
}

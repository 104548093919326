import { useEffect, useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { ConfigService } from "../../services/ConfigService";
import { ConfigContext } from "../../context/ConfigContext";

const getConfig = () => {
    const hostname = window.location.hostname;
    const { config, setConfig, setIsLoading } = useContext(ConfigContext);
    const [configService] = useState(new ConfigService());

    useEffect(() => {
        if (!config) {
            const getConfig = async () => {
                setIsLoading(true);
                const config = await configService.getConfig(hostname);

                setConfig(config);
                setIsLoading(false);
            };
            // if not on the network-error page, get the config
            if (!window.location.pathname.includes("/network-error")) {
                getConfig();
            }
        }
    }, []);

    return <Outlet />;
};

export default getConfig;

import { SvgIcon, SvgIconProps } from "@mui/material";

export const CalendarIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16">
            <g>
                <path
                    d="M13,16H3c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2v10C15,15.1,14.1,16,13,16z M3,3.6C2.8,3.6,2.6,3.8,2.6,4
		v10c0,0.2,0.2,0.4,0.4,0.4h10c0.2,0,0.4-0.2,0.4-0.4V4c0-0.2-0.2-0.4-0.4-0.4H3z"
                />
            </g>
            <g>
                <rect x="2" y="5.4" width="12" height="1.6" />
            </g>
            <g>
                <path d="M4.8,3C4.4,3,4,2.6,4,2.2V0.8C4,0.4,4.4,0,4.8,0s0.8,0.4,0.8,0.8v1.4C5.6,2.6,5.2,3,4.8,3z" />
            </g>
            <g>
                <path d="M11.2,3c-0.4,0-0.8-0.4-0.8-0.8V0.8c0-0.4,0.4-0.8,0.8-0.8S12,0.4,12,0.8v1.4C12,2.6,11.6,3,11.2,3z" />
            </g>
        </svg>
    </SvgIcon>
);

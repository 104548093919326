import { useContext, useEffect } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import MeadowContainer from "../../container";
import { BackIcon } from "../../icons/BackIcon";
import { ConfigContext } from "../../../context/ConfigContext";
import useBreakpoint from "../../../hooks/useBreakpoint";

export const PaymentPlanLegal = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile } = useBreakpoint();
    const { config } = useContext(ConfigContext);

    useEffect(() => {
        const scrollToElement = (id: string) => {
            // Delay scrolling to ensure the DOM is fully loaded and the element exists
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 0);
        };

        if (window.location.hash) {
            const id = window.location.hash.replace("#", "");
            scrollToElement(id);
        }
    }, [location]);

    const gapSx = {
        marginBottom: "1rem",
        "&:last-child": {
            marginBottom: 0,
        },
    };
    const boldSx = {
        fontWeight: 700,
    };

    const legalTextWithAutopay = () => {
        return (
            <Box>
                <Box>
                    <BackIcon data-testid="back-icon-mobile" onClick={() => navigate(-1)} />
                    <Typography variant="h1">Meadow Payment Plan Agreement</Typography>
                </Box>
                <Box>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>TERMS AND CONDITIONS:</span> Meadow Technologies, Inc.
                        (Meadow), New York, NY, has contracted with {config?.schoolName}{" "}
                        (Institution) to process payments for tuition and/or fees. This Meadow
                        Agreement (Agreement) is independent of any tuition agreement you may have
                        with the Institution. The status of this Agreement in no way affects your
                        obligation to pay the Institution. As the person who submitted this
                        Agreement, you are the Plan Owner. You accept and agree to be bound by the
                        Agreement&apos;s terms and conditions until the total amount owed is paid in
                        full.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>AUTHORIZATION:</span> You authorize Meadow to process
                        payments from the account provided or any subsequent account and acknowledge
                        the account provided belongs to you. Your authorization will terminate when
                        the total balance due has been paid (including fees, unless waived).
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>NON-ELECTRONIC COPIES:</span> You have the right to
                        receive a paper copy of the record for which you provided an electronic
                        signature. If you wish to receive a paper copy of the record, please send an
                        email to your institution administrator or call your institution
                        administrator. There are no fees for a copy of the requested record.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>ENROLLMENT FEE:</span> The non-refundable enrollment
                        fee will be automatically processed from the account provided immediately.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        The enrollment fee is based upon the number of payments selected for each
                        Agreement period. If the entire balance is not paid within twelve (12)
                        months, Meadow may assess a new enrollment fee and continue to do so on an
                        annual basis until the balance is paid in full. Fees are subject to change.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>IMMEDIATE PAYMENTS:</span> This plan contains an
                        immediate payment which may include your down payment, full payment, and/or
                        fees. Meadow will process the payment from the financial account you have
                        designated. If the payment fails, Meadow will notify you that your agreement
                        has been terminated; you will need to re-enroll with Meadow or make payment
                        arrangements directly with your Institution.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>RETURNED PAYMENT FEE:</span> If a payment is returned,
                        you will be notified and a Meadow Returned Payment Fee will be automatically
                        processed from the account provided. If the returned payment fee is
                        returned, it will be rescheduled. Fees are subject to change.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>PAYMENT DATES:</span> If the payment date falls on a
                        weekend or banking holiday observed by the Federal Reserve, the payment will
                        be attempted on the following business day. Although Meadow specifies the
                        date each payment will occur, your financial institution determines the time
                        of day the payment is debited to the account.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>CHANGES TO AGREEMENTS:</span>
                        <br />
                        <br />
                        A. You may make changes to the information provided in this Agreement by
                        contacting the Institution.
                        <br />
                        <br /> The timely application of changes depends on when they are received
                        by Meadow; Meadow may refuse to apply changes prior to the next scheduled
                        payment date if Meadow determines, for whatever reason, that it does not
                        have sufficient time to act on them.
                        <br />
                        <br /> In the event you authorize additional services from the Institution,
                        or in the event additional fees are assessed by the Institution in
                        accordance with its policies and as a result of changes authorized by you,
                        you understand that the total balance due and/or payment amount will change.
                        You agree that your authorization of any such change shall constitute your
                        authorization to change the payment amount, and/or to continue payments
                        until the total balance due is paid in full. If you, as the Plan Owner, are
                        not the recipient of services, you authorize the recipient of services to
                        make changes to his or her schedule or activities and agree to be bound by
                        any such changes. You do not require Meadow or the Institution to send
                        advance notice of adjustments resulting from such changed authorization,
                        which includes any reduction in the balance due and/or payment as a result
                        of financial aid, or any other similar cause. However, a copy of any such
                        changed authorization, as described above, is to be provided to you by the
                        Institution.
                        <br />
                        <br />
                        B. If there will be any change in the pre-authorized payment amount other
                        than a changed authorization, as described above, the Institution will give
                        you notice of such changed payment amount at least ten (10) days in advance
                        of the next scheduled payment.
                        <br />
                        <br />
                        C. You may revoke your authorization by sending Meadow a signed, written
                        notification or email. Please note that terminating your Agreement with
                        Meadow in no way affects your obligation to pay the Institution. Your
                        Institution may demand immediate payment of all outstanding balances. You
                        are strongly encouraged to contact your Institution before requesting to
                        terminate your Agreement.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>CUSTODIAL ACCOUNT:</span> Meadow does not guarantee
                        payments it does not collect from you. Collected funds shall be held by
                        Meadow as your agent until remitted to the Institution. Depending upon the
                        Institution&apos;s policy, payments returned by your financial institution
                        may be rescheduled. Refunds of any money paid to Meadow, except for any
                        applicable Meadow fees, will be handled by the Institution according to its
                        refund policy. Interest earned on custodial funds is paid to Meadow.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>CONFIRMATION:</span> Any and all inconsistencies in the
                        information provided will be resolved in the confirmation notification sent
                        to you from Meadow.
                        <br />
                        <br /> Changes made by the Institution that are received by Meadow before
                        the notification is sent may also be included. In either event, the
                        confirmation notification shall be controlling. A portion of your enrollment
                        fee or late fee, if applicable, may be retained by or used to support the
                        institution(s) administering your payment plan.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>DISCOVERY OF SUSPECTED ERRORS:</span> If you discover
                        what you believe to be an error made by Meadow, you must report the
                        suspected error to the company immediately. Meadow must hear from you no
                        later than sixty (60) days after the suspected error occurred. This
                        obligates you to timely review of your bank statements and a timely response
                        to company letters, emails, or phone calls. It is your responsibility to
                        report suspected errors as soon as possible.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>TELEPHONE CONTACT CONSENT:</span> You certify that you
                        are the subscriber to the provided wireless number. You authorize Meadow and
                        its representatives to contact you regarding your account at any current and
                        future numbers that you provide for your cellular telephone or other
                        wireless device using automatic dialing systems, artificial or prerecorded
                        messages, and/or SMS text messages. Standard message and data rates may be
                        charged by your service provider(s).
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>GOVERNING LAW:</span> You acknowledge that the
                        origination of ACH transactions to your account must comply with the
                        provisions of U.S. law. This Agreement shall be governed by the laws of the
                        State of New York. This Agreement should in no way be construed to be a
                        lender-borrower agreement between Meadow and the Institution or Meadow and
                        you.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>ARBITRATION:</span> Any controversy or claim between
                        the parties to this Agreement, its interpretation, enforcement or breach,
                        including but not limited to claims arising from tort (which includes claims
                        of fraud and fraud in the inducement), shall be settled by binding
                        arbitration administered by and under the rules of Commercial Dispute
                        Resolution Procedures of the American Arbitration Association
                        (&quot;AAA&quot;), as modified by this Agreement, and will be administered
                        by the AAA. While either party shall have all the rights and benefits of
                        arbitration, both parties are giving up the right to litigate such claims
                        and disputes in a court or jury trial. The results, determinations,
                        findings, judgments and/or awards rendered through such arbitration shall be
                        final and binding on the parties hereto and may be specifically enforced by
                        legal proceedings. Judgment on the award may be entered into any court
                        having jurisdiction. Neither party shall be entitled to join or consolidate
                        disputes by or against others in any arbitration, or to include in any
                        arbitration any dispute as a representative or member of a class or as part
                        of a class action, or to act in any arbitration in the interest of the
                        general public or in any private attorney general capacity. A demand for
                        arbitration shall not be made after the date when the institution of legal
                        or equitable proceedings based on the claim would be barred by the
                        applicable statute of limitations. For statute of limitations purposes,
                        receipt of a written demand for arbitration shall constitute the institution
                        of legal or equitable proceedings based on the claim. All proceedings in
                        arbitration shall be administered in New York County, New York. This
                        provision shall survive termination of the Agreement.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>PRIVACY AND SECURITY:</span> Data collected and stored
                        by Meadow pursuant to this Agreement is governed by the Institution&apos;s
                        privacy policy in addition to the Meadow privacy policy. Access to the data
                        shall be restricted to authorized associates of Meadow or its affiliates and
                        shall be used for the purposes of providing, marketing, and improving
                        services to you or the Institution. Meadow maintains physical, procedural,
                        and electronic safeguards to protect data from being accessed by
                        unauthorized third parties. You agree and acknowledge, in accordance with
                        the Meadow privacy policy that Meadow or its affiliates may market services
                        to You. You will have the opportunity to tell us you do not want to receive
                        future messages with each message you receive.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>MILITARY LENDING ACT:</span> Notice to Covered Members
                        under the Military Lending Act (10 U.S.C. Section 987 (i)(1)): Federal law
                        provides important protections to members of the Armed Forces and their
                        dependents relating to this agreement. In general, the cost of a payment
                        plan to a member of the Armed Forces and his or her dependent may not exceed
                        an annual percentage rate of 36 percent. This rate must include, as
                        applicable to your account: The costs associated with payment plan insurance
                        premiums, fees for ancillary products sold in connection with the payment
                        plan; any application of charged (other than certain application fees for
                        specified payment plans or accounts); and any participation fee charged.
                        Federal law also exempts you from the Arbitration section of this Agreement.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>SPECIAL NOTE REGARDING FINANCIAL AID:</span> Please do
                        not assume your balance will automatically be adjusted if you receive
                        financial aid or a class or service is added or dropped. You should review
                        your Agreement balance online or contact your Institution.
                    </Typography>
                </Box>
            </Box>
        );
    };

    const legalTextNoAutopay = () => {
        return (
            <Box>
                <Box>
                    <BackIcon data-testid="back-icon-mobile" onClick={() => navigate(-1)} />
                    <Typography variant="h1">Meadow Payment Plan Agreement</Typography>
                </Box>
                <Box>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>TERMS AND CONDITIONS:</span> Meadow Technologies, Inc.
                        (Meadow), New York, NY, has contracted with {config?.schoolName}{" "}
                        (Institution) to process payments for tuition and/or fees. This Meadow
                        Agreement (Agreement) is independent of any tuition agreement you may have
                        with the Institution. The status of this Agreement in no way affects your
                        obligation to pay the Institution. As the person who submitted this
                        Agreement, you are the Plan Owner. You accept and agree to be bound by the
                        Agreement&apos;s terms and conditions until the total amount owed is paid in
                        full.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>AUTHORIZATION:</span> You authorize Meadow to process
                        payments from the account provided or any subsequent account and acknowledge
                        the account provided belongs to you. Your authorization will terminate when
                        the total balance due has been paid (including fees, unless waived).
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>NON-ELECTRONIC COPIES:</span> You have the right to
                        receive a paper copy of the record for which you provided an electronic
                        signature. If you wish to receive a paper copy of the record, please send an
                        email to your institution administrator or call your institution
                        administrator. There are no fees for a copy of the requested record.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>ENROLLMENT FEE:</span> The non-refundable enrollment
                        fee will be automatically processed from the account provided immediately.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        The enrollment fee is based upon the number of payments selected for each
                        Agreement period. If the entire balance is not paid within twelve (12)
                        months, Meadow may assess a new enrollment fee and continue to do so on an
                        annual basis until the balance is paid in full. Fees are subject to change.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>IMMEDIATE PAYMENTS:</span> This plan contains an
                        immediate payment which may include your down payment, full payment, and/or
                        fees. Meadow will process the payment from the financial account you have
                        designated. If the payment fails, Meadow will notify you that your agreement
                        has been terminated; you will need to re-enroll with Meadow or make payment
                        arrangements directly with your Institution.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>RETURNED PAYMENT FEE:</span> If a payment is returned,
                        you will be notified and a Meadow Returned Payment Fee will be automatically
                        processed from the account provided. If the returned payment fee is
                        returned, it will be rescheduled. Fees are subject to change.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>PAYMENT DATES:</span> If the payment date falls on a
                        weekend or banking holiday observed by the Federal Reserve, the payment will
                        be attempted on the following business day. Although Meadow specifies the
                        date each payment will occur, your financial institution determines the time
                        of day the payment is debited to the account.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>CHANGES TO AGREEMENTS:</span>
                        <br />
                        <br />
                        A. You may make changes to the information provided in this Agreement by
                        contacting the Institution.
                        <br />
                        <br /> The timely application of changes depends on when they are received
                        by Meadow; Meadow may refuse to apply changes prior to the next scheduled
                        payment date if Meadow determines, for whatever reason, that it does not
                        have sufficient time to act on them.
                        <br />
                        <br /> In the event you authorize additional services from the Institution,
                        or in the event additional fees are assessed by the Institution in
                        accordance with its policies and as a result of changes authorized by you,
                        you understand that the total balance due and/or payment amount will change.
                        You agree that your authorization of any such change shall constitute your
                        authorization to change the payment amount, and/or to continue payments
                        until the total balance due is paid in full. If you, as the Plan Owner, are
                        not the recipient of services, you authorize the recipient of services to
                        make changes to his or her schedule or activities and agree to be bound by
                        any such changes. You do not require Meadow or the Institution to send
                        advance notice of adjustments resulting from such changed authorization,
                        which includes any reduction in the balance due and/or payment as a result
                        of financial aid, or any other similar cause. However, a copy of any such
                        changed authorization, as described above, is to be provided to you by the
                        Institution.
                        <br />
                        <br />
                        B. If there will be any change in the pre-authorized payment amount other
                        than a changed authorization, as described above, the Institution will give
                        you notice of such changed payment amount at least ten (10) days in advance
                        of the next scheduled payment.
                        <br />
                        <br />
                        C. You may revoke your authorization by sending Meadow a signed, written
                        notification or email. Please note that terminating your Agreement with
                        Meadow in no way affects your obligation to pay the Institution. Your
                        Institution may demand immediate payment of all outstanding balances. You
                        are strongly encouraged to contact your Institution before requesting to
                        terminate your Agreement.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>CUSTODIAL ACCOUNT:</span> Meadow does not guarantee
                        payments it does not collect from you. Collected funds shall be held by
                        Meadow as your agent until remitted to the Institution. Depending upon the
                        Institution&apos;s policy, payments returned by your financial institution
                        may be rescheduled. Refunds of any money paid to Meadow, except for any
                        applicable Meadow fees, will be handled by the Institution according to its
                        refund policy. Interest earned on custodial funds is paid to Meadow.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>CONFIRMATION:</span> Any and all inconsistencies in the
                        information provided will be resolved in the confirmation notification sent
                        to you from Meadow.
                        <br />
                        <br /> Changes made by the Institution that are received by Meadow before
                        the notification is sent may also be included. In either event, the
                        confirmation notification shall be controlling. A portion of your enrollment
                        fee or late fee, if applicable, may be retained by or used to support the
                        institution(s) administering your payment plan.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>DISCOVERY OF SUSPECTED ERRORS:</span> If you discover
                        what you believe to be an error made by Meadow, you must report the
                        suspected error to the company immediately. Meadow must hear from you no
                        later than sixty (60) days after the suspected error occurred. This
                        obligates you to timely review of your bank statements and a timely response
                        to company letters, emails, or phone calls. It is your responsibility to
                        report suspected errors as soon as possible.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>TELEPHONE CONTACT CONSENT:</span> You certify that you
                        are the subscriber to the provided wireless number. You authorize Meadow and
                        its representatives to contact you regarding your account at any current and
                        future numbers that you provide for your cellular telephone or other
                        wireless device using automatic dialing systems, artificial or prerecorded
                        messages, and/or SMS text messages. Standard message and data rates may be
                        charged by your service provider(s).
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>GOVERNING LAW:</span> You acknowledge that the
                        origination of ACH transactions to your account must comply with the
                        provisions of U.S. law. This Agreement shall be governed by the laws of the
                        State of New York. This Agreement should in no way be construed to be a
                        lender-borrower agreement between Meadow and the Institution or Meadow and
                        you.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>ARBITRATION:</span> Any controversy or claim between
                        the parties to this Agreement, its interpretation, enforcement or breach,
                        including but not limited to claims arising from tort (which includes claims
                        of fraud and fraud in the inducement), shall be settled by binding
                        arbitration administered by and under the rules of Commercial Dispute
                        Resolution Procedures of the American Arbitration Association
                        (&quot;AAA&quot;), as modified by this Agreement, and will be administered
                        by the AAA. While either party shall have all the rights and benefits of
                        arbitration, both parties are giving up the right to litigate such claims
                        and disputes in a court or jury trial. The results, determinations,
                        findings, judgments and/or awards rendered through such arbitration shall be
                        final and binding on the parties hereto and may be specifically enforced by
                        legal proceedings. Judgment on the award may be entered into any court
                        having jurisdiction. Neither party shall be entitled to join or consolidate
                        disputes by or against others in any arbitration, or to include in any
                        arbitration any dispute as a representative or member of a class or as part
                        of a class action, or to act in any arbitration in the interest of the
                        general public or in any private attorney general capacity. A demand for
                        arbitration shall not be made after the date when the institution of legal
                        or equitable proceedings based on the claim would be barred by the
                        applicable statute of limitations. For statute of limitations purposes,
                        receipt of a written demand for arbitration shall constitute the institution
                        of legal or equitable proceedings based on the claim. All proceedings in
                        arbitration shall be administered in New York County, New York. This
                        provision shall survive termination of the Agreement.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>PRIVACY AND SECURITY:</span> Data collected and stored
                        by Meadow pursuant to this Agreement is governed by the Institution&apos;s
                        privacy policy in addition to the Meadow privacy policy. Access to the data
                        shall be restricted to authorized associates of Meadow or its affiliates and
                        shall be used for the purposes of providing, marketing, and improving
                        services to you or the Institution. Meadow maintains physical, procedural,
                        and electronic safeguards to protect data from being accessed by
                        unauthorized third parties. You agree and acknowledge, in accordance with
                        the Meadow privacy policy that Meadow or its affiliates may market services
                        to You. You will have the opportunity to tell us you do not want to receive
                        future messages with each message you receive.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>MILITARY LENDING ACT:</span> Notice to Covered Members
                        under the Military Lending Act (10 U.S.C. Section 987 (i)(1)): Federal law
                        provides important protections to members of the Armed Forces and their
                        dependents relating to this agreement. In general, the cost of a payment
                        plan to a member of the Armed Forces and his or her dependent may not exceed
                        an annual percentage rate of 36 percent. This rate must include, as
                        applicable to your account: The costs associated with payment plan insurance
                        premiums, fees for ancillary products sold in connection with the payment
                        plan; any application of charged (other than certain application fees for
                        specified payment plans or accounts); and any participation fee charged.
                        Federal law also exempts you from the Arbitration section of this Agreement.
                    </Typography>
                    <Typography sx={gapSx} variant="body2">
                        <span style={boldSx}>SPECIAL NOTE REGARDING FINANCIAL AID:</span> Please do
                        not assume your balance will automatically be adjusted if you receive
                        financial aid or a class or service is added or dropped. You should review
                        your Agreement balance online or contact your Institution.
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <MeadowContainer fullWidth sx={{ padding: "1.5rem", width: isMobile ? "auto" : "50.5rem" }}>
            {config?.isAutopayEnabled ? legalTextWithAutopay() : legalTextNoAutopay()}
        </MeadowContainer>
    );
};

import { useState, FC } from "react";
import { Box, Card, Typography, Button } from "@mui/material";
import { AccountAccessResponse } from "../Student.model";
import { BLACK_000, GREY_100 } from "../../../theme/colors";
import { AccountAccessStatus } from "./AccountAccessStatus";
import RevokeAllyAccessModal from "./RevokeAllyAccessModal";

interface AccessCardProps {
    access: AccountAccessResponse;
    studentId: number;
    onRevokeAccess: () => Promise<void>;
    setRevokeAccessError: React.Dispatch<React.SetStateAction<string | undefined>>;
}
export const AccessCard: FC<AccessCardProps> = ({
    access,
    studentId,
    onRevokeAccess,
    setRevokeAccessError,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const cardSx = {
        padding: "1rem",
        boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        borderRadius: "0.5rem",
    };
    const fieldWrapperSx = {
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        padding: "1rem 0",
        borderBottom: `1px solid ${GREY_100}`,
        "&:first-child": {
            paddingTop: 0,
        },
    };
    const fieldTitleSx = {
        fontWeight: 500,
        color: BLACK_000,
        fontSize: "1rem",
        lineHeight: "1.5rem",
    };
    const fieldValueSx = {
        fontWeight: 400,
        color: BLACK_000,
        fontSize: "1rem",
        lineHeight: "1.5rem",
    };

    return (
        <>
            <Card sx={cardSx}>
                <div>
                    <Box sx={fieldWrapperSx}>
                        <Typography sx={fieldTitleSx}>First name</Typography>
                        <Typography sx={fieldValueSx}>{access.firstName}</Typography>
                    </Box>
                    <Box sx={fieldWrapperSx}>
                        <Typography sx={fieldTitleSx}>Last name</Typography>
                        <Typography sx={fieldValueSx}>{access.lastName}</Typography>
                    </Box>
                    <Box sx={fieldWrapperSx}>
                        <Typography sx={fieldTitleSx}>Date accepted</Typography>
                        <Box sx={fieldValueSx}>
                            <AccountAccessStatus accountAccess={access} />
                        </Box>
                    </Box>
                    <Box sx={fieldWrapperSx}>
                        <Typography sx={fieldTitleSx}>Email</Typography>
                        <Typography sx={fieldValueSx}>{access.email}</Typography>
                    </Box>
                </div>
                <Box
                    sx={{
                        paddingTop: "1rem",
                    }}
                >
                    <Button
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                        variant="secondary"
                        sx={{ maxWidth: "auto" }}
                    >
                        Remove access
                    </Button>
                </Box>
            </Card>
            <RevokeAllyAccessModal
                access={access}
                studentId={studentId}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                onRevokeAccess={onRevokeAccess}
                setRevokeAccessError={setRevokeAccessError}
            />
        </>
    );
};

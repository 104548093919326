/**
 * When SSO is enabled at a school, this is the component
 * that a student sees when navigating to `pay.wbu.edu` (or
 * equivalent for other schools).
 */

import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { BLUE_700, GREY_PLACEHOLDER, RED_500 } from "../../theme/colors";
import useBreakpoint from "../../hooks/useBreakpoint";
import { AuthService } from "../../services/AuthService";
import { useContext, useState } from "react";
import { ConfigContext } from "../../context/ConfigContext";
import LoadingDialog from "../modal/LoadingDialog";
import { Message } from "./Message";
import { ContactLink } from "./ContactLink";
import * as Sentry from "@sentry/react";

export default function WelcomeSSO() {
    const { isMobile } = useBreakpoint();
    const [authService] = useState(() => new AuthService());
    const { config } = useContext(ConfigContext);
    const [isLoading, setIsLoading] = useState(false);
    const [hasSsoError, setHasSsoError] = useState(false);
    const buttonColor = config?.ssoButtonColor;
    const buttonLabelColor = config?.ssoButtonLabelColor;
    const ssoButtonLabel = config?.ssoButtonLabel;
    const clearError = () => {
        setHasSsoError(false);
    };

    const signInSSO = async () => {
        if (config?.id) {
            try {
                setIsLoading(true);
                const result = await authService.getSsoRequestUrl(config.id);
                window.location.href = result.ssoUrl;
            } catch (err) {
                setIsLoading(false);
                setHasSsoError(true);
                Sentry.captureException(err);
            }
        } else {
            setHasSsoError(true);
        }
    };

    const buttonSX = {
        width: isMobile ? "100%" : "24.375rem",
        marginBottom: "1rem",
    };
    const ssoButtonSx = {
        ...buttonSX,
        color: buttonLabelColor,
        backgroundColor: buttonColor,
        "&:hover": {
            backgroundColor: buttonColor,
            opacity: "0.8",
        },
    };
    const titleSX = {
        width: isMobile ? "100%" : "24.375rem",
        marginBottom: "2rem",
    };
    const typographySX = {
        width: isMobile ? "100%" : "24.375rem",
        marginBottom: "1.5rem",
        fontSize: ".875rem",
    };
    const dividerWrapperMobileSx = {
        width: "100%",
        padding: "0",
    };
    const dividerWrapperDesktopSx = {
        width: "24.375rem",
        padding: "0 1.5rem",
    };
    const dividerSX = {
        width: "100%",
        color: GREY_PLACEHOLDER,
        marginBottom: "1.5rem",
    };
    const mediumWeight = {
        fontWeight: "500",
    };
    const containerPadding = {
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
    };

    const disclaimer = () => (
        <>
            By using Meadow Pay, you are agreeing to our{" "}
            <Link style={{ textDecoration: "none", color: BLUE_700 }} to="/legal/terms">
                terms of use
            </Link>{" "}
            and{" "}
            <Link style={{ textDecoration: "none", color: BLUE_700 }} to="/legal/privacy">
                privacy policy.
            </Link>
        </>
    );

    return (
        <Box>
            <div style={containerPadding}>
                <Message icon="info" clearError={clearError} showMessage={hasSsoError} color="red">
                    Something went wrong. Please try again, sign in with email, or{" "}
                    <ContactLink textColor={RED_500} /> .
                </Message>
                <Typography sx={titleSX} variant="h1">
                    Pay your school bills online easily and securely.
                </Typography>
                <Button onClick={signInSSO} sx={ssoButtonSx} disabled={isLoading} variant="primary">
                    {ssoButtonLabel || "Sign in with my student login"}
                </Button>
                <Typography sx={typographySX} variant="body1">
                    You are automatically enrolled with your school login credentials.
                </Typography>
                <Divider sx={dividerSX}>OR</Divider>
                <Link to="/signin" state={{ shouldHideSSO: true }}>
                    <Button sx={buttonSX} variant="primary">
                        Sign in
                    </Button>
                </Link>
                <br />
                <Link to="/signup">
                    <Button sx={buttonSX} variant="secondary">
                        Create Account
                    </Button>
                </Link>
                <Typography sx={typographySX} variant="body1">
                    <span style={mediumWeight}>If you are a parent, guardian or relative,</span> ask
                    your student to sign-in and grant you access. Once added, you will be able to
                    view and pay their bills.
                </Typography>
            </div>
            <Box sx={isMobile ? dividerWrapperMobileSx : dividerWrapperDesktopSx}>
                <Divider sx={dividerSX} />
            </Box>
            <div style={containerPadding}>
                <Typography sx={typographySX} variant="body1">
                    {disclaimer()}
                </Typography>
            </div>
            <LoadingDialog open={isLoading} />
        </Box>
    );
}

import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { ErrorTypes } from "../enums/errors";

export function parseJwt(jwt: string) {
    try {
        const base64Url = jwt.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(atob(base64));
    } catch (err) {
        // Catch this error if the cookie is corrupted to avoid crash
        Sentry.captureException(err);
    }
}

export function parseError(error: unknown): string {
    if (error instanceof AxiosError) {
        if (error.response?.data) {
            return error.response.data?.error;
        }
    }
    return ErrorTypes.GENERIC;
}

/**
 * Utility to get origin for _displaying_ relative links.
 */
export const getLocationOrigin = (): string => {
    if (window.location.origin) {
        return window.location.origin;
    }
    // backoff for IE / other browsers that don't support window.location.origin
    return window.location.protocol.concat("//", window.location.hostname);
};

export function sessionStorageGet(key: string) {
    const stringifiedValue = sessionStorage.getItem(key);
    if (stringifiedValue) {
        try {
            return JSON.parse(stringifiedValue);
        } catch (err) {
            Sentry.captureMessage(`Error getting data from session storage, because the stored JSON data is invalid.
            Key: ${key}
            Invalid Stored Data: ${stringifiedValue}`);
            return null;
        }
    }
    return null;
}

export function sessionStorageSet(key: string, value: unknown) {
    const stringifiedValue = JSON.stringify(value);
    sessionStorage.setItem(key, stringifiedValue);
}

export function sessionStorageRemove(key: string) {
    sessionStorage.removeItem(key);
}

export function randomId(len: number) {
    const chars = "0123456789";
    let result = "";
    for (let i = len; i > 0; --i) {
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
}

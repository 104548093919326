import { Box, Typography, Button } from "@mui/material";
import { STUDENT_STATUS } from "../../../constants";
import { GREY_500 } from "../../../theme/colors";
import { BillDueBanner } from "../BillDueBanner";
import { capitalizeFirstLetter, determineOrdinal, formatter } from "../utilities";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";
import { PastDueNextPaymentComponent } from "./PastDueNextPaymentComponent";
import { moneySx } from "./styles";
import { RemainingBalance } from "../payment-plans/RemainingBalance";
import { useContext } from "react";
import { ConfigContext } from "../../../context/ConfigContext";

type InnerMobileProps = {
    student: PaymentsStudent;
    handleMakeApayment: (student: PaymentsStudent) => void;
    makeAPayment: boolean;
    shouldShowNextPaymentHelper: boolean;
    handleViewBill: (student: PaymentsStudent) => void;
};

export default function InnerMobile({
    student,
    handleMakeApayment,
    makeAPayment,
    shouldShowNextPaymentHelper,
    handleViewBill,
}: InnerMobileProps) {
    const { config } = useContext(ConfigContext);
    const shouldHideViewDetailsButton =
        student.overduePlusNextPaymentAmount === 0 &&
        (config?.shouldHideBillDetailsIfNoBalanceDue || false);
    return (
        <Box>
            <Typography
                sx={{ fontWeight: 500 }}
                variant="body1"
                data-testid="billing-card-main-amount-heading"
            >
                {student.applicablePaymentPlan &&
                student.applicablePaymentPlan?.installmentsPaid !==
                    student.applicablePaymentPlan.installments.length
                    ? `${capitalizeFirstLetter(
                          determineOrdinal(student.applicablePaymentPlan?.installmentsPaid + 1)
                      )}
                      payment due for ${capitalizeFirstLetter(
                          student.applicablePaymentPlan?.periodOfEnrollment
                      )}`
                    : "Payment due"}
            </Typography>
            <Box sx={moneySx} data-testid="billing-card-main-amount">
                {config?.shouldShowDueDates
                    ? formatter.format(student.overduePaymentAmount || student.nextPaymentAmount)
                    : formatter.format(student.overduePlusNextPaymentAmount)}
            </Box>
            {config?.shouldShowDueDates && (
                <Box>
                    <BillDueBanner student={student} />
                </Box>
            )}

            {student.status === STUDENT_STATUS.PENDING && (
                <Box>
                    <Typography
                        sx={{
                            color: GREY_500,
                            fontWeight: 400,
                            fontSize: "0.75rem",
                            lineHeight: "1rem",
                            marginTop: "1.25rem",
                        }}
                    >
                        A payment was just made, it will be processed within 24 hours.
                    </Typography>
                </Box>
            )}
            {student.status !== STUDENT_STATUS.NO_PAYMENT_DUE && (
                <Button
                    sx={{
                        marginTop: "1.25rem",
                    }}
                    data-testid="make-a-payment"
                    variant="primary"
                    onClick={() => handleMakeApayment(student)}
                >
                    {"Make a payment"}
                </Button>
            )}
            {!makeAPayment && !shouldHideViewDetailsButton && (
                <Button
                    sx={{
                        marginTop: "1.25rem",
                    }}
                    variant={
                        student.status === STUDENT_STATUS.NO_PAYMENT_DUE ? "primary" : "tertiary"
                    }
                    onClick={() => handleViewBill(student)}
                >
                    {"View bill details"}
                </Button>
            )}
            {student.applicablePaymentPlan && (
                <RemainingBalance applicablePaymentPlan={student.applicablePaymentPlan} />
            )}
            {shouldShowNextPaymentHelper && config?.shouldShowDueDates && (
                <PastDueNextPaymentComponent student={student} />
            )}
        </Box>
    );
}

import { BLACK_000, BLUE_100, BLUE_700, GREY_100, GREY_50, GREY_500 } from "../../../theme/colors";

export const innerContainerSx = (isMobile: boolean) => ({
    width: isMobile ? "100%" : "50.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: isMobile ? 0 : "1rem",
    gap: "1rem",
});
export const meadowContainerSx = (isMobile: boolean) => ({
    alignItems: "center",
    padding: "1rem",
    paddingBottom: isMobile ? 0 : "1rem !important",
    backgroundColor: GREY_50,
});
export const desktopWidth = "50.5rem";
export const simpleHeaderSx = (isMobile: boolean) => ({
    width: isMobile ? "unset" : "50.5rem",
    padding: "1rem",
    backgroundColor: GREY_50,
});
export const planInformationSx = {
    backgroundColor: BLUE_100,
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.5rem",
};

export const planInformationInnerSx = (isMobile: boolean) => ({
    width: isMobile ? "100%" : desktopWidth,
    display: "flex",
    gap: "1rem",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: isMobile ? "1rem" : "2rem",
});

export const rowSx = () => ({
    display: "flex",
    gap: "0.75rem",
    alignItems: "center",
});

export const rowTextSx = {
    color: BLUE_700,
    fontSize: "0.875rem",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
export const totalSx = {
    ...rowTextSx,
    fontSize: "3rem",
    fontWeight: 500,
    color: BLUE_700,
};
export const installmentRowSx = {
    display: "flex",
    justifyContent: "space-between",
};
export const installmentTitleSx = {
    fontWeight: 500,
    color: BLACK_000,
};
export const installmentAmountSx = {
    fontWeight: 400,
    color: BLACK_000,
};

export const buttonContainerSx = {
    backgroundColor: GREY_50,
    width: "100%",
};
export const buttonSectionSx = (isMobile: boolean) => ({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    padding: "1rem",
    gap: "1rem",
    justifyContent: isMobile ? "center" : "flex-end",
});

export const buttonSx = (isMobile: boolean) => ({
    width: isMobile ? "100%" : "222px",
});

export const checkboxWrapper = (isMobile: boolean) => ({
    padding: isMobile ? "1rem" : "1rem 0 1rem 1rem",
});

export const disclaimerSx = {
    ...rowTextSx,
    padding: "0 2.375rem",
};

export const tilaCardSx = {
    width: "100%",
};

export const tilaCardInnerSx = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
};

export const tilaRowSx = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${GREY_100}`,
    padding: "1rem",
    "&:last-child": {
        borderBottom: "none",
    },
};

export const paymentScheduleHeaderRowSx = {
    borderBottom: `1px solid ${GREY_100}`,
    padding: "1rem",
    width: "100%",
    boxSizing: "border-box",
};

export const tilaNameSx = {
    display: "flex",
    color: BLACK_000,
    fontWeight: 500,
};

export const tilaRowTextBoldSx = {
    color: BLACK_000,
    fontSize: "0.875rem",
    fontWeight: 500,
};

export const tilaRowTextSx = {
    color: BLACK_000,
    fontSize: "0.875rem",
    fontWeight: 400,
};

export const paymentScheduleCardSx = {
    width: "100%",
};
export const paymentScheduleCardInnerSx = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
};

export const paymentScheduleRowWrapperSx = {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    borderBottom: `1px solid ${GREY_100}`,
    margin: "0 1rem",
    "&:last-child": {
        borderBottom: "none",
    },
};

export const paymentScheduleRow = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const rowWithTooltipWrapperSx = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
};
export const rowWithtooltipSx = {
    display: "flex",
    alignItems: "center",
};

export const dueDateTextSx = {
    color: GREY_500,
    fontSize: "0.875rem",
    fontWeight: 400,
};

export const disclaimerSectionSx = (isMobile: boolean) => ({
    backgroundColor: GREY_50,
    padding: "1rem",
    width: isMobile ? "100%" : desktopWidth,
    // this is easier than messing with the container gap
    marginTop: isMobile ? "-1rem" : 0,
    border: isMobile ? "none" : `1px solid ${GREY_100}`,
    borderTop: `1px solid ${GREY_100}`,
});

export const paymentPlanInnerWrapperSx = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem",
};
export const cardSx = {
    width: "100%",
    boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    borderRadius: "0.5rem",
};
export const titleSx = {
    display: "flex",
    gap: "0.635rem",
    alignItems: "center",
};

export const visibleSx = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
    padding: "0.75rem 0",
    borderTop: `1px solid ${GREY_100}`,
    "&:last-child": {
        borderBottom: `1px solid ${GREY_100}`,
    },
};

export const autopayFooterSx = (isMobile: boolean) => ({
    backgroundColor: GREY_50,
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
});

export const autopayFooterTextSx = {
    color: GREY_500,
    fontSize: "0.875rem",
    fontWeight: 400,
};

export const contactUsSx = {
    color: BLUE_700,
    fontWeight: 500,
    textDecoration: "none",
};

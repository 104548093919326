import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { GREY_500 } from "../../../theme/colors";
import { AuthService } from "../../../services/AuthService";
import * as Sentry from "@sentry/react";

interface CancelDialogProps {
    onClose: () => void;
    studentId: number;
}
export default function ConfirmRemoveAccountModal({ onClose, studentId }: CancelDialogProps) {
    const [authService] = useState(() => new AuthService());

    const removeAndClose = async () => {
        try {
            await authService.removeAdminAlly(studentId);
            // reload the page to reflect the changes
            window.location.reload();
            onClose();
        } catch (error) {
            Sentry.captureException(error, {
                level: "error",
                extra: { message: "Error removing admin ally" },
            });
        }
    };
    return (
        <Dialog
            open={true}
            onClose={onClose}
            data-testid="confirm-remove-account-modal"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "0.5rem",
                gap: "1rem",
                "& .MuiDialog-paper": {
                    maxWidth: "24.5rem",
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: 500,
                    fontSize: "22px",
                    lineHeight: "2rem",
                    padding: "2rem 1.5rem 1rem 1.5rem",
                }}
            >
                Are you sure you want remove this account?
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: GREY_500,
                    }}
                >
                    {
                        "This action removes the account from your view, it won't delete the information. You can add it back anytime."
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "0 1.5rem 2rem 1.5rem",
                }}
            >
                <Button onClick={removeAndClose} variant="primary" autoFocus>
                    Yes
                </Button>

                <Button onClick={onClose} variant="secondary">
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
}

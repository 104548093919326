import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "../../services/AuthService";
import LoadingDialog from "../modal/LoadingDialog";
import { UserContext } from "../../context/UserContext";
import * as Sentry from "@sentry/react";

export default function Permalink() {
    const [authService] = useState(() => new AuthService());
    const navigate = useNavigate();
    const { permalink } = useParams();
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user) navigate("/billing");
    }, [user]);
    useEffect(() => {
        async function checkRegistrationStatus(permalink: string) {
            try {
                const isRegistered = await authService.checkUserRegistration(permalink);
                const isRegisteredStatus = isRegistered.status;
                if (isRegisteredStatus === 1) {
                    // the user is not registered
                    navigate(`/confirm?permalink=${permalink}`);
                    return;
                }
                // the user is registered
                navigate("/signin");
            } catch (err) {
                Sentry.captureException(err);
                // if the student link is not valid
                navigate({
                    pathname: "/",
                    search: "?linkError=true",
                });
            }
        }

        if (!permalink) {
            navigate("/signin");
        } else {
            checkRegistrationStatus(permalink);
        }
    }, []);

    return <LoadingDialog open={true} />;
}

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GREY_500 } from "../../theme/colors";
import { PaymentContext } from "../../providers/PaymentsProvider";
import { useContext } from "react";

interface CancelDialogProps {
    open: boolean;
    onClose: () => void;
}
export default function CancelDialog({ open, onClose }: CancelDialogProps) {
    const navigate = useNavigate();
    const { reset } = useContext(PaymentContext);
    const navigateAndClose = () => {
        onClose();
        reset();
        navigate("/billing");
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "0.5rem",
                gap: "1rem",
                "& .MuiDialog-paper": {
                    maxWidth: "24.5rem",
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: 500,
                    fontSize: "22px",
                    lineHeight: "2rem",
                    padding: "2rem 1.5rem 1rem 1.5rem",
                }}
            >
                Do you want to cancel?
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: GREY_500,
                    }}
                >
                    {
                        "Note that your payment information won't be saved, and you'll need to enter it again for future payments."
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "0 1.5rem 2rem 1.5rem",
                }}
            >
                <Button onClick={navigateAndClose} variant="primary" autoFocus>
                    Yes
                </Button>
                {/* Mui has a weird CSS selector:
                    .MuiDialogActions-root>:not(:first-of-type) { margin-left: 8px;}

                    on these buttons for some reason, adding marginLeft: 0 is the easiest fix
                */}
                <Button sx={{ marginLeft: "0 !important" }} onClick={onClose} variant="secondary">
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import { useEffect, useState } from "react";
import useWindowWidth from "./useWindowWidth";

const useBreakpoint = () => {
    const MOBILE_BREAKPOINT = 640;
    const windowWidth = useWindowWidth();
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        if (!isMobile && windowWidth <= MOBILE_BREAKPOINT) {
            setIsMobile(true);
        } else if (isMobile && windowWidth > MOBILE_BREAKPOINT) {
            setIsMobile(false);
        }
    }, [windowWidth]);

    return {
        isMobile,
    };
};

export default useBreakpoint;

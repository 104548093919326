import BaseBanner from "./Base";
import { RED_200, RED_700 } from "../../../theme/colors";
import { OverdueInfoIcon } from "../../icons/OverdueInfoIcon";

export default function PaymentPlanPastDue() {
    return (
        <BaseBanner
            color={RED_700}
            backgroundColor={RED_200}
            message="You are behind on your payment plan. Please pay the remaining past due amount."
            Icon={OverdueInfoIcon}
        />
    );
}

import { AxiosError } from "axios";
import { sessionStorageRemove } from "./utilities";

export const makeAuthResponseErrorInterceptor = () => async (error: AxiosError) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
        await sessionStorageRemove("authToken");
        window.location.href = "/signin?expired=true";
    }

    return Promise.reject(error);
};

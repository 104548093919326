import { Box, Container, SxProps, Typography } from "@mui/material";
import { MeadowContainerProps } from ".";
import { UserIcon } from "../icons/UserIcon";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { BackIcon } from "../icons/BackIcon";
import { GREY_500, GREY_800, WHITE_000 } from "../../theme/colors";
import useBreakpoint from "../../hooks/useBreakpoint";
import ImageWithFallback from "../utils/ImageWithFallback";
import defaultSchoolLogo from "../../assets/defaultSchoolLogo.svg";
import { HelpIcon } from "../icons/HelpIcon";
import { Logging } from "../../services/Logging";
import { ConfigContext } from "../../context/ConfigContext";
import { AddAccountButton } from "../admin/AddAccountButton.js";
import { AdminBanner } from "../admin/AdminBanner";

const ContainerMobile = ({
    children,
    sx = {},
    showSimpleHeader = false,
    simpleHeaderText = "",
    noPadding = false,
    hideHeader = false,
    lightSimpleHeader = false,
    simpleHeaderOverrideStyles = {},
}: MeadowContainerProps): JSX.Element => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const { isMobile } = useBreakpoint();
    const isLoggedIn = !!user;
    const { config } = useContext(ConfigContext);
    const lowerCaseSchoolAbbreviation = config ? config.abbreviation.toLowerCase() : "";

    const containerSx: SxProps = {
        display: "flex",
        flexDirection: "column",
        padding: noPadding ? "0" : "1.5rem",
        flexGrow: 1,
        overflow: "auto",
        ...sx,
    };

    const simpleHeaderSx: SxProps = {
        padding: "1.5rem 1rem",
        backgroundColor: lightSimpleHeader ? WHITE_000 : GREY_800,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        ...simpleHeaderOverrideStyles,
    };

    return (
        <>
            <AdminBanner />
            {!showSimpleHeader && !hideHeader && (
                <Box
                    sx={{
                        padding: "1rem",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: isLoggedIn ? "space-between" : "center",
                        alignItems: "center",
                        position: "relative",
                        boxShadow:
                            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
                    }}
                >
                    <Link to={isLoggedIn ? "/billing" : "/"}>
                        {config && (
                            <ImageWithFallback
                                alt="Meadow Pay logo"
                                src={config.logoURL}
                                fallback={defaultSchoolLogo}
                                height={44}
                            />
                        )}
                    </Link>
                    <Box sx={{ display: "flex", gap: "1rem" }}>
                        <a
                            href={`https://help.meadowpay.com/${lowerCaseSchoolAbbreviation}`}
                            onClick={() => Logging.help()}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                color: GREY_500,
                            }}
                        >
                            <Box
                                sx={{
                                    display: isLoggedIn ? "flex" : "none",
                                    gap: "0.25rem",
                                    color: GREY_500,
                                }}
                            >
                                <HelpIcon sx={{ cursor: "pointer" }} />
                                Help
                            </Box>
                        </a>
                        <Box sx={{ display: isLoggedIn ? "flex" : "none" }}>
                            <UserIcon
                                sx={{ cursor: "pointer" }}
                                onClick={() => navigate("/account")}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
            {showSimpleHeader && isMobile && (
                <Box sx={simpleHeaderSx}>
                    <BackIcon data-testid="back-icon-mobile" onClick={() => navigate(-1)} />
                    <Typography variant="h1" sx={{ margin: 0 }}>
                        {simpleHeaderText}
                    </Typography>
                </Box>
            )}
            <Container sx={containerSx}>{children}</Container>
        </>
    );
};

export default ContainerMobile;

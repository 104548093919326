import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import arrowLeft from "../../assets/arrow_left.svg";
import MeadowContainer from "../container";
import { useNavigate } from "react-router-dom";
import { BLUE_700, GREY_500 } from "../../theme/colors";
import { getLocationOrigin } from "../../services/utilities";

export const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const termsLinkPath = "/legal/terms";
    const termsLinkCopy = getLocationOrigin().concat(termsLinkPath);

    return (
        <MeadowContainer fullWidth sx={{ padding: "1.5rem" }}>
            <Box sx={{ color: GREY_500, fontSize: "0.875rem" }}>
                <Box>
                    <Button sx={{ justifyContent: "left" }} onClick={() => navigate(-1)}>
                        <img src={arrowLeft} />
                    </Button>
                    <Typography variant="h1">Privacy policy</Typography>
                </Box>
                <p>Meadow Technologies, Inc.</p>
                <p>Privacy Policy</p>
                <p>Last updated: July 6th, 2023</p>
                <p>
                    This Privacy Policy (the “Privacy Policy”) serves to inform you of our policies
                    and procedures regarding the collection, use and disclosure of the information
                    we receive when you access and use the website (the “Site”), services, software,
                    content, and all other products and services (all of the foregoing collectively,
                    the “Services”) owned, controlled or offered, directly or indirectly, by Meadow
                    Technologies, Inc. (together with its subsidiaries and affiliates, “Meadow”,
                    “we,” “our” or “us”). Meadow believes that the privacy of its users is paramount
                    and strives to use Personal Information (as defined below) only in ways outlined
                    in this Privacy Policy. The Privacy Policy is incorporated by reference into our
                    Terms of Service, currently available at{" "}
                    <a
                        href={termsLinkPath}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: BLUE_700 }}
                    >
                        {termsLinkCopy}
                    </a>{" "}
                    (the “Terms of Service”). By using the Services, you hereby warrant and
                    represent that you have read, understand and agree to this Privacy Policy and
                    the Terms of Service and that you are over 13 years of age. PLEASE DO NOT USE,
                    INSTALL OR ACCESS THE SERVICES IF YOU DO NOT AGREE TO THIS PRIVACY POLICY.
                </p>
                <p>
                    Please note that this Privacy Policy does not apply to personal information that
                    we collect from other organizations in the course of providing our Services,
                    which may include public and private universities or other higher education
                    institutions (our “Clients”). If you have questions about the privacy practices
                    of our Clients, please contact them directly.
                </p>
                <p>1. What We Mean by Personal Information</p>
                <p>
                    For purposes of this Privacy Policy, “Personal Information” means any
                    information from or about a person that either identifies that person directly
                    or that makes that person identifiable when it is combined with other
                    information from or about that person from any source.
                </p>
                <p>2. Information We Collect</p>
                <p>Information You Provide To Us</p>
                <p>
                    When you use the Services or otherwise communicate with us, we collect
                    information that you provide to us directly. For example, we collect information
                    in the following circumstances: you (i) visit the Site, (ii) register for the
                    Services or create an account or profile or accept the creation of an account or
                    profile on your behalf (an “Account”), (iii) access the Services through third
                    party websites, (iv) subscribe to email or text communications; (v) request
                    technical support; (vi) otherwise use our online Services where Personal
                    Information is required for such use and/or participation, and (vii) when you
                    contact us via the Site or when you otherwise communicate with us. You can
                    choose not to provide information that is requested of you by us; however, this
                    may limit your ability to use or access the Services.
                </p>
                <p>
                    The information you provide to us directly may include, without limitation, the
                    following information that may, alone or in combination with other data,
                    constitute Personal Information:
                </p>
                <p>
                    * Information you provide in connection with completing a transaction using the
                    Services (for example, in connection with making a payment towards your
                    outstanding balance with your higher education institution), including your
                    name, email, phone number, financial and billing information, account security
                    and authentication information, IP address or other network activities, and any
                    other information you decide to provide;
                </p>
                <p>
                    * Information you provide via email, through the “Contact” section on the Site
                    or by using the contact details listed on various parts of the Site, including
                    your name, email, phone number, inquiry, and any other information you decide to
                    provide;
                </p>
                <p>
                    * Information you provide in order to subscribe to our electronic communications
                    and updates, including your email address and phone number, the topic for which
                    you wish to receive updates, or any other information you decide to provide us
                    with. You may always unsubscribe from these communications by following the
                    instructions included;
                </p>
                <p>
                    * If you are one of our customers, suppliers or prospects, we may process
                    limited Personal Information in the course of our business relation with you,
                    for example when you place an order, request a demo or vice versa. Such Personal
                    Information may include your name, company, title, email address, telephone
                    number, address, order details, and where applicable and relevant, credit
                    registrations and credit limits;
                </p>
                <p>* Transaction and limited (non-PCI) payment data; and</p>
                <p>
                    * Any other information you may want to share with us, such as Personal
                    Information related to recruitment / job applications.{" "}
                </p>
                <p>Moreover, if you contact us, a record of such correspondence may be kept. </p>
                <p>
                    Additionally, if another user refers you to us by providing us with your email
                    address, we may send you a message. However, we will use your email address only
                    to send such other user’s message unless you otherwise affirmatively provide
                    your information to us.
                </p>
                <p>
                    Finally, service providers, such as payment processors whom we utilize in
                    connection with providing the Services, may provide us with your personal
                    information, subject to their privacy practices.{" "}
                </p>
                <p>Information We Collect in the Course of Providing Our Services to Clients</p>
                <p>
                    We collect personal information in the course of providing our Services to
                    Clients. This information may include contact information; student
                    identification numbers; enrollment and financial aid information; payment
                    information, billing records and course information, your age and location
                    information. We process this information in our role as a service provider
                    according to the instructions of our Clients. If you have questions about the
                    privacy practices of our Clients, or if you wish to exercise your privacy rights
                    with respect to this information, we recommend that you contact our Clients
                    directly.
                </p>
                <p>Automatically Collected Information</p>
                <p>
                    Most of the data we collect in and through the Site and the Services is
                    technical in nature and is collected and processed automatically through
                    so-called application programming interfaces, software development kits, cookies
                    and similar software-based technologies. Alone or in combination with other
                    data, such automatically collected data may constitute Personal Information. The
                    data we may collect by automated means may include, without limitation:
                </p>
                <p>
                    * Device data: including, but not limited to, data on device ID’s and similar
                    hardware qualifiers, and precise location data.
                </p>
                <p>
                    * Usage data: including, but not limited to, search terms entered and pages
                    viewed.{" "}
                </p>
                <p>
                    * Network and internet information: including, but not limited to, URLs,
                    Internet Protocol addresses, bounce rates, use of spoofing, active (TCP/IP)
                    ports, number of sessions initiated, click streams, location information and
                    network/Wi-Fi access points.
                </p>
                <p>
                    * Information we collect on the use of the Site via cookies: please see the “How
                    We Use Cookies and Other Technologies” section below for more information.{" "}
                </p>
                <p>How We Use Cookies and Other Technologies </p>
                <p>
                    Some of the features on the Site and the Services require the use of “cookies” -
                    small text files that are stored on your device’s hard drive. We use cookies to
                    measure which pages are being accessed, and which features are most frequently
                    used. This enables us to continuously improve the Site to meet the needs of our
                    visitors.
                </p>
                <p>
                    The following sets out how we may use different categories of cookies and your
                    options for managing cookie settings:
                </p>

                <table>
                    <thead>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>Type of Cookies</p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>Description</p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>Managing Settings</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>Required cookies</p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    Required cookies enable you to navigate the Site and use their
                                    features, such as accessing secure areas of the Site and using
                                    the Services. If you have chosen to identify yourself to us, we
                                    use cookies containing encrypted information to allow us to
                                    uniquely identify you. These cookies allow us to uniquely
                                    identify you when you are logged into the Site and to process
                                    your online transactions and requests.
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    Because required cookies are essential to operate the Site,
                                    there is no option to opt out of these cookies.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>Performance cookies</p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    These cookies collect information about how you use our Sites,
                                    including which pages you go to most often and if they receive
                                    error messages from certain pages. These cookies do not collect
                                    information that individually identifies you. Information is
                                    only used to improve how the Site function and perform. From
                                    time-to-time, we may engage third parties to track and analyze
                                    usage and volume statistical information relating to individuals
                                    who visit the Site. We may also utilize Flash cookies for these
                                    purposes.
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    To learn how to opt out of performance cookies using your
                                    browser settings,{" "}
                                    <a href="https://www.aboutcookies.org/">click here</a>. To learn
                                    how to manage privacy and storage settings for Flash cookies,{" "}
                                    <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                                        click here
                                    </a>
                                    .
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={1} rowSpan={1}>
                                <p>Functionality cookies</p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    Functionality cookies allow our Sites to remember information
                                    you have entered or choices you make (such as your username,
                                    language, or your region) and provide enhanced, more personal
                                    features. These cookies also enable you to optimize your use of
                                    the Site after logging in. These cookies can also be used to
                                    remember changes you have made to text size, fonts and other
                                    parts of web pages that you can customize. We may use local
                                    shared objects, also known as Flash cookies, to store your
                                    preferences or display content based upon what you view on the
                                    Site to personalize your visit.
                                </p>
                            </td>
                            <td colSpan={1} rowSpan={1}>
                                <p>
                                    To learn how to opt out of functionality cookies using your
                                    browser settings,{" "}
                                    <a href="https://www.aboutcookies.org/">click here</a>. To learn
                                    how to manage privacy and storage settings for Flash cookies,{" "}
                                    <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                                        click here
                                    </a>
                                    .
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p>
                    We and our service providers may also use “pixel tags,” “web beacons,” “clear
                    GIFs,” or similar means in connection with the Services and HTML-formatted email
                    messages to, among other things, track the actions of users, to determine the
                    success of marketing campaigns and to compile aggregate statistics about Site
                    usage and response rates.{" "}
                </p>
                <p>3. Use of Collected Information</p>
                <p>
                    We use the information you provide to us for the following purposes: (i) to
                    further our legitimate interests in providing the Services, (ii) to present
                    bills and notices to you and your designees, facilitate the third-party
                    processing payments and keep records of bills and payments, (iii) to report
                    billing data and payment records to higher education institutions, (iv) to
                    administer your use of the Services and any Accounts you may have with us, (v)
                    to communicate with you and send reminders and other communications about
                    billing and payments on behalf of your higher education institution’s billing
                    office, (vi) to personalize your experience and improve your services or
                    facilitate tests of new services, communication methods or other offerings,
                    (vii) to provide to you Service announcements or inform you of new releases and
                    features, (viii) to provide you with further information and offers from us or
                    third parties that we believe you may find useful or interesting, such as
                    marketing or promotional materials, (ix) to perform tasks on behalf of and
                    according to instructions of a third party, such as payment processors, or third
                    party-service providers, (x) to enforce our Terms of Service, (xi) to resolve
                    any disputes between users of the Site or between such users and us, (xii)
                    comply with a legal requirement or process, including, but not limited to, civil
                    and criminal subpoenas, court orders or other compulsory disclosures; (xiii) to
                    further our legitimate interest in protecting our rights, property, or safety
                    and the rights, property and safety of the Services, our users or the public,
                    and (xiv) to contact you to obtain feedback from you regarding the Site and the
                    Services.{" "}
                </p>
                <p>
                    In addition to the purposes described above, we use the information collected
                    automatically to (i) to further our legitimate interests in monitoring and
                    analyzing the use of the Services and for the technical administration of the
                    Site, (ii) improve the Site and the Services, (iii) generate and derive useful
                    data and information concerning the interests, characteristics and website use
                    behavior of our users, and (iv) verify that users of the Services meet the
                    criteria required to process their requests.{" "}
                </p>
                <p>4. Third Parties with Whom We Share Personal Information </p>
                <p>
                    We may disclose Personal Information you provide to us or that we collect
                    automatically on the Site and in and through the Services with the following
                    categories of third parties:
                </p>
                <p>* Higher education institutions with which you have financial obligations;</p>
                <p>
                    * Channel partners, such as subprocessors (e.g. Twilio, Mixpanel, GitHub,
                    Sentry);{" "}
                </p>
                <p>
                    * Consultants and service providers contracted to provide services on your
                    behalf;
                </p>
                <p>
                    * Service providers, such as payment processors, web hosting and data storage
                    service providers, marketing service providers, and communications service
                    providers (e.g. Finix, Amazon Web Services);
                </p>
                <p>
                    * Public authorities, such as law enforcement, if we are legally required to do
                    so or if we need to protect our rights or the rights of third parties;{" "}
                </p>
                <p>
                    * Our subsidiaries and affiliates; or a subsequent owner, co-owner or operator
                    of the Site and/or the Services and their advisors in connection with a
                    corporate merger, consolidation, restructuring, the sale of substantially all of
                    our stock and/or assets, or in connection with bankruptcy proceedings, or other
                    corporate reorganization, in accordance with this Privacy Policy; and
                </p>
                <p>5. Links to Other Websites</p>
                <p>
                    We frequently make content or services from other websites available to you from
                    links located on the Site. We may present links in a format that enables us to
                    keep track of whether these links have been followed. This Privacy Policy
                    applies only to the Site and the Services. We do not exercise control over third
                    party services or other websites that provide information, or links from within
                    the Site or the Services. Your interactions with these third party services are
                    governed by the privacy policy of the company providing it. These other sites
                    and services may place their own cookies or other files on your computer’s
                    browser, collect data or solicit Personal Information from you. Other websites
                    and services follow different rules regarding the use or disclosure of the
                    Personal Information that you submit, and the collection and use of such
                    information and access of any third party websites are subject to such third
                    party’s privacy policy. We encourage you to read the privacy policies and other
                    terms of such third parties before using their services.
                </p>
                <p>6. Security</p>
                <p>
                    We understand the importance of privacy and security of Personal Information to
                    our users and have made them a priority. Meadow uses a variety of
                    industry-standard security technologies and procedures, including encryption and
                    identity-based permissions, to help protect Personal Information about you from
                    unauthorized access, use, or disclosure. However, we cannot guarantee that
                    unauthorized third parties will never be able to overcome those measures or use
                    your Personal Information for improper purposes, and we do not promise that
                    Personal Information about you or private communications will be protected from
                    unauthorized disclosure or use.{" "}
                </p>
                <p>7. Educational Information</p>
                <p>
                    We do not collect student information on the Site. However, when we do process
                    student information on behalf of our Clients, we comply with the Family
                    Educational Rights and Privacy Act (“FERPA”). For more information about FERPA,
                    please see the U.S. Department of Education page on Protecting Student Privacy
                    at https://studentprivacy.ed.gov/.
                </p>
                <p>8. Do Not Track Signals and Similar Mechanisms</p>
                <p>
                    Some web browsers transmit “do-not-track” signals to websites. Because of
                    differences in how web browsers incorporate and activate this feature, it is not
                    always clear whether users intend for these signals to be transmitted, or
                    whether they even are aware of them. We currently do not take action in response
                    to these signals.
                </p>
                <p>9. Students under the age of 18</p>
                <p>
                    We do not knowingly collect, maintain, or use personal information from children
                    under 13 years of age, and no part of the Services is directed at children.{" "}
                </p>
                <p>
                    With proper authentication, a parent or legal guardian always has the right to
                    review the Personal Information we may have collected and stored about their
                    child, request deletion of such Personal Information, or refuse to allow further
                    collection or use of such information by Meadow. Please keep in mind that a
                    request to delete such information may limit your or the child’s access to all
                    or a portion of the Services. If you believe that a child under 18 might have
                    provided us with Personal Information without your permission, or if you wish to
                    view, request deletion of, or prohibit further collection of information about
                    your child, please contact us at support@meadowfi.com.{" "}
                </p>
                <p>10. Managing Your Privacy</p>
                <p>
                    We keep your data on your behalf and for your benefit. You can correct or
                    terminate and delete your Account information by following the instructions on
                    the Services or by email at support@meadowfi.com, which you may also access for
                    further assistance and questions regarding the Privacy Policy or for a copy of
                    your Account data. If you cancel your Account or request us to delete your
                    information, Meadow will limit its access to Personal Information to perform
                    what is requested by you and will delete information accessible to Meadow within
                    seven business days.
                </p>
                <p>11. Your Rights</p>
                <p>
                    We take steps to help ensure that you are able to exercise your rights regarding
                    Personal Information about you in accordance with applicable law. If you would
                    like to access, review, erase, withdraw your consent, or object to, or restrict
                    the processing of Personal Data we collect about you, you may submit a request
                    to support@meadowfi.com. We will promptly review all such requests in accordance
                    with applicable laws. Depending on where you live, you may also have a right to
                    lodge a complaint with a supervisory authority or other regulatory agency if you
                    believe that we have violated any of the rights concerning Personal Information
                    about you. We encourage you to first reach out to us at support@meadowfi.com, so
                    we have an opportunity to address your concerns directly before you do so.
                </p>
                <p>12. California Residents</p>
                <p>
                    California Civil Code Section 1798.83 requires certain businesses that share
                    customer Personal Information with third parties for the third parties’ direct
                    marketing purposes to respond to requests from California customers asking about
                    the businesses’ practices related to such information-sharing. We currently do
                    not share or disclose your Personal Information to third parties for the third
                    parties’ direct marketing purposes. If we change our practices in the future, we
                    will implement an opt-out policy as required under California laws.
                </p>
                <p>
                    Furthermore, subject to certain exemptions, California residents have the
                    following rights with respect to Personal Information we may have collected
                    about them:{" "}
                </p>
                <p>Requests to Know</p>
                <p>You have the right to request that we disclose: </p>
                <p>* The categories of Personal Information we have collected about you;</p>
                <p>
                    * The categories of Personal Information about you we have sold or disclosed for
                    a business purpose;
                </p>
                <p>
                    * The categories of sources from which we have collected Personal Information
                    about you;
                </p>
                <p>
                    * The business or commercial purposes for selling or collecting Personal
                    Information about you;
                </p>
                <p>
                    * The categories of Personal Information sold or shared, if any, about you, as
                    well as the categories of third parties to whom the Personal Information was
                    sold, by category of Personal Information for each party to whom Personal
                    Information was sold; and
                </p>
                <p>* The specific pieces of Personal Information collected.</p>
                <p>
                    You may submit a request to know by emailing support@meadowfi.com. The delivery
                    of our response may take place electronically or by mail. We are not required to
                    respond to requests to know more than twice in a 12-month period.
                </p>
                <p>
                    We do not sell, and have not in the prior 12 months sold, Personal Information
                    about California residents. Therefore, we have not included a “Do Not Sell My
                    Personal Info” link on our Site. If our practices change, we will update this
                    Privacy Policy and take any other necessary action to comply with applicable
                    law. We do, however, disclose Personal Information for business purposes as
                    described in the “Third Parties We Share Personal Information With” section
                    above.
                </p>
                <p>Requests to Delete</p>
                <p>
                    You have the right to request that we delete any Personal Information about you
                    that we have collected. Upon receiving a verified request to delete Personal
                    Information, we will do so unless otherwise required or authorized by law. You
                    may submit a request to delete Personal Information by emailing
                    support@meadowfi.com.
                </p>
                <p>Authorized Agents</p>
                <p>
                    You may designate an authorized agent to make requests on your behalf. You must
                    provide an authorized agent written permission to submit a request on your
                    behalf, and we may require that you verify your identity directly with us.
                    Alternatively, an authorized agent that has been provided power of attorney
                    pursuant to Probate Code sections 4000-4465 may submit a request on your behalf.
                </p>
                <p>Methods for Submitting Consumer Requests and Our Response to Requests</p>
                <p>
                    You may submit a request for access and requests to delete Personal Information
                    about you via email at support@meadowfi.com.
                </p>
                <p>
                    Upon receipt of a request, we may ask you for additional information to verify
                    your identity. Any additional information you provide will be used only to
                    verify your identity and not for any other purpose.
                </p>
                <p>
                    We will acknowledge the receipt of your request within 10 days of receipt.
                    Subject to our ability to verify your identity, we will respond to your request
                    within 45 days of receipt. If we require more time (up to 90 days), we will
                    inform you of the reason and extension period in writing. In order to protect
                    your privacy and the security of Personal Information about you, we verify your
                    request by requiring you to log in to your user account in order to submit your
                    request.{" "}
                </p>
                <p>
                    Any disclosures we provide will only cover the 12-month period preceding the
                    verifiable consumer request &apos;s receipt. The response we provide will also
                    explain the reasons we cannot comply with a request, if applicable.
                </p>
                <p>
                    We do not charge a fee to process or respond to your verifiable consumer request
                    unless it is excessive, repetitive, or manifestly unfounded. If we determine
                    that the request warrants a fee, we will tell you why we made that decision and
                    provide you with a cost estimate before completing your request.
                </p>
                <p>The Right to Non-Discrimination</p>
                <p>
                    You have the right not to be discriminated against for the exercise of your
                    California privacy rights described above. Unless permitted by the CCPA, we will
                    not:
                </p>
                <p>* Deny you goods or services;</p>
                <p>
                    * Charge you different prices or rates for goods or services, including through
                    granting discounts or other benefits, or imposing penalties;
                </p>
                <p>* Provide you a different level or quality of goods or services; or</p>
                <p>
                    * Suggest that you may receive a different price or rate for goods or services
                    or a different level or quality of goods or services.
                </p>
                <p>
                    If you are a California resident, then under California Civil Code Section
                    1789.3, you may contact the Complaint Assistance Unit of the Division of
                    Consumer Services of the California Department of Consumer Affairs in writing at
                    1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone
                    at +1-800-952-5210 in order to resolve a complaint regarding the Service or to
                    receive further information regarding use of the Services.
                </p>
                <p>13. International Transfers of Personal Information</p>
                <p>
                    Meadow is based in the United States. As a result, Personal Information that we
                    collect in and through the Services and on the Site may be transferred to our
                    U.S. offices. In addition, we may work with third-party service providers in the
                    U.S. and in other countries to support our business activities. Thus, Personal
                    Information may be transferred to, stored on servers in, and accessed from the
                    United States and countries other than the country in which the Personal
                    Information was initially collected. In all such instances, we use, transfer,
                    and disclose Personal Information solely for the purposes described in this
                    Privacy Notice and in compliance with applicable laws.
                </p>
                <p>14. Data Retention</p>
                <p>
                    We keep Personal Information related to your Account for as long as it is needed
                    to fulfill the purposes for which it was collected, to provide our services, to
                    deal with possible legal claims, to comply with our business interests and/or to
                    abide by all applicable laws. Thereafter, we either delete Personal Information
                    about you or de-identify it. Please note that even if you request the deletion
                    of Personal Information about you, we may be required (by law or otherwise) to
                    retain the Personal Information and not delete it. However, once those
                    requirements are removed, we will delete Personal Information about you in
                    accordance with your request.
                </p>
                <p>15. Changes to the Privacy Policy</p>
                <p>
                    Our security and Privacy Policy are periodically reviewed and enhanced as
                    necessary. This Privacy Policy might change as we update and expand the
                    Services. You can tell when this Privacy Policy was last updated by reviewing
                    the Last Updated-legend on top of this page. We will endeavor to notify you of
                    these changes by email, but will not be liable for any failure to do so. We also
                    encourage you to review this Privacy Policy periodically. If you do not
                    understand any of the terms or conditions of any of our policies, you may
                    inquire regarding the same via email at support@meadowfi.com. Your continued use
                    of the Services after any change in this Privacy Policy will constitute your
                    acceptance of such change.
                </p>
                <p>16. Contacting Us</p>
                <p>
                    If you have any concerns or questions about this Privacy Policy, please contact
                    us at support@meadowfi.com.
                </p>
            </Box>
        </MeadowContainer>
    );
};

import { Box, SxProps } from "@mui/material";
import { BackIcon } from "../icons/BackIcon";
import { LogoutIcon } from "../icons/LogoutIcon";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { BLACK_000, GREY_50, GREY_800, WHITE_000 } from "../../theme/colors";
import MeadowContainer from "../container";
import useBreakpoint from "../../hooks/useBreakpoint";
import { Logging } from "../../services/Logging";
import {
    randomId,
    sessionStorageGet,
    sessionStorageRemove,
    sessionStorageSet,
} from "../../services/utilities";
import * as Sentry from "@sentry/react";
import { PaymentContext } from "../../providers/PaymentsProvider";

const IconLink = ({
    children: [icon, ...rest],
    onClick,
    sx,
}: {
    children: [React.ReactNode, string];
    onClick?: () => void;
    sx?: SxProps;
}) => (
    <Box
        onClick={onClick}
        sx={{
            display: "flex",
            justifyContent: "left",
            width: "100%",
            borderRadius: "0.5rem",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: GREY_50,
            },
            ...sx,
        }}
    >
        <Box sx={{ display: "flex", gap: "0.75rem", padding: "1rem" }}>
            <Box>{icon}</Box>
            <Typography sx={{ color: BLACK_000 }}>{rest}</Typography>
        </Box>
    </Box>
);

export const AccountSettings = () => {
    const { setUser } = useContext(UserContext);
    const { reset } = useContext(PaymentContext);
    const { isMobile } = useBreakpoint();
    const navigate = useNavigate();

    const IconLinkContainer = ({ children }: { children: React.ReactNode }) => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                backgroundColor: WHITE_000,
                borderRadius: "0.5rem",
                margin: "0.5rem",
                width: "100%",
                maxWidth: !isMobile ? "20.5rem" : "auto",
            }}
        >
            {children}
        </Box>
    );

    const accountSettingsSx = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };

    const accountContainerSx = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: !isMobile ? "22.5rem" : "auto",
        paddingTop: "1rem",
    };

    const boxSx = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: "1.5rem",
    };

    const logoutSx = {
        alignSelf: "flex-start",
        paddingBottom: "0.5rem",
        top: "0.5rem",
        left: "0.5rem",
        cursor: "pointer",
        marginLeft: "1rem",
    };

    const handleLogout = () => {
        Logging.logout();
        setUser(null);
        reset();
        sessionStorageRemove("authToken");
        sessionStorageRemove("refreshToken");

        // Start tracking subsequent requests as a new session.
        const oldMeadowSessionId = sessionStorageGet("meadowSessionId");
        const newMeadowSessionId = "MEADOWID-S" + randomId(10);
        sessionStorageSet("meadowSessionId", newMeadowSessionId);

        Sentry.addBreadcrumb({
            category: "lifecycle",
            message: `User has logged out, replacing session.
            End of session with Meadow Session ID: ${oldMeadowSessionId}. 
            Start of new session with Meadow Session ID: ${newMeadowSessionId}.
            The Meadow Request IDs for all subsequent requests in this new session will begin with the new prefix.`,
            level: "log",
        });

        navigate({
            pathname: "/",
            search: "?logout=true",
        });
    };

    const AccountSettingsBody = () => (
        <Box sx={accountSettingsSx}>
            <Box sx={accountContainerSx}>
                <BackIcon data-testid="back-icon" sx={logoutSx} onClick={() => navigate(-1)} />
                <Typography
                    sx={{ margin: 0, alignSelf: "flex-start", marginLeft: "1rem" }}
                    variant="h1"
                >
                    Account
                </Typography>
                <Box sx={boxSx}>
                    <IconLinkContainer>
                        <IconLink onClick={handleLogout}>
                            <LogoutIcon />
                            Logout
                        </IconLink>
                    </IconLinkContainer>
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            {isMobile ? (
                <Box
                    sx={{
                        padding: "2rem 1rem 0 1rem",
                        backgroundColor: GREY_800,
                        height: "100vh",
                    }}
                >
                    <AccountSettingsBody />
                </Box>
            ) : (
                <MeadowContainer
                    fullWidth
                    sx={{
                        backgroundColor: GREY_800,
                        height: "100%",
                    }}
                >
                    <AccountSettingsBody />
                </MeadowContainer>
            )}
        </>
    );
};

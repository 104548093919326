import { BLACK_000, BLUE_500, GREY_100, GREY_400, GREY_800 } from "../../../theme/colors";

export const radioWrapperSx = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: BLACK_000,
};

export const inputSx = {
    marginLeft: "2rem",
    width: "290px",
    "&:hover": {
        ".MuiOutlinedInput-notchedOutline": {
            borderColor: BLUE_500,
            border: `2px solid ${BLUE_500} !important`,
        },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
    },
    input: {
        border: "none !important",
        padding: "10px 1rem",
        paddingLeft: 0,
    },
    fieldset: {
        border: `2px solid ${GREY_400}`,
        borderRadius: "0.5rem",
        "&:hover": {
            border: "none !important",
        },
    },
};
export const disabledAccountInputSx = {
    ...inputSx,
    marginLeft: 0,
    marginTop: "0.25rem",
};

export const checkboxSx = {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
};

export const radioWrapperBorderTopSx = {
    ...radioWrapperSx,
    borderTop: `1px solid ${GREY_100}`,
};

export const innerContainerSx = (isMobile: boolean) => ({
    width: isMobile ? "100%" : "24.5rem",
});

export const checkboxCardSx = {
    boxShadow: "none",
    borderTop: `1px solid ${GREY_100}`,
    borderBottom: `1px solid ${GREY_100}`,
    borderRadius: 0,
};

export const otherAmountContainerSx = {
    display: "flex",
    flexDirection: "column",
    borderTop: `1px solid ${GREY_100}`,
    padding: "1rem",
};

export const otherAmountCheckboxContainerSx = {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
};

export const continueContainerSx = {
    padding: "0 1.5rem",
};

export const continueButtonSx = {
    marginTop: "1.5rem",
    marginBottom: "0.5rem",
};

export const meadowContainerSx = {
    backgroundColor: GREY_800,
    alignItems: "center",
};

export const amountPaddingSx = {
    padding: "1rem",
};

export const dollarSignSx = {
    color: BLACK_000,
};

export const disabledInputLabelSx = {
    fontFamily: "Tisa Sans Pro",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5rem",
    color: BLACK_000,
};

import * as Sentry from "@sentry/react";
import { useContext, useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { ErrorTypes } from "../../enums/errors";
import MeadowContainer from "../container";
import PoweredByMeadow from "../footer/PoweredByMeadow";
import LoadingDialog from "../modal/LoadingDialog";
import { AuthService } from "../../services/AuthService";
import { BLUE_700, GREY_PLACEHOLDER } from "../../theme/colors";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { parseError, parseJwt, sessionStorageGet } from "../../services/utilities";
import { UserContext } from "../../context/UserContext";
import { ContactUs } from "./ContactUs";
import { PaymentContext } from "../../providers/PaymentsProvider";
import { ConfigContext } from "../../context/ConfigContext";
import { SignInOTP } from "./SignInOTP";
import { SignInEmail } from "./SignInEmail";
import { SignInSSO } from "./SignInSSO";
import useBreakpoint from "../../hooks/useBreakpoint";
import maskEmail from "../utils/maskEmail";
import validator from "validator";
import { Logging } from "../../services/Logging";

export default function SignIn() {
    const { isMobile } = useBreakpoint();

    const [maskedEmail, setMaskedEmail] = useState<string>("");
    const [emailValue, setEmailValue] = useState<string>("");
    const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
    const [signInError, setSignInError] = useState<ErrorTypes | string>(ErrorTypes.NULL);
    const [sendCodeError, setSendCodeError] = useState<ErrorTypes | string>(ErrorTypes.NULL);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [pageContent, setPageContent] = useState<JSX.Element>();
    const [hasSsoError, setHasSsoError] = useState<boolean>(false);
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const { reset } = useContext(PaymentContext);
    const [authService] = useState(() => new AuthService());

    const { config, isLoading: isLoadingConfig } = useContext(ConfigContext);

    const { state } = useLocation();

    useEffect(() => {
        if (user) navigate("/billing");
    }, [user]);

    /**
     * 1. Shows the OTP page if an email was sent with a OTP
     * 2. Shows the "sign in with email" page (non-SSO) if SSO is disabled or if the user navigated here from the welcome page
     * 3. Shows the "sign in with SSO" page if SSO is enabled and the user did not navigate here from the welcome page
     */
    useEffect(() => {
        if (isCodeSent) {
            setPageContent(
                <SignInOTP
                    handleSubmit={submitCode}
                    error={sendCodeError}
                    maskedEmail={maskedEmail}
                    onBackClick={onCodeBackClick}
                    onResend={onResend}
                />
            );
        } else if (config?.isSsoEnabled && !state?.shouldHideSSO) {
            setPageContent(
                <SignInSSO
                    handleSubmit={submitEmail}
                    onSsoClick={onSsoClick}
                    error={signInError}
                    hasSsoError={hasSsoError}
                    isLoading={isLoading}
                    clearError={clearSsoError}
                />
            );
        } else {
            setPageContent(<SignInEmail handleSubmit={submitEmail} error={signInError} />);
        }
        window.history.replaceState({}, document.title);
    }, [isCodeSent, config, state, signInError, sendCodeError, maskedEmail, hasSsoError]);

    // TODO: Refactor this to use the utility function
    async function setUserData() {
        const encodedToken = sessionStorageGet("authToken");
        if (typeof encodedToken === "string") {
            const userData = parseJwt(encodedToken);
            Logging.login(userData.userId);
            setUser(userData);
        }
    }

    const onResend = async () => {
        await submitEmail(emailValue);
    };

    const submitEmail = async (email: string) => {
        setSignInError(ErrorTypes.NULL);

        if (email.length === 0) setSignInError(ErrorTypes.EMPTY);
        if (email.length > 0) {
            const isEmail = validator.isEmail(email);

            if (!isEmail) {
                setSignInError(ErrorTypes.INVALID);
            } else {
                const emailToMask = maskEmail(email);
                setMaskedEmail(emailToMask);

                try {
                    setEmailValue(email);
                    setIsLoading(true);

                    const authService = new AuthService();
                    await authService.otpSendChallenge(email);
                    setIsCodeSent(true);
                } catch (error) {
                    setSignInError(parseError(error));
                    Sentry.captureException(error);
                }

                setIsLoading(false);
            }
        }
    };

    const submitCode = async (code: string) => {
        try {
            setIsLoading(true);
            const authService = new AuthService();

            await authService.otpVerifyChallenge(emailValue, code);
            reset();
            await setUserData();
            navigate("/billing");
        } catch (error) {
            setSendCodeError(parseError(error));
            Sentry.captureException(error);
        }

        setIsLoading(false);
    };

    const onCodeBackClick = () => {
        setSignInError(ErrorTypes.NULL);
        setSendCodeError(ErrorTypes.NULL);
        setIsCodeSent(false);
        setEmailValue("");
    };

    const onSsoClick = async () => {
        if (config?.id) {
            try {
                setIsLoading(true);
                const result = await authService.getSsoRequestUrl(config.id);
                window.location.href = result.ssoUrl;
            } catch (err) {
                setHasSsoError(true);
                Sentry.captureException(err);
            }
        } else {
            setHasSsoError(true);
        }

        setIsLoading(false);
    };

    const clearSsoError = () => {
        setHasSsoError(false);
    };

    const typographySX = {
        margin: "1rem auto",
        paddingLeft: isMobile ? "1.5rem" : "0rem",
        paddingRight: isMobile ? "1.5rem" : "0rem",
    };

    const dividerSX = {
        width: isMobile ? "100%" : "auto",
        color: GREY_PLACEHOLDER,
        margin: "1rem auto",
    };

    // I added React.CSSProperties type because if removed it
    // gives a TS error on the "column" value of flexDirection
    const flexEndContainer: React.CSSProperties = {
        width: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    };

    const containerPadding = {
        paddingLeft: isMobile ? "1.5rem" : "0rem",
        paddingRight: isMobile ? "1.5rem" : "0rem",
    };

    const footer = {
        marginTop: "auto",
        paddingLeft: isMobile ? "1.5rem" : "0rem",
        paddingRight: isMobile ? "1.5rem" : "0rem",
    };

    const dividerWrapperMobileSx = {
        width: "100%",
        padding: "0rem",
    };
    const dividerWrapperDesktopSx = {
        padding: "0rem",
    };
    const containerSX = {
        width: isMobile ? "100%" : "24.375rem",
        /**
         * These styles are marked !important because we want them to
         * take predence over the padding defined in MuiContainer.root
         * which is marked with left and right padding of 1.5 rem.
         */
        padding: "0rem 0rem 1rem 0rem !important",
    };

    return (
        <MeadowContainer noPadding sx={containerSX}>
            {!isLoadingConfig ? (
                <>
                    <div style={flexEndContainer}>
                        <div style={containerPadding}>{pageContent}</div>
                        {!isCodeSent && (
                            <>
                                <Box
                                    sx={isMobile ? dividerWrapperMobileSx : dividerWrapperDesktopSx}
                                >
                                    <Divider sx={dividerSX} />
                                </Box>
                                <Typography sx={typographySX} variant="body1">
                                    By using Meadow Pay, you are agreeing to our{" "}
                                    <Link
                                        style={{ textDecoration: "none", color: BLUE_700 }}
                                        to="/legal/terms"
                                    >
                                        terms of use
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                        style={{ textDecoration: "none", color: BLUE_700 }}
                                        to="/legal/privacy"
                                    >
                                        privacy policy.
                                    </Link>
                                </Typography>
                            </>
                        )}
                        <div style={footer}>
                            <ContactUs />
                            <PoweredByMeadow />
                        </div>
                    </div>
                    {isLoading && <LoadingDialog open={isLoading} />}
                </>
            ) : (
                <></>
            )}
        </MeadowContainer>
    );
}

import * as Sentry from "@sentry/react";
import MeadowContainer from "../../container";
import {
    ACCOUNT_HOLDER_NAME_EMPTY,
    CARD_NUMBER_INCORRECT,
    EXPIRATION_DATE_INCORRECT,
    FINIX_CC_EXPIRATION_FIELD,
    FINIX_CC_NUMBER_FIELD,
    FINIX_CC_SECURITY_CODE_FIELD,
    FINIX_NAME_FIELD,
    FINIX_ZIP_CODE_FIELD,
    finixCreditCardKeyArray,
    SECURITY_CODE_INCORRECT,
    ZIP_CODE_INCORRECT,
} from "../../../constants";
import { GREY_100, GREY_800, RED_500, WHITE_000 } from "../../../theme/colors";
import { Box, Button, FormControl, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormEvent, MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { errorFieldIframeSx, errorSx, fieldHolderSx, fieldIframeSx, inputStyle } from "./styles";
import CancelDialog from "../CancelDialog";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { PaymentService } from "../../../services/PaymentService";
import SecurePayment from "../SecurePayment";
import errorInfo from "../../../assets/error_info.svg";
import { ErrorTypes } from "../../../enums/errors";
import { PaymentContext } from "../../../providers/PaymentsProvider";
import LoadingDialog from "../../modal/LoadingDialog";
import useAmountToPay from "../../../hooks/useAmountToPay";

interface FieldType {
    id: string;
    finixType: "name" | "number" | "expiration_date" | "security_code" | "address.postal_code";
    placeholder: string;
    ref: MutableRefObject<HTMLElement | null>;
    validation: string;
    autoComplete: string;
}
export default function CreditCard() {
    const navigate = useNavigate();
    const { setPaymentInstrument } = useContext(PaymentContext);
    const { isMobile } = useBreakpoint();
    const formRef = useRef<HTMLFormElement | null>(null);
    const nameRef = useRef<HTMLElement | null>(null);
    const cardNumberRef = useRef<HTMLElement | null>(null);
    const expirationDateRef = useRef<HTMLElement | null>(null);
    const securityCodeRef = useRef<HTMLElement | null>(null);
    const zipCodeRef = useRef<HTMLElement | null>(null);
    const [isFinixLoaded, setIsFinixLoaded] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [finixErrorState, setFinixErrorState] = useState<any>(null);
    const [errorFields, setErrorFields] = useState<string[]>([]);
    const [finixForm, setFinixForm] = useState<any>(() =>
        window.PaymentForm.card((state: any) => {
            setFinixErrorState(state);
        })
    );

    const fieldsListRef = useRef<FieldType[]>([
        {
            id: "name",
            finixType: "name",
            placeholder: "e.g. John Doe",
            validation: "required",
            autoComplete: "cc-name",
            ref: nameRef,
        },
        {
            id: "number",
            finixType: "number",
            placeholder: "XXXX XXXX XXXX XXXX",
            validation: "cardNumber",
            autoComplete: "cc-number",
            ref: cardNumberRef,
        },
        {
            id: "expiration_date",
            finixType: "expiration_date",
            placeholder: "MM / YY",
            // validation: "cardExpiry",
            validation: "required",
            ref: expirationDateRef,
            autoComplete: "cc-exp",
        },
        {
            id: "cvc",
            finixType: "security_code",
            placeholder: "CVC",
            validation: "cardCVC",
            ref: securityCodeRef,
            autoComplete: "cc-csc",
        },
        {
            id: "address_postal_code",
            finixType: "address.postal_code",
            placeholder: "5 digits",
            validation: "required",
            ref: zipCodeRef,
            autoComplete: "postal-code",
        },
    ]);
    useAmountToPay("credit-card");

    useEffect(() => {
        // this buys us a little time to load the finix form, and make sure the refs are set
        // https://muffinresearch.co.uk/does-settimeout-solve-the-domcontentloaded-problem/
        const timeoutId = setTimeout(() => {
            if (
                !nameRef.current ||
                !cardNumberRef.current ||
                !expirationDateRef.current ||
                !securityCodeRef.current ||
                !zipCodeRef.current
            ) {
                return;
            }
            if (!finixForm) {
                setFinixForm(
                    window.PaymentForm.card((state: any) => {
                        finixErrorState(state);
                    })
                );
            }
            fieldsListRef.current.forEach((field) => {
                const formInputIframe = finixForm.field(field.finixType, {
                    validations: field.validation,
                    autoComplete: field.autoComplete,
                    placeholder: {
                        text: field.placeholder,
                        hideOnFocus: false,
                    },
                    styles: {
                        default: inputStyle,
                        // This doesn't work like it should :(
                        // error: {
                        //     border: `1px solid ${RED_500}`,
                        // },
                    },
                });

                field?.ref?.current?.appendChild(formInputIframe);
            });
            setIsFinixLoaded(true);
        }, 0);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const innerContainerSx = {
        width: isMobile ? "100%" : "24.5rem",
    };
    const errorLabelWrapperSX = {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        paddingBottom: "1rem",
    };
    const errorLabelSX = {
        color: RED_500,
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "1.25rem",
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errorList: string[] = [];

        finixCreditCardKeyArray.forEach((item: string) => {
            if (finixErrorState && finixErrorState[item].errorMessages.length > 0) {
                errorList.push(item);
                setErrorFields(errorList);
            }
        });

        // if there are errors, don't submit
        if (errorList.length > 0) {
            setIsProcessing(false);
            return;
        }
        try {
            setIsProcessing(true);
            finixForm.submit(
                import.meta.env.VITE_FINIX_ENVIRONMENT,
                import.meta.env.VITE_FINIX_APP_ID,
                async (err: any, res: any) => {
                    try {
                        if (err) {
                            console.log("err", err);
                            throw err;
                        }

                        if (res?.data?._embedded?.errors) {
                            console.log("res", res);
                            throw res?.data?._embedded?.errors;
                        }

                        const token = res?.data?.id;
                        const paymentService = new PaymentService();
                        const paymentResponse = await paymentService.makePaymentMethod(token);

                        setPaymentInstrument(paymentResponse);
                        setIsProcessing(false);
                        navigate("/payment/summary");
                    } catch (error) {
                        // something with our api went wrong
                        // for now we will just show a generic error
                        setIsProcessing(false);
                        setErrorMessage(ErrorTypes.GENERIC);
                        Sentry.captureException(error);
                    }
                }
            );
        } catch (error: unknown) {
            // something with finix went wrong
            setIsProcessing(false);
            setErrorMessage(ErrorTypes.GENERIC);
            Sentry.captureException(error);
        }
    };

    return (
        <MeadowContainer
            fullWidth
            showSimpleHeader={true}
            noPadding
            simpleHeaderText="Enter card information"
            sx={{
                backgroundColor: GREY_800,
                alignItems: isMobile ? "unset" : "center",
                borderBottom: `1px solid ${GREY_100}`,
            }}
        >
            <Box sx={innerContainerSx}>
                <Box
                    sx={{
                        backgroundColor: WHITE_000,
                        padding: "1.25rem 1rem",
                        display: isFinixLoaded ? "flex" : "none",
                        width: isMobile ? "unset" : "24.5rem",
                        paddingBottom: "0",
                        borderWidth: "1px 0px",
                        borderStyle: "solid",
                        borderColor: GREY_100,
                    }}
                >
                    <FormControl sx={{ width: isMobile ? "100%" : "24.5rem" }}>
                        {errorMessage && errorMessage.length > 0 && (
                            <Box sx={errorLabelWrapperSX}>
                                <img src={errorInfo} />
                                <Typography sx={errorLabelSX}>{errorMessage}</Typography>
                            </Box>
                        )}
                        <form ref={formRef} onSubmit={handleSubmit}>
                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>Name on card</label>
                                <Box
                                    id="name"
                                    className="field"
                                    sx={
                                        errorFields.includes(FINIX_NAME_FIELD)
                                            ? errorFieldIframeSx
                                            : fieldIframeSx
                                    }
                                    ref={nameRef}
                                ></Box>
                            </Box>
                            {errorFields.includes(FINIX_NAME_FIELD) && (
                                <Box sx={errorSx}>{ACCOUNT_HOLDER_NAME_EMPTY}</Box>
                            )}

                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>Card number</label>
                                <Box
                                    id="number"
                                    sx={
                                        errorFields.includes(FINIX_CC_NUMBER_FIELD)
                                            ? errorFieldIframeSx
                                            : fieldIframeSx
                                    }
                                    className="field"
                                    ref={cardNumberRef}
                                ></Box>
                            </Box>
                            {errorFields.includes(FINIX_CC_NUMBER_FIELD) && (
                                <Box sx={errorSx}>{CARD_NUMBER_INCORRECT}</Box>
                            )}
                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>Expiration date</label>
                                <Box
                                    id="expiration_date"
                                    sx={
                                        errorFields.includes(FINIX_CC_EXPIRATION_FIELD)
                                            ? errorFieldIframeSx
                                            : fieldIframeSx
                                    }
                                    className="field"
                                    ref={expirationDateRef}
                                ></Box>
                            </Box>
                            {errorFields.includes(FINIX_CC_EXPIRATION_FIELD) && (
                                <Box sx={errorSx}>{EXPIRATION_DATE_INCORRECT}</Box>
                            )}
                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>Security code</label>
                                <Box
                                    id="cvc"
                                    sx={
                                        errorFields.includes(FINIX_CC_SECURITY_CODE_FIELD)
                                            ? errorFieldIframeSx
                                            : fieldIframeSx
                                    }
                                    className="field"
                                    ref={securityCodeRef}
                                ></Box>
                            </Box>
                            {errorFields.includes(FINIX_CC_SECURITY_CODE_FIELD) && (
                                <Box sx={errorSx}>{SECURITY_CODE_INCORRECT}</Box>
                            )}
                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>ZIP code</label>
                                <Box
                                    id="address_postal_code"
                                    sx={
                                        errorFields.includes(FINIX_ZIP_CODE_FIELD)
                                            ? errorFieldIframeSx
                                            : fieldIframeSx
                                    }
                                    className="field"
                                    ref={zipCodeRef}
                                ></Box>
                            </Box>
                            {errorFields.includes(FINIX_ZIP_CODE_FIELD) && (
                                <Box sx={errorSx}>{ZIP_CODE_INCORRECT}</Box>
                            )}
                        </form>
                    </FormControl>
                </Box>

                <Box sx={{ padding: "0 1.5rem" }}>
                    <Button
                        sx={{
                            marginTop: "1.5rem",
                        }}
                        disabled={isProcessing}
                        onClick={(e: any) => handleSubmit(e)}
                        variant="primary"
                        type="submit"
                    >
                        Continue
                    </Button>
                    <Button
                        sx={{
                            marginTop: "0.75rem",
                        }}
                        onClick={() => setIsCancel(true)}
                        variant="secondary"
                    >
                        Cancel
                    </Button>
                    <SecurePayment />
                </Box>
            </Box>
            <LoadingDialog open={isProcessing} />
            <>{isCancel && <CancelDialog open={isCancel} onClose={() => setIsCancel(false)} />}</>
        </MeadowContainer>
    );
}

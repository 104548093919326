import { Box, Checkbox, FormControlLabel, SxProps, Typography } from "@mui/material";
import { BLUE_500, GREY_400, RED_500 } from "../../theme/colors";
import errorInfo from "../../assets/error_info.svg";
import { ReactNode } from "react";
import { ErrorTypes } from "../../enums/errors";

interface CheckBoxRowProps {
    onCheck: () => void;
    isChecked: boolean;
    error?: boolean;
    label: () => ReactNode;
    noLabelSpacing?: boolean;
    termsOnly?: boolean;
    sx?: SxProps;
}
export default function CheckboxRow({
    isChecked = false,
    onCheck,
    error = false,
    label,
    noLabelSpacing = false,
    termsOnly = false,
    sx,
}: CheckBoxRowProps) {
    const CheckboxLabel = () => (
        <Typography variant="checkboxLabel">
            <Box>{label()}</Box>
        </Typography>
    );

    const formControlLabelSX = {
        marginTop: "1.5rem",
        marginBottom: error ? "0.5rem" : "1.5rem",
        margin: noLabelSpacing ? 0 : "auto",
    };
    const checkboxSX = {
        color: error ? RED_500 : GREY_400,
        borderRadius: "4px",
        paddingTop: noLabelSpacing ? 0 : "auto",
        paddingBottom: noLabelSpacing ? 0 : "auto",
        paddingLeft: noLabelSpacing ? 0 : "auto",
        "&:hover": {
            backgroundColor: "transparent",
        },
        "& .MuiSvgIcon-root": { fontSize: 28 },
        "&.Mui-checked": {
            color: BLUE_500,
        },
    };

    const errorLabelWrapperSX = {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        marginBottom: "1.5rem",
        marginTop: "1.5rem",
        ...sx,
    };

    const errorLabelSX = {
        color: RED_500,
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "1.25rem",
    };

    return (
        <>
            <FormControlLabel
                sx={formControlLabelSX}
                control={<Checkbox checked={isChecked} onChange={onCheck} sx={checkboxSX} />}
                label={<CheckboxLabel />}
            />
            {error && (
                <Box sx={errorLabelWrapperSX}>
                    <img src={errorInfo} />
                    <Typography sx={errorLabelSX}>
                        {termsOnly ? ErrorTypes.TERMS_AND_CONDITIONS : ErrorTypes.TERMS}
                    </Typography>
                </Box>
            )}
        </>
    );
}

import { createContext, useState } from "react";

interface ContextProps {
    children: React.ReactNode;
}

interface ConfigContextType {
    isLoading: boolean;
    config: {
        id: number;
        logoURL: string;
        abbreviation: string;
        isSsoEnabled: boolean;
        schoolName: string;
        ssoButtonLabel?: string | null;
        ssoButtonLabelColor?: string | null;
        ssoButtonColor?: string | null;
        tilaDisclosureText?: string | null;
        isAutopayEnabled: boolean;
        isTilaEnabled: boolean;
        shouldHideBillDetailsIfNoBalanceDue: boolean;
        shouldShowDueDates: boolean;
    } | null;
    setConfig: (
        config: {
            id: number;
            logoURL: string;
            abbreviation: string;
            isSsoEnabled: boolean;
            schoolName: string;
            ssoButtonLabel?: string | null;
            ssoButtonLabelColor?: string | null;
            ssoButtonColor?: string | null;
            tilaDisclosureText?: string | null;
            isAutopayEnabled: boolean;
            isTilaEnabled: boolean;
            shouldHideBillDetailsIfNoBalanceDue: boolean;
            shouldShowDueDates: boolean;
        } | null
    ) => void;
    setIsLoading: (isLoading: boolean) => void;
}

export const ConfigContext = createContext<ConfigContextType>({
    isLoading: true,
    config: null,
    setConfig: () => {},
    setIsLoading: () => {},
});

export default function ConfigContextProvider({ children }: ContextProps) {
    const [config, setConfig] = useState<{
        id: number;
        logoURL: string;
        abbreviation: string;
        isSsoEnabled: boolean;
        schoolName: string;
        ssoButtonLabel?: string | null;
        ssoButtonLabelColor?: string | null;
        ssoButtonColor?: string | null;
        tilaDisclosureText?: string | null;
        isAutopayEnabled: boolean;
        isTilaEnabled: boolean;
        shouldHideBillDetailsIfNoBalanceDue: boolean;
        shouldShowDueDates: boolean;
    } | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    return (
        <ConfigContext.Provider
            value={{
                isLoading,
                config,
                setConfig,
                setIsLoading,
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

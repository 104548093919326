import { Box, List, ListItem, Typography } from "@mui/material";
import { BLUE_100, BLUE_700, GREY_500 } from "../../../theme/colors";

export const SharingList = () => {
    const listData = [
        {
            title: "Bills",
            description: "View billing information, including Financial Aid.",
        },
        {
            title: "Reminders",
            description: "Receive billing notifications and reminders.",
        },
        {
            title: "Payments",
            description: "Pay on your behalf and view payment history.",
        },
    ];
    return (
        <>
            <Typography
                sx={{
                    fontSize: "0.875rem",
                    lineHeight: "1.5rem",
                    color: GREY_500,
                    padding: "1rem",
                }}
            >
                By granting access to your account, you are authorizing the following actions:
            </Typography>
            <List>
                {listData.map((item, index) => (
                    <ListItem
                        key={index}
                        sx={{
                            color: GREY_500,
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                        }}
                    >
                        <Box
                            sx={{
                                padding: "0.25rem",
                                color: BLUE_700,
                                backgroundColor: BLUE_100,
                                borderRadius: "100px",
                                height: "1.375rem",
                                minWidth: "1.375rem",
                                textAlign: "center",
                            }}
                        >
                            {index + 1}
                        </Box>
                        <Box>
                            <span style={{ fontWeight: "500" }}>{item.title}:</span>{" "}
                            {item.description}
                        </Box>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Box, Typography } from "@mui/material";
import { adminBannerSx, adminBannerTextSx } from "./styles";

export const AdminBanner = () => {
    const { user } = useContext(UserContext);

    return (
        <>
            {user && user.isAdmin ? (
                <Box data-testid="admin-banner" sx={adminBannerSx}>
                    <Typography sx={adminBannerTextSx}>ADMIN</Typography>
                </Box>
            ) : null}
        </>
    );
};

import { AccountAccessResponse } from "../components/billing/Student.model";
import { meadowHttpFactory, IMeadowHttpFactory } from "./meadowHttpFactory";

export type IAuthService = {
    otpSendChallenge(email: string): Promise<any>;
    otpVerifyChallenge(email: string, value: string): Promise<any>;
    checkUserRegistration(permalink: string): Promise<any>;
    otpRegisterSendChallenge(email: string, permalink: string): Promise<any>;
    otpRegisterVerifyChallenge(email: string, permalink: string, challenge: string): Promise<any>;
    getSsoRequestUrl(schoolId: number): Promise<{ ssoUrl: string }>;
    ssoLogin(): Promise<any>;
};

export type OtpLoginResponse = {
    success: boolean;
};

export class AuthService implements IAuthService {
    private http: IMeadowHttpFactory;
    public constructor(http?: IMeadowHttpFactory) {
        this.http = http || meadowHttpFactory({});
    }

    public async ssoLogin(): Promise<any> {
        const result = await this.http.get("/v1/auth/sso/login");

        return result.data;
    }

    public async checkUserRegistration(permalink: string): Promise<any> {
        const result = await this.http.post("/v1/auth/permalink", { permalink });

        return result.data;
    }

    public async otpRegisterSendChallenge(email: string, permalink: string): Promise<any> {
        const result = await this.http.post("/v1/auth/otp_register_send_challenge", {
            email,
            permalink,
        });

        return result.data;
    }

    public async otpRegisterVerifyChallenge(
        email: string,
        permalink: string,
        challenge: string
    ): Promise<any> {
        const result = await this.http.post("/v1/auth/otp_register_verify_challenge", {
            email,
            permalink,
            challenge,
        });

        return result.data;
    }

    // sends email with otp code
    public async otpSendChallenge(email: string): Promise<OtpLoginResponse> {
        const result = await this.http.post("/v1/auth/otp_send_challenge", { email });

        return result.data;
    }

    // verify code
    public async otpVerifyChallenge(email: string, challenge: string): Promise<any> {
        const result = await this.http.post("/v1/auth/otp_verify_challenge", { email, challenge });

        return result.data;
    }

    public async inviteAlly(
        email: string,
        firstName: string,
        lastName: string,
        studentId: number
    ): Promise<any> {
        const result = await this.http.post("/v1/auth/student_access_challenge", {
            email,
            firstName,
            lastName,
            studentId,
        });

        return result.data;
    }

    public async removeAllyAccess(
        studentId: number,
        allyEmail: string
    ): Promise<{
        message: string;
    }> {
        const result = await this.http.post("/v1/auth/revoke_ally_access", {
            studentId,
            allyEmail,
        });

        return result.data;
    }

    //get the list of account access challenges
    public async getAccountAccessChallenges(studentId: number): Promise<AccountAccessResponse[]> {
        const result = await this.http.post("/v1/auth/account_access", { studentId });

        return result.data;
    }

    // send ally otp
    public async allySendOtp(email: string, challenge: string): Promise<OtpLoginResponse> {
        const result = await this.http.post("/v1/auth/ally_send_otp", { email, challenge });

        return result.data;
    }

    // verify ally otp
    public async allyConfirmOtp(
        email: string,
        onboardChallenge: string,
        otp: string
    ): Promise<any> {
        const result = await this.http.post("/v1/auth/ally_confirm_otp", {
            email,
            studentChallenge: onboardChallenge,
            allyChallenge: otp,
        });

        return result.data;
    }

    public async selfServiceSendInvite(schoolStudentId: string): Promise<{ message: string }> {
        const result = await this.http.post("/v1/auth/self_service_send_invite", {
            schoolStudentId,
            hostname: window.location.hostname,
        });

        return result.data;
    }

    public async getSsoRequestUrl(schoolId: number): Promise<{ ssoUrl: string }> {
        const result = await this.http.get(`/v1/auth/sso/school/${schoolId}`);

        return result.data;
    }

    // Removes the admin ally access
    public async removeAdminAlly(studentId: number): Promise<{ message: string }> {
        const result = await this.http.delete(`/v1/auth/user_student/student/${studentId}`);

        return result.data;
    }
}

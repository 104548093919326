import { SvgIcon, SvgIconProps } from "@mui/material";

export const XIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 2L2 14M2 2L14 14"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

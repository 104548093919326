import { useContext, useEffect, useState } from "react";
import MeadowContainer from "../container";
import LoadingDialog from "../modal/LoadingDialog";
import { AuthService } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../utils/setUserData";
import { UserContext } from "../../context/UserContext";
import * as Sentry from "@sentry/react";

export default function SSOCallback() {
    const [authService] = useState(() => new AuthService());
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);

    useEffect(() => {
        async function ssoLogin() {
            try {
                // the token is stored in localstorage in a meadowHttpFactory.tsx responseInterceptor
                const { token } = await authService.ssoLogin();
                const userData = await setUserData();
                setUser(userData);

                if (token) {
                    navigate("/billing");
                } else {
                    throw new Error("No token returned from SSO login");
                }
            } catch (err) {
                Sentry.captureException(err);
                navigate("/?ssoError=true");
            }
        }

        ssoLogin();
    }, []);

    return <MeadowContainer>{<LoadingDialog open={true} />}</MeadowContainer>;
}

import { Box, Typography } from "@mui/material";
import { FC, useContext, useEffect } from "react";
import { GREEN_700, GREEN_400 } from "../../theme/colors";
import success from "../../assets/success.svg";

import MeadowContainer from "../container";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const SelfServiceSuccess: FC = () => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/billing");
        }
    }, []);

    return (
        <MeadowContainer
            fullWidth
            hideHeader
            sx={{
                backgroundColor: GREEN_700,
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    maxWidth: "24rem",
                    gap: "1.25rem",
                }}
            >
                <Box>
                    <img src={success} />
                    <Typography
                        sx={{
                            color: GREEN_400,
                            fontSiz: "1.75rem",
                            fontWeight: 500,
                            lineHeight: "2.25rem",
                        }}
                        variant="h1"
                    >
                        Your account access invite has been sent!
                    </Typography>

                    <Typography
                        sx={{
                            color: GREEN_400,
                            fontSize: "1rem",
                            fontWeight: 400,
                            lineHeight: "1.5rem",
                        }}
                    >
                        Please check your school email address to accept the invitation and sign
                        into your account.
                    </Typography>
                </Box>
            </Box>
        </MeadowContainer>
    );
};

export default SelfServiceSuccess;

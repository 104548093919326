import BaseBanner from "./Base";
import { RED_200, RED_700 } from "../../../theme/colors";
import { OverdueInfoIcon } from "../../icons/OverdueInfoIcon";

const BillOverdueBanner: React.FC = () => {
    return (
        <BaseBanner
            color={RED_700}
            backgroundColor={RED_200}
            message="You have a past due balance. Please make a payment."
            Icon={OverdueInfoIcon}
        />
    );
};

export default BillOverdueBanner;

import { Box, Alert, Typography, Button, SxProps, Theme } from "@mui/material";
import { CloseIcon } from "../../icons/CloseIcon";
import useBreakpoint from "../../../hooks/useBreakpoint";
import React, { useState } from "react";

interface Props {
    message: string;
    color: string;
    backgroundColor: string;
    Icon: React.ComponentType<{ sx?: SxProps<Theme> }>;
}

/**
 * Base status banner for various billing status alerts.
 */
const BaseBanner: React.FC<Props> = ({ message, color, backgroundColor, Icon }: Props) => {
    const { isMobile } = useBreakpoint();
    const [show, setShow] = useState<boolean>(true);
    return (
        <Box
            sx={{
                display: "flex",
            }}
        >
            <Alert
                sx={{
                    backgroundColor,
                    borderRadius: "0.5rem",
                    padding: "1rem",
                    width: isMobile ? "100%" : "50.5rem",
                    fontWeight: 500,
                    lineHeight: "1,25rem",
                    fontSize: "0.875rem",
                    alignItems: isMobile ? "flex-start" : "center",
                    justifyContent: "ceter",
                    display: show ? "auto" : "none",
                    flexDirection: "row",
                    color,
                    gap: "0.75rem",
                    marginBottom: "1rem",
                    "& .MuiAlert-icon": {
                        marginRight: "0",
                        paddingBottom: "0",
                    },
                    "& .MuiAlert-message": {
                        padding: 0,
                        width: "100%",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 16 },
                }}
                icon={<Icon sx={{ color, height: "20px", width: "20px" }} />}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: isMobile ? "flex-start" : "center",
                        justifyContent: "space-between",
                        gap: "0.75rem",
                    }}
                >
                    <Typography sx={{ color }}>{message}</Typography>
                    <Button
                        sx={{
                            minWidth: "1rem",
                            width: "1rem",
                            padding: "7px 0",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                        onClick={() => setShow(false)}
                    >
                        <CloseIcon sx={{ color, height: "1rem", width: "1rem", fill: color }} />
                    </Button>
                </Box>
            </Alert>
        </Box>
    );
};

export default BaseBanner;

import BaseBanner from "./Base";
import { BLUE_100, BLUE_700 } from "../../../theme/colors";
import { PendingInfoIcon } from "../../icons/PendingInfoIcon";

const BillPendingBanner: React.FC = () => {
    return (
        <BaseBanner
            color={BLUE_700}
            backgroundColor={BLUE_100}
            message="Your bill is being prepared. We'll notify you when it's ready."
            Icon={PendingInfoIcon}
        />
    );
};

export default BillPendingBanner;

import axios, { AxiosResponse } from "axios";
import { makeAuthRequestBodyInterceptor } from "./makeAuthRequestBodyInterceptor";
import { makeAuthResponseErrorInterceptor } from "./makeAuthResponseErrorInterceptor";
import { makeMeadowRequestIdRequestBodyInterceptor } from "./makeMeadowRequestIdRequestBodyInterceptor";
import { sessionStorageSet } from "./utilities";
import { makeNetworkErrorInterceptor } from "./makeNetworkErrorInterceptor";

const DEFAULT_HEADERS = "application/json";
const URL_CONFIG = {
    LOCALHOST: {
        hostname: "localhost",
        apiBaseUrl: "http://localhost:3002",
    },
    AMPLIFY: {
        hostname: "amplifyapp.com",
        apiBaseUrl: "https://develop.meadowpay.com",
    },
};

export interface IMeadowHttpFactory {
    get: (url: string, config?: any) => Promise<AxiosResponse>;
    post: (url: string, data?: any, config?: any) => Promise<AxiosResponse>;
    put: (url: string, data?: any, config?: any) => Promise<AxiosResponse>;
    delete: (url: string, config?: any) => Promise<AxiosResponse>;
}
/**
 * @function meadowHttpFactory
 * @param {Object} options - options for creating the http client
 * @param {string} [options.baseURL='/api'] - The base url for pay-backend.
 * @param {string} options.username - The username for the http client's basic auth.
 * @param {string} options.password - The password for the http client's basic auth.
 * @returns {Object} - An object containing several methods for making HTTP requests with different headers.
 * @property {function} get - axios http get method with 'application/vnd.api+json' header.
 * @property {function} post - axios http post method with 'application/vnd.api+json' header.
 * @property {function} put - axios http put method with 'application/vnd.api+json' header.
 * @property {function} delete - axios http delete method with 'application/vnd.api+json' header.
 */

export const meadowHttpFactory = ({ baseURL = import.meta.env.VITE_SERVER_TARGET }) => {
    let url = baseURL;
    /**
     * This mapping determines what API endpoint to use based on the
     * hostname of the frontend app.
     *
     * All hostnames (except localhost and amplify) should be using the
     * `api.` subdomain for pay-backend.
     */

    /**
     * We can update `localhost` to use the same `api.` subdomain logic for
     * the backend as outlined in this ticket.
     * https://meadowfi.atlassian.net/browse/MC-1550
     */
    if (window.location.hostname === URL_CONFIG.LOCALHOST.hostname) {
        url = URL_CONFIG.LOCALHOST.apiBaseUrl;
        /**
         * Because amplify generates dynamic subdomains, we have to also call out amplify
         * as a separate case. We need to do some research into how to handle this case
         * because just adding `api.` to the front of the hostname won't work. The
         * hostname is different for every PR we create in pay-frontend.
         */
    } else if (window.location.hostname.includes(URL_CONFIG.AMPLIFY.hostname)) {
        url = URL_CONFIG.AMPLIFY.apiBaseUrl;
    } else {
        url = `https://api.${window.location.hostname}`;
    }

    const http = axios.create({
        baseURL: url,
        headers: {
            "Content-Type": DEFAULT_HEADERS,
        },
        withCredentials: true,
    });

    function responseInterceptor(response: AxiosResponse) {
        if (response.data.token) {
            const token = response.data.token;
            sessionStorageSet("authToken", token);
        }
        return response;
    }

    http.interceptors.request.use(makeAuthRequestBodyInterceptor());
    http.interceptors.request.use(makeMeadowRequestIdRequestBodyInterceptor());
    http.interceptors.response.use(responseInterceptor, makeAuthResponseErrorInterceptor());
    http.interceptors.response.use(undefined, makeNetworkErrorInterceptor());
    return {
        get: http.get,
        post: http.post,
        put: http.put,
        delete: http.delete,
    };
};

import { useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
        });
    }, [[pathname]]);

    return <Outlet />;
};

export default ScrollToTop;

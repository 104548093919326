import { SvgIcon, SvgIconProps } from "@mui/material";
export const UserIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.90242 20.141C5.64428 18.9239 6.68672 17.918 7.92954 17.2201C9.17238 16.5221 10.5738 16.1555 11.9992 16.1555C13.4246 16.1555 14.8261 16.5221 16.0689 17.2201C17.3117 17.918 18.3542 18.9239 19.096 20.141M11.9992 22.8002C17.9639 22.8002 22.7992 17.9649 22.7992 12.0002C22.7992 6.03552 17.9639 1.2002 11.9992 1.2002C6.03455 1.2002 1.19922 6.03552 1.19922 12.0002C1.19922 17.9649 6.03455 22.8002 11.9992 22.8002ZM11.9992 13.6634C14.2936 13.6634 16.1536 11.8034 16.1536 9.50899C16.1536 7.21459 14.2936 5.35459 11.9992 5.35459C9.70482 5.35459 7.84482 7.21459 7.84482 9.50899C7.84482 11.8034 9.70482 13.6634 11.9992 13.6634Z"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke="#5B616E"
            />
        </svg>
    </SvgIcon>
);

import * as Sentry from "@sentry/react";

import { useState, FC } from "react";
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { AuthService } from "../../../services/AuthService";

import { AccountAccessResponse } from "../Student.model";

interface Props {
    access: AccountAccessResponse;
    studentId: number;
    isModalOpen: boolean;
    setIsModalOpen: (value: React.SetStateAction<boolean>) => void;
    onRevokeAccess: () => Promise<void>;
    setRevokeAccessError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const RevokeAllyAccessModal: FC<Props> = ({
    access,
    studentId,
    isModalOpen,
    setIsModalOpen,
    onRevokeAccess,
    setRevokeAccessError,
}) => {
    const [authService] = useState(() => new AuthService());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const buttonWrapperSx = {
        padding: "0.5rem 0",
    };

    const confirmRemoveAccess = async () => {
        try {
            setIsLoading(true);
            await authService.removeAllyAccess(studentId, access.email);
            await onRevokeAccess();
            setIsLoading(false);
            setIsModalOpen(false);
        } catch (error) {
            Sentry.captureException(error);
            setRevokeAccessError("Removing access failed. Please try again later.");
            setIsLoading(false);
            setIsModalOpen(false);
        }
    };
    return (
        <Dialog
            open={isModalOpen}
            onClose={() => {
                setIsModalOpen(false);
            }}
            sx={{
                textAlign: "center",
                maxWidth: "21rem",
                margin: "auto",
            }}
            PaperProps={{
                sx: { borderRadius: "0.5rem" },
            }}
        >
            <DialogTitle>Remove access</DialogTitle>
            <DialogContent>
                <Typography variant="body2" component="span">
                    Are you sure you want to remove access for{" "}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        fontWeight: 500,
                    }}
                    component="span"
                >
                    {access.email}
                </Typography>
                <Typography variant="body2" component="span">
                    ?
                </Typography>
                <Box sx={buttonWrapperSx}>
                    <Button
                        variant="primary"
                        disabled={isLoading}
                        onClick={() => {
                            confirmRemoveAccess();
                        }}
                    >
                        Yes, remove
                    </Button>
                </Box>
                <Box sx={buttonWrapperSx}>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                        disabled={isLoading}
                    >
                        No
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default RevokeAllyAccessModal;

import { Box, Divider, Typography } from "@mui/material";
import { GREY_100, GREY_500 } from "../../../theme/colors";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { useContext } from "react";
import { PaymentContext } from "../../../providers/PaymentsProvider";

export const BillingDetailsFooter = () => {
    const { isMobile } = useBreakpoint();
    const { getStudentSchool } = useContext(PaymentContext);

    const school = getStudentSchool();
    const billingDetailsNotice = school?.billingDetailsNotice;

    if (!billingDetailsNotice) {
        return <></>;
    }

    return (
        <Box
            sx={{
                border: isMobile ? "none" : `1px solid ${GREY_100}`,
            }}
        >
            {isMobile ? <Divider sx={{ borderColor: GREY_100 }} /> : <></>}
            <Box sx={{ padding: "1rem" }}>
                <Typography
                    variant="body2"
                    sx={{
                        color: GREY_500,
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        lineHeight: "1.25rem",
                    }}
                >
                    {billingDetailsNotice}
                </Typography>
            </Box>
        </Box>
    );
};

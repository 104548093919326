import { SvgIcon, SvgIconProps } from "@mui/material";

export const BankIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.59922 9.6V18M8.39922 9.6V18M15.5992 9.6V18M20.3992 9.6V18M21.82 9.6H2.17842C1.98891 9.62483 1.79656 9.58522 1.63228 9.48753C1.468 9.38985 1.34134 9.23976 1.27265 9.0614C1.20397 8.88304 1.19725 8.68677 1.25357 8.50413C1.3099 8.32149 1.426 8.16309 1.58322 8.0544L11.404 1.38C11.5814 1.26073 11.7903 1.19702 12.004 1.19702C12.2178 1.19702 12.4267 1.26073 12.604 1.38L22.4152 8.0544C22.5724 8.16309 22.6885 8.32149 22.7449 8.50413C22.8012 8.68677 22.7945 8.88304 22.7258 9.0614C22.6571 9.23976 22.5304 9.38985 22.3662 9.48753C22.2019 9.58522 22.0095 9.62483 21.82 9.6ZM2.39922 18H21.5992C22.262 18 22.7992 18.5373 22.7992 19.2V21.6C22.7992 22.2627 22.262 22.8 21.5992 22.8H2.39922C1.73648 22.8 1.19922 22.2627 1.19922 21.6V19.2C1.19922 18.5373 1.73648 18 2.39922 18Z"
                stroke="black"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
);

import { Box, Button, Card, Typography } from "@mui/material";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { useContext, useEffect, useState } from "react";
import { verifyAccessControls } from "../utilities";
import { UserContext } from "../../../context/UserContext";
import SecurePayment from "../../payment/SecurePayment";
import {
    contentWrapperSx,
    makeAPaymentButtonSx,
    securePaymentSx,
    nameSx,
    cardSx,
    accountNumberSx,
    boldSx,
    gridSx,
    wrapperSx,
} from "./disabledStudentCardStyles";
import { PaymentContext } from "../../../providers/PaymentsProvider";
import { useNavigate } from "react-router-dom";
import { Logging } from "../../../services/Logging";
import { MeadowButton } from "../../form/MeadowButton";
import {
    adminAccountRowWrapperSx,
    adminRemoveAllyButtonSx,
    removeBoxSx,
} from "../student-card/styles";
import trash from "../../../assets/trash.svg";
import ConfirmRemoveAccountModal from "../student-card/ConfirmRemoveAccountModal";

interface DisabledStudentCardProps {
    student: PaymentsStudent;
}
export default function DisabledStudentCard({ student }: DisabledStudentCardProps) {
    const [isRemoveAccountModalOpen, setIsRemoveAccountModalOpen] = useState(false);
    const { user } = useContext(UserContext);
    const { isMobile } = useBreakpoint();
    const { setCurrentStudentId } = useContext(PaymentContext);
    const navigate = useNavigate();

    useEffect(() => {
        Logging.trackStudentAccountImpression(student);
    }, []);

    const handleMakeApayment = (student: PaymentsStudent) => {
        const utmString = "utm_source=billing-overview-disabled";

        setCurrentStudentId(student.studentId);
        navigate(`/payment/amount?${utmString}`);
    };

    return (
        <Box sx={wrapperSx(isMobile)}>
            <Box sx={gridSx}>
                <Card sx={cardSx}>
                    <Box sx={adminAccountRowWrapperSx}>
                        <Typography sx={nameSx}>
                            {student.firstName} {student.lastName}
                        </Typography>
                        {user?.isAdmin && (
                            <MeadowButton
                                sx={adminRemoveAllyButtonSx}
                                onClick={() => {
                                    setIsRemoveAccountModalOpen(true);
                                }}
                            >
                                <Box data-testid="remove-ally-button" sx={removeBoxSx}>
                                    <img src={trash} alt="delete" />
                                    <Typography>Remove</Typography>
                                </Box>
                            </MeadowButton>
                        )}
                    </Box>
                    <Typography sx={accountNumberSx}>
                        Student account: <span style={boldSx}>{student.accountNumber}</span>
                    </Typography>
                    <Box sx={contentWrapperSx}>
                        <Button
                            sx={makeAPaymentButtonSx(isMobile)}
                            data-testid="make-a-payment"
                            variant="primary"
                            onClick={() => handleMakeApayment(student)}
                        >
                            {"Make a payment"}
                        </Button>
                        <SecurePayment sx={securePaymentSx(isMobile)} />
                    </Box>
                </Card>
                {user && verifyAccessControls(user, student)}
            </Box>
            {isRemoveAccountModalOpen && (
                <ConfirmRemoveAccountModal
                    onClose={() => setIsRemoveAccountModalOpen(false)}
                    studentId={student.studentId}
                />
            )}
        </Box>
    );
}

import meadowpaylogo from "../../assets/meadowpay-logo.svg";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";

export default function PoweredByMeadow() {
    return (
        <BottomNavigation
            sx={{
                flex: "1",
                backgroundColor: "transparent",
                alignItems: "end",
            }}
        >
            <BottomNavigationAction
                label=""
                icon={<img alt="Powered by meadow pay" src={meadowpaylogo} />}
            />
        </BottomNavigation>
    );
}

import { Hidden, SxProps } from "@mui/material";
import ContainerDesktop from "./Desktop";
import ContainerMobile from "./Mobile";

export interface MeadowContainerProps {
    children?: JSX.Element | JSX.Element[];
    sx?: SxProps;
    fullWidth?: boolean;
    showSimpleHeader?: boolean;
    simpleHeaderText?: string;
    noPadding?: boolean;
    hideHeader?: boolean;
    lightSimpleHeader?: boolean;
    simpleHeaderOverrideStyles?: SxProps;
}

const MeadowContainer = (props: MeadowContainerProps) => {
    return (
        <>
            <Hidden mdUp initialWidth="md">
                <ContainerMobile {...props} />
            </Hidden>
            <Hidden mdDown initialWidth="md">
                <ContainerDesktop {...props} />
            </Hidden>
        </>
    );
};
export default MeadowContainer;

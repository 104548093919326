import { Box, Button, FormGroup } from "@mui/material";
import MeadowInput from "../form/MeadowInput";
import { FC, FormEvent, useEffect, useState } from "react";
import { ErrorTypes } from "../../enums/errors";
import { GREY_MANATEE } from "../../theme/colors";
import FormHeader from "../form/FormHeader";

interface SignInOTPProps {
    handleSubmit: (code: string) => void;
    error: ErrorTypes | string;
    maskedEmail: string;
    onBackClick: () => void;
    onResend: () => void;
}

/**
 * This component will show when users are prompted to enter
 * their OTP after entering their email on either the
 * SignInEmail or SignInSSO components.
 */
export const SignInOTP: FC<SignInOTPProps> = ({
    handleSubmit,
    onBackClick,
    onResend,
    error,
    maskedEmail,
}) => {
    const [code, setCode] = useState<string>("");
    const [errorType, setErrorType] = useState<ErrorTypes | string>(ErrorTypes.NULL);

    useEffect(() => {
        setErrorType(error);
    }, [error]);

    const submitCode = async (e: FormEvent) => {
        e.preventDefault();

        if (code.length > 0) {
            handleSubmit(code);
        } else {
            setErrorType(ErrorTypes.EMPTY_CODE);
        }
    };

    const resendButtonSX = {
        borderBottom: `1px solid ${GREY_MANATEE}`,
        color: GREY_MANATEE,
        borderRadius: 0,
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1rem",
    };

    return (
        <>
            <FormHeader
                headerText={"Sign in"}
                subText={`Please enter the code that was sent to ${maskedEmail}.`}
            />
            <FormGroup>
                <form>
                    <MeadowInput
                        label="Enter code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        errorType={errorType}
                        isError={errorType.length > 0}
                        inputMode="text"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    />

                    <Button
                        type="submit"
                        variant="primary"
                        disabled={false}
                        sx={{
                            marginTop: "1.75rem",
                        }}
                        onClick={(e) => submitCode(e)}
                    >
                        Sign in
                    </Button>
                </form>
            </FormGroup>
            <Button
                type="submit"
                variant="secondary"
                disabled={false}
                sx={{
                    marginTop: "1.75rem",
                }}
                onClick={() => {
                    setErrorType(ErrorTypes.NULL);
                    setCode("");
                    onBackClick();
                }}
            >
                Back
            </Button>
            <Box sx={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}>
                <Button onClick={onResend} sx={resendButtonSX}>
                    Resend code
                </Button>
            </Box>
        </>
    );
};

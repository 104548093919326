import mixpanel from "mixpanel-browser";
import { AllLoggingEvents, LoggingEvents, PaymentPlanLoggingEvents } from "../enums/logging";
import { PaymentsStudent } from "../reducers/PaymentsReducer";

type Dict = {
    [key: string]: any;
};
export class Logging {
    private static mixpanel() {
        if (import.meta.env.VITE_MIXPANEL_DISABLED === "true") {
            // returns a dummy mixpanel object to avoid testing issues
            return {
                init: () => {},
                reset: () => {},
                track: () => {},
                track_pageview: () => {},
                track_with_groups: () => {},
                time_event: () => {},
                identify: () => {},
                alias: () => {},
                register: () => {},
                get_distinct_id: () => "",
                people: { increment: () => {}, set: () => {}, union: () => {}, set_once: () => {} },
            };
        }

        return mixpanel;
    }

    public static getDistinctId() {
        return this.mixpanel().get_distinct_id();
    }

    public static init() {
        this.mixpanel().init(import.meta.env.VITE_MIXPANEL_TOKEN, {
            debug: import.meta.env.DEV,
            track_pageview: false,
            persistence: "localStorage",
        });
    }

    // calling this method from another method for a specific event is preferred to calling this outside of this class.
    public static track(eventName: AllLoggingEvents, props: Dict) {
        this.mixpanel().track(eventName, props);
    }

    // calling this method from another method for a specific event is preferred to calling this outside of this class.
    public static timeEvent(eventName: LoggingEvents) {
        this.mixpanel().time_event(eventName);
    }

    public static trackPageview(props?: Dict) {
        this.mixpanel().track_pageview(props);
    }

    public static identify(userId: number, isAuthEvent?: boolean) {
        this.mixpanel().identify(`${userId}`);
        const nowISO = new Date().toISOString();
        // as method name indicates, this is only set once, but is fine to call on each log in.
        this.mixpanel().people.set_once({ "First Login": nowISO });
        // only want to increment logins and set this value if this was an auth event, not
        // if we're calling this from a returning authenticated user.
        if (isAuthEvent) {
            this.mixpanel().people.set({
                "Last Login": nowISO,
            });
            this.mixpanel().people.increment("Logins", 1);
        }
    }

    public static login(userId: number) {
        this.mixpanel().track(LoggingEvents.LOGIN, { userId: `${userId}` });
    }

    public static reset() {
        this.mixpanel().reset();
    }

    public static signup() {
        this.mixpanel().track(LoggingEvents.SIGN_UP);
    }

    public static logout() {
        this.mixpanel().track(LoggingEvents.LOG_OUT);
    }

    public static authTokenExpired() {
        this.mixpanel().track(LoggingEvents.AUTH_TOKEN_EXPIRED);
        this.mixpanel().reset();
    }

    public static contactUs() {
        this.mixpanel().track(LoggingEvents.CONTACT_US);
    }

    public static help() {
        this.mixpanel().track(LoggingEvents.HELP);
    }

    public static viewBill(student: PaymentsStudent, schoolId: number) {
        this.mixpanel().track_with_groups(
            LoggingEvents.VIEW_BILL,
            {
                billing_account_id: student.accountNumber,
                "Billing Summary Status": student.status,
                "Next Payment Amount": student.nextPaymentAmount,
                "Next Payment Due Date": student.nextPaymentDueDate,
                "Overdue Payment Amount": student.overduePaymentAmount,
                "Overdue Payment Due Date": student.overduePaymentDueDate,
            },
            {
                student_id: student.studentId,
                school_id: schoolId,
            }
        );
    }

    // payment plans
    public static trackPaymentPlanCardShown(studentId: number) {
        this.mixpanel().track_with_groups(
            PaymentPlanLoggingEvents.PAYMENT_PLAN_CARD_SHOWN,
            {
                student_id: studentId,
            },
            {}
        );
    }

    public static trackStudentAccountImpression(student: PaymentsStudent) {
        this.mixpanel().track_with_groups(
            LoggingEvents.STUDENT_ACCOUNT_IMPRESSION,
            {
                billingAccountStatus: student.status,
            },
            {}
        );
    }
}

import { BLACK_000, GREY_100 } from "../../../theme/colors";

export const detailsTitleWrapperSx = {
    padding: "0.75rem 1rem",
    display: "flex",
    justifyContent: "space-between",
};
export const subcategorySx = {
    fontWeight: 400,
    fontSize: "0/875rem",
    lineHeight: "1.25rem",
    color: BLACK_000,
};
export const categoryTitleSx = {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: BLACK_000,
    display: "flex",
};

export const subcategoryWrapperSx = {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.75rem 1rem",
    borderTop: `1px solid ${GREY_100}`,
    cursor: "pointer",
};

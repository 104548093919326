import { Box, CircularProgress } from "@mui/material";
import { BLUE_200, BLUE_500 } from "../../theme/colors";

export default function LoadingIcon() {
    return (
        <Box sx={{ position: "relative" }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    position: "absolute",
                    color: BLUE_200,
                }}
                size={40}
                thickness={4}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: BLUE_500,
                    animationDuration: "550ms",
                }}
                size={40}
                thickness={4}
            />
        </Box>
    );
}

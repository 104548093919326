import { randomId, sessionStorageGet } from "./utilities";
import * as Sentry from "@sentry/react";

export const makeMeadowRequestIdRequestBodyInterceptor = () => async (config: any) => {
    const meadowSessionId = sessionStorageGet("meadowSessionId") || "MEADOWID-S0000000000";
    const meadowRequestId = meadowSessionId + "-R" + randomId(15);

    config.headers["x-meadow-request-id"] = meadowRequestId;

    Sentry.addBreadcrumb({
        category: "request",
        message: `Sending request to ${config.url} with Meadow Request ID: ${meadowRequestId}`,
        level: "log",
    });

    return config;
};

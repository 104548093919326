import { Container, Typography } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { MeadowContainerProps } from ".";
import { UserIcon } from "../icons/UserIcon";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import defaultSchoolLogo from "../../assets/defaultSchoolLogo.svg";
import ImageWithFallback from "../utils/ImageWithFallback";
import { HelpIcon } from "../icons/HelpIcon";
import { GREY_500, GREY_800, WHITE_000 } from "../../theme/colors";
import { Logging } from "../../services/Logging";
import { ConfigContext } from "../../context/ConfigContext";
import { BackIcon } from "../icons/BackIcon";
import { AddAccountButton } from "../admin/AddAccountButton";
import { AdminBanner } from "../admin/AdminBanner";

const ContainerDesktop = ({
    children,
    sx = {},
    showSimpleHeader = false,
    simpleHeaderText = "",
    fullWidth,
    noPadding,
    lightSimpleHeader = false,
    simpleHeaderOverrideStyles = {},
}: MeadowContainerProps): JSX.Element => {
    const { user } = useContext(UserContext);
    const { config } = useContext(ConfigContext);

    const navigate = useNavigate();
    const isLoggedIn = !!user;
    const lowerCaseSchoolAbbreviation = config ? config.abbreviation.toLowerCase() : "";
    const containerSX: SxProps = {
        display: "flex",
        maxWidth: fullWidth ? "100%" : "24.375rem",
        flexDirection: "column",
        padding: noPadding ? "0rem" : "2rem",
        flexGrow: 1,
        overflow: "auto",
        ...sx,
    };
    const simpleHeaderSx: SxProps = {
        padding: "1.5rem 0rem",
        backgroundColor: lightSimpleHeader ? WHITE_000 : GREY_800,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "24.5rem",
        ...simpleHeaderOverrideStyles,
    };

    return (
        <>
            <AdminBanner />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: isLoggedIn ? "space-between" : "center",
                    padding: "1rem 2.5rem",
                    alignItems: "center",
                    position: "relative",
                    boxShadow:
                        "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
                }}
            >
                <Link to={isLoggedIn ? "/billing" : "/"}>
                    {config && (
                        <ImageWithFallback
                            alt="Desktop logo"
                            src={config.logoURL}
                            fallback={defaultSchoolLogo}
                            height={44}
                        />
                    )}
                </Link>
                <Box sx={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                    {user?.isAdmin ? <AddAccountButton /> : <></>}
                    <a
                        href={`https://help.meadowpay.com/${lowerCaseSchoolAbbreviation}`}
                        onClick={() => Logging.help()}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            color: GREY_500,
                        }}
                    >
                        <Box
                            sx={{
                                display: isLoggedIn ? "flex" : "none",
                                gap: "0.25rem",
                                color: GREY_500,
                                cursor: "pointer",
                            }}
                        >
                            <HelpIcon sx={{ cursor: "pointer" }} />
                            Help
                        </Box>
                    </a>
                    <Box sx={{ display: isLoggedIn ? "flex" : "none" }}>
                        <UserIcon sx={{ cursor: "pointer" }} onClick={() => navigate("/account")} />
                    </Box>
                </Box>
            </Box>
            <Container maxWidth={false} sx={containerSX}>
                {showSimpleHeader && (
                    <Box sx={simpleHeaderSx}>
                        <BackIcon
                            data-testid="back-icon-desktop"
                            sx={{
                                "&:hover": {
                                    cursor: "pointer",
                                },
                            }}
                            onClick={() => navigate(-1)}
                        />
                        <Typography variant="h1" sx={{ margin: 0 }}>
                            {simpleHeaderText}
                        </Typography>
                    </Box>
                )}
                {children}
            </Container>
        </>
    );
};

export default ContainerDesktop;

import * as React from "react";
import { NumericFormat, NumberFormatValues, NumericFormatProps } from "react-number-format";

interface CustomProps {
    onChange: (event: { target: { value: NumberFormatValues } }) => void;
    enableDecimalFormatting: boolean;
}

export const NumericFormatter = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, enableDecimalFormatting, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                decimalScale={2}
                fixedDecimalScale={enableDecimalFormatting}
                allowNegative={false}
            />
        );
    }
);

import { SvgIcon, SvgIconProps } from "@mui/material";

export const AddIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 12 12">
            <g clipPath="url(#clip0_5215_33682)">
                <path
                    d="M6 0.462891V11.6057"
                    stroke="currentColor"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M0.427734 6H11.5706"
                    stroke="currentColor"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5215_33682">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

import { Box, List, ListItem, Card, Typography } from "@mui/material";
import { BillingTerm } from "../Billing.model";
import { formatter } from "../utilities";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { Category } from "./Category";
import { categoryTitleSx } from "./styles";

interface DetailProps {
    detail: BillingTerm;
}
export const Detail = ({ detail }: DetailProps) => {
    const { isMobile } = useBreakpoint();
    const cardSx = {
        marginBottom: "1rem",
        width: isMobile ? "100%" : "50.5rem",
        boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        borderRadius: "0.5rem",
    };

    return (
        <Box key={detail.id}>
            <List sx={{ padding: 0 }}>
                <ListItem
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0",
                    }}
                >
                    <h2
                        style={{
                            width: "100%",
                            fontWeight: 500,
                            fontSize: "1.75rem",
                            lineHeight: "2.25rem",
                            margin: "1.5rem 0",
                        }}
                    >
                        {detail.title}{" "}
                    </h2>
                    {detail.categories.map((category, index) => {
                        return <Category category={category} key={index} />;
                    })}
                    <Card sx={cardSx}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0.75rem 1rem",
                            }}
                        >
                            <Typography sx={categoryTitleSx}>Total for {detail.title}</Typography>
                            <Typography sx={categoryTitleSx}>
                                {formatter.format(detail.total)}
                            </Typography>
                        </Box>
                    </Card>
                </ListItem>
            </List>
        </Box>
    );
};

import { Box, Button, Divider, FormGroup, Typography } from "@mui/material";
import MeadowInput from "../form/MeadowInput";
import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { ErrorTypes } from "../../enums/errors";
import useBreakpoint from "../../hooks/useBreakpoint";
import { GREY_PLACEHOLDER, RED_500 } from "../../theme/colors";
import { Message } from "./Message";
import { ContactLink } from "./ContactLink";
import { ConfigContext } from "../../context/ConfigContext";

interface SignInSSOProps {
    handleSubmit: (email: string) => void;
    onSsoClick: () => void;
    error: ErrorTypes | string;
    hasSsoError: boolean;
    isLoading: boolean;
    clearError?: () => void;
}

/**
 * This component only shows for users who are at schools with SSO enabled.
 *
 * However, if the user gets to this page from clicking "Sign in" on the welcome
 * screen, they will not see this component and instead will see the SignInEmail
 * component.This is because there is an SSO sign in option on the welcome
 * screen, and if they click "Sign in" from that screen we don't need to show
 * them the option to Sign In with SSO again.
 */
export const SignInSSO: FC<SignInSSOProps> = ({
    handleSubmit,
    onSsoClick,
    error,
    hasSsoError,
    isLoading,
    clearError = () => {},
}) => {
    const { isMobile } = useBreakpoint();
    const { config } = useContext(ConfigContext);

    const [emailValue, setEmailValue] = useState<string>("");
    const [errorType, setErrorType] = useState<ErrorTypes | string>(ErrorTypes.NULL);
    const buttonColor = config?.ssoButtonColor;
    const buttonLabelColor = config?.ssoButtonLabelColor;
    const ssoButtonLabel = config?.ssoButtonLabel;
    useEffect(() => {
        setErrorType(error);
    }, [error]);

    const submitEmail = async (e: FormEvent) => {
        e.preventDefault();
        handleSubmit(emailValue);
    };

    const desktopHeaderSX = {
        textAlign: "center",
        paddingTop: "2.5rem",
        paddingBottom: "0.5rem",
    };

    const mobileHeaderSX = {
        textAlign: "center",
    };

    const dividerSX = {
        width: "100%",
        color: GREY_PLACEHOLDER,
        margin: ".5rem auto",
    };

    const mediumWeight = {
        fontWeight: "500",
    };

    return (
        <>
            <Message icon="info" clearError={clearError} showMessage={hasSsoError} color="red">
                Something went wrong. Please try again, sign in with email, or{" "}
                <ContactLink textColor={RED_500} /> .
            </Message>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={isMobile ? mobileHeaderSX : desktopHeaderSX} variant="h1">
                    Sign in
                </Typography>
            </Box>
            <Button
                type="submit"
                variant="primary"
                disabled={isLoading}
                sx={{
                    marginTop: "1.75rem",
                    marginBottom: "1.75rem",
                    color: buttonLabelColor,
                    backgroundColor: buttonColor,
                    "&:hover": {
                        backgroundColor: buttonColor,
                        opacity: "0.8",
                    },
                }}
                onClick={onSsoClick}
            >
                {ssoButtonLabel || "Sign in with my student login"}
            </Button>
            <Divider sx={dividerSX}>OR</Divider>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ paddingTop: "1rem", paddingBottom: "2.5rem" }} variant="body1">
                    <span style={mediumWeight}>
                        If you are a former student, parent, guardian or relative,
                    </span>{" "}
                    enter your email to receive a one-time verification code.
                </Typography>
            </Box>
            <FormGroup>
                <form>
                    <MeadowInput
                        label="Email"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: 0,
                        }}
                        onChange={(e) => setEmailValue(e.target.value)}
                        value={emailValue}
                        errorType={errorType}
                        isError={errorType.length > 0}
                        inputMode="email"
                        autoComplete={"email"}
                        autoFocus={false}
                    />

                    <Button
                        type="submit"
                        variant="secondary"
                        disabled={false}
                        sx={{
                            marginTop: "1.75rem",
                            marginBottom: "1.75rem",
                        }}
                        onClick={(e) => submitEmail(e)}
                    >
                        Send code
                    </Button>
                </form>
            </FormGroup>
        </>
    );
};

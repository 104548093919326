import { Alert, Typography } from "@mui/material";
import { ACCOUNT_ACCESS_STATUS } from "../../../constants";
import { AccountAccessResponse } from "../Student.model";
import { BLACK_000, BLUE_100, BLUE_700, RED_200, RED_700 } from "../../../theme/colors";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { InfoIcon } from "../../icons/InfoIcon";
interface AccountAccessStatusProps {
    accountAccess: AccountAccessResponse;
}
export const AccountAccessStatus = ({ accountAccess }: AccountAccessStatusProps) => {
    return (
        <>
            {accountAccess.status === ACCOUNT_ACCESS_STATUS.ACCEPTED && (
                <Typography
                    sx={{
                        fontWeight: 400,
                        color: BLACK_000,
                        fontSize: "1rem",
                        lineHeight: "1.5rem",
                    }}
                >
                    {accountAccess.dateAccepted
                        ? new Date(accountAccess.dateAccepted).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                          })
                        : ""}
                </Typography>
            )}
            {accountAccess.status === ACCOUNT_ACCESS_STATUS.PENDING && (
                <Alert
                    sx={{
                        backgroundColor: BLUE_100,
                        borderRadius: "0.5rem",
                        padding: "0.25rem 0.5rem",
                        height: "1.5rem",
                        width: "fit-content",
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        lineHeight: "1rem",
                        alignItems: "center",
                        color: BLUE_700,
                        "& .MuiAlert-icon": {
                            marginRight: "4px",
                        },
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    icon={<AccessTimeIcon sx={{ color: BLUE_700 }} />}
                >
                    Pending acceptance
                </Alert>
            )}
            {accountAccess.status === ACCOUNT_ACCESS_STATUS.EXPIRED && (
                <Alert
                    sx={{
                        backgroundColor: RED_200,
                        borderRadius: "0.5rem",
                        padding: "0.25rem 0.5rem",
                        height: "1.5rem",
                        width: "fit-content",
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        lineHeight: "1rem",
                        alignItems: "center",
                        marginTop: "0.5rem",
                        color: RED_700,
                        "& .MuiAlert-icon": {
                            marginRight: "4px",
                        },
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                    }}
                    icon={<InfoIcon fill={RED_700} />}
                >
                    Invite expired
                </Alert>
            )}
        </>
    );
};

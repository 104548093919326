import { Box, SxProps } from "@mui/material";
import { BLUE_500, GREY_500 } from "../../theme/colors";
import lockIcon from "../../assets/lock_icon.svg";

interface SecurePaymentProps {
    sx?: SxProps;
}
const SecurePayment = ({ sx }: SecurePaymentProps) => {
    const boxSx: SxProps = {
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
        color: GREY_500,
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        marginTop: "0.75rem",
        ...sx,
    };

    return (
        <Box sx={boxSx}>
            <img src={lockIcon} style={{ color: BLUE_500, height: "1rem", width: "1rem" }} /> Secure
            payment
        </Box>
    );
};

export default SecurePayment;

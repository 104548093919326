import * as Sentry from "@sentry/react";
import MeadowContainer from "./components/container";
import { Box } from "@mui/material";
import { BLUE_100 } from "./theme/colors";
import PoweredByMeadow from "./components/footer/PoweredByMeadow";
import { useContext, useEffect, useState } from "react";
import Welcome from "./components/account/Welcome";
import { ConfigContext } from "./context/ConfigContext";
import WelcomeSSO from "./components/account/WelcomeSSO";
import useBreakpoint from "./hooks/useBreakpoint";
import { Message } from "./components/account/Message";
import { AuthService } from "./services/AuthService";
import LoadingDialog from "./components/modal/LoadingDialog";

export default function Home() {
    const { isMobile } = useBreakpoint();
    const wasLoggedOut = new URLSearchParams(location.search).get("logout") == "true";
    const wasExpired = new URLSearchParams(location.search).get("expired") == "true";
    const identifierError = new URLSearchParams(location.search).get("linkError") == "true";
    const ssoError = new URLSearchParams(location.search).get("ssoError") == "true";
    const isSsoLogin = new URLSearchParams(location.search).get("sso") == "true";

    const [isLoadingSso, setIsLoadingSso] = useState(false);
    const [authService] = useState(() => new AuthService());

    const { config, isLoading } = useContext(ConfigContext);
    const isSsoEnabled = config ? config.isSsoEnabled : false;

    useEffect(() => {
        async function checkSsoLogin() {
            /**
             * If SSO is enabled at the school and we received the query
             * param `sso=true` than we should attempt to automatically
             * login this user through the school's SSO provider.
             */
            if (config?.id && config?.isSsoEnabled && isSsoLogin) {
                try {
                    setIsLoadingSso(true);
                    const result = await authService.getSsoRequestUrl(config.id);
                    window.location.href = result.ssoUrl;
                } catch (err) {
                    setIsLoadingSso(false);
                    Sentry.captureException(err);
                }
            }
        }

        checkSsoLogin();
    }, [config]);

    const wrapperSX = {
        marginTop: isMobile ? "0rem" : "7rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    };
    const containerSX = {
        backgroundColor: BLUE_100,
        textAlign: "center",
        justifyContent: "center",
        paddingBottom: "1rem",
    };

    return (
        <MeadowContainer fullWidth noPadding sx={containerSX}>
            {isLoading || isLoadingSso ? (
                <LoadingDialog open />
            ) : (
                <>
                    <Box sx={wrapperSX}>
                        <Message icon="check" showMessage={wasLoggedOut} color="green">
                            You&apos;re logged out. Thank you for using Meadow Pay.
                        </Message>
                        <Message icon="info" showMessage={wasExpired} color="red">
                            Your session has expired. Please sign in again.
                        </Message>
                        <Message icon="info" showMessage={identifierError} color="red">
                            The link you provided is invalid. Please contact us at
                            support@meadowfi.com
                        </Message>
                        <Message icon="info" showMessage={ssoError} color="red">
                            We were unable to log you in with your school login. Try another option
                            below.
                        </Message>
                        {isSsoEnabled ? <WelcomeSSO /> : <Welcome />}
                    </Box>
                    <PoweredByMeadow />
                </>
            )}
        </MeadowContainer>
    );
}

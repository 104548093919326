const maskEmail = (email: string) => {
    // protect us from empty or negative length strings
    if (email.length <= 1) {
        return email;
    }
    const split = email.split("@");
    const masked =
        email.substring(0, 1) + new Array(split[0].length - 1).fill("*").join("") + "@" + split[1];

    return masked;
};

export default maskEmail;

import MeadowContainer from "../container";
import { GREEN_400, GREEN_700 } from "../../theme/colors";
import { Box, Button, Typography } from "@mui/material";
import useBreakpoint from "../../hooks/useBreakpoint";
import success from "../../assets/success.svg";
import mail from "../../assets/mail.svg";
import bank from "../../assets/bank.svg";
import { PaymentContext } from "../../providers/PaymentsProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import calendarIconLarge from "../../assets/Icon-Calendar-Large.svg";

export default function PaymentSuccess() {
    const { user } = useContext(UserContext);
    const { getTotalPaid, getStudentSchool, reset, getPaymentPlanEnrollment, getCurrentStudent } =
        useContext(PaymentContext);
    const [isPaymentPlan, setIsPaymentPlan] = useState<boolean>(false);
    const [statementDescriptor, setStatementDescriptor] = useState<string>("");
    const { isMobile } = useBreakpoint();
    const navigate = useNavigate();
    const currentStudent = getCurrentStudent();
    const paymentPlan = getPaymentPlanEnrollment();
    const school = getStudentSchool();
    const totalPaid = getTotalPaid();
    const wrapperSx = {
        maxWidth: isMobile ? "17.5rem" : "unset",
    };

    useEffect(() => {
        if (paymentPlan) {
            setIsPaymentPlan(true);
        }
    }, [paymentPlan]);

    useEffect(() => {
        if (totalPaid && school) {
            setStatementDescriptor(school.statementDescriptor);
            reset();
        } else {
            navigate("/billing");
        }
    }, []);

    return (
        <MeadowContainer
            fullWidth
            hideHeader
            sx={{
                backgroundColor: GREEN_700,
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    maxWidth: "24rem",
                    gap: "1.25rem",
                }}
            >
                <Box>
                    <img src={success} />
                    <Typography sx={{ color: GREEN_400 }} variant="h1">
                        {isPaymentPlan
                            ? "You've successfully set up a payment plan."
                            : "Your payment is on the way."}
                    </Typography>
                </Box>

                <Box sx={wrapperSx}>
                    <img style={{ height: "1.5rem", width: "1.5rem" }} src={mail} />
                    <Typography
                        sx={{
                            color: GREEN_400,
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                        }}
                    >
                        Confirmation email sent to{" "}
                        <span style={{ fontWeight: 500 }}>{user?.email}</span>
                    </Typography>
                </Box>

                <Box sx={wrapperSx}>
                    <img style={{ height: "1.5rem", width: "1.5rem" }} src={bank} />
                    <Typography
                        sx={{
                            color: GREEN_400,
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        The payment will appear as{" "}
                        {
                            <span
                                style={{
                                    color: GREEN_400,
                                    fontSize: "1rem",
                                    lineHeight: "1.5rem",
                                    fontWeight: 500,
                                }}
                            >
                                {statementDescriptor}
                            </span>
                        }{" "}
                        on your statement.
                    </Typography>
                </Box>

                <Box sx={wrapperSx}>
                    <img
                        src={calendarIconLarge}
                        style={{ height: "1.5rem", width: "1.5rem", color: GREEN_400 }}
                    />
                    <Typography
                        sx={{
                            color: GREEN_400,
                            fontWeight: 400,
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                        }}
                    >
                        It may take up to <span style={{ fontWeight: 500 }}>24 hours </span>
                        for the payment to be reflected on your student account.
                    </Typography>
                </Box>

                <Button
                    sx={{
                        marginTop: "1.5rem",
                        marginBottom: "0.5rem",
                        width: "13.125rem",
                    }}
                    onClick={() => {
                        reset();
                        navigate("/billing");
                    }}
                    variant="primary"
                >
                    Back to home
                </Button>
            </Box>
        </MeadowContainer>
    );
}

import { Button, FormGroup } from "@mui/material";
import MeadowInput from "../form/MeadowInput";
import { FC, FormEvent, useEffect, useState } from "react";
import { ErrorTypes } from "../../enums/errors";
import FormHeader from "../form/FormHeader";

interface SignInEmailProps {
    handleSubmit: (email: string) => void;
    error: ErrorTypes | string;
}

/**
 * This component will show for users at schools that
 * do not have SSO enabled or for users who get to this
 * page by clicking "Sign In" on the welcome screen.
 */
export const SignInEmail: FC<SignInEmailProps> = ({ handleSubmit, error }) => {
    const [emailValue, setEmailValue] = useState<string>("");
    const [errorType, setErrorType] = useState<ErrorTypes | string>(ErrorTypes.NULL);

    useEffect(() => {
        setErrorType(error);
    }, [error]);

    const submitEmail = async (e: FormEvent) => {
        e.preventDefault();
        handleSubmit(emailValue);
    };

    return (
        <>
            <FormHeader
                headerText={"Sign in"}
                subText={
                    "Sign in to view, pay and share bills. Enter your email to receive a one-time verification code."
                }
            />
            <FormGroup>
                <form>
                    <MeadowInput
                        label="Email"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: 0,
                        }}
                        onChange={(e) => setEmailValue(e.target.value)}
                        value={emailValue}
                        errorType={errorType}
                        isError={errorType.length > 0}
                        inputMode="email"
                        autoComplete={"email"}
                    />

                    <Button
                        type="submit"
                        variant="primary"
                        disabled={false}
                        sx={{
                            marginTop: "1.75rem",
                        }}
                        onClick={(e) => submitEmail(e)}
                    >
                        Send code
                    </Button>
                </form>
            </FormGroup>
        </>
    );
};

import { useContext, useEffect, useState } from "react";
import { Logging } from "../../services/Logging";
import { ConfigContext } from "../../context/ConfigContext";

export const ContactLink = ({ textColor }: { textColor: string }) => {
    const { config } = useContext(ConfigContext);
    const [abbreviation, setAbbreviation] = useState("");

    useEffect(() => {
        setAbbreviation(config?.abbreviation?.toLowerCase() || "");
    }, [config]);

    return (
        <a
            onClick={() => {
                Logging.contactUs();
            }}
            href={`https://help.meadowpay.com/${abbreviation}`}
            target="_blank"
            rel="noreferrer"
            style={{
                borderBottom: `1px solid ${textColor}`,
                cursor: "pointer",
                textDecoration: "none",
                color: textColor,
            }}
        >
            contact us
        </a>
    );
};

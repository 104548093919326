import { Box, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { GREEN_400, GREEN_700, RED_200, RED_700 } from "../../theme/colors";
import { InfoIcon } from "../icons/InfoIcon";
import { CheckIcon } from "../icons/CheckIcon";
import { XIcon } from "../icons/XIcon";

export const Message = ({
    showMessage,
    children,
    color = "green",
    icon = "check",
    clearError = () => {},
}: {
    showMessage: boolean;
    children: ReactNode | string;
    color: string;
    icon: string;
    clearError?: () => void;
}) => {
    const [shouldShow, setShouldShow] = useState(showMessage);

    useEffect(() => {
        setShouldShow(showMessage);
    }, [showMessage]);

    const onClear = () => {
        setShouldShow(false);
        clearError();
    };

    return (
        <>
            {shouldShow && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        backgroundColor: color === "green" ? GREEN_700 : RED_200,
                        padding: "1rem",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        textAlign: "left",
                        gap: "0.5rem",
                    }}
                >
                    {icon === "check" ? <CheckIcon /> : <InfoIcon />}
                    <Typography
                        variant="toast"
                        sx={{
                            color: color === "green" ? GREEN_400 : RED_700,
                        }}
                    >
                        {children}
                    </Typography>
                    <XIcon
                        sx={{ cursor: "pointer" }}
                        stroke={color === "green" ? GREEN_400 : RED_700}
                        onClick={onClear}
                    />
                </Box>
            )}
        </>
    );
};

import { Box, Typography } from "@mui/material";
import { BillingCategory, BillingSubcategory } from "../Billing.model";
import { formatter } from "../utilities";
import { subcategorySx } from "./styles";
import { GREY_100 } from "../../../theme/colors";
import { useNavigate } from "react-router-dom";

interface SubCategoryProps {
    category: BillingCategory;
    subcategory: BillingSubcategory;
}
export const Subcategory = ({ category, subcategory }: SubCategoryProps) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.75rem 1rem",
                borderTop: `1px solid ${GREY_100}`,
                cursor: "pointer",
            }}
            onClick={() => {
                navigate("/billing/details/charges", {
                    state: { category },
                });
            }}
        >
            <Typography sx={subcategorySx}>{subcategory.title}</Typography>
            <Typography sx={{ ...subcategorySx, whiteSpace: "nowrap" }}>
                {formatter.format(subcategory.total)}
            </Typography>
        </Box>
    );
};

import { useContext } from "react";
import { UserContext } from "../../context/UserContext";

export function getGreeting(): string {
    const { user } = useContext(UserContext);
    const date = new Date();
    const hour = date.getHours();
    let greeting = "Good ";

    if (hour >= 5 && hour < 12) {
        greeting = greeting.concat("morning, ");
    } else if (hour >= 12 && hour < 18) {
        greeting = greeting.concat("afternoon, ");
    } else {
        greeting = greeting.concat("evening, ");
    }
    greeting = greeting + `${user?.userFirstName}`;

    return greeting;
}

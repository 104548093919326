import { createTheme } from "@mui/material";
import {
    BLACK_000,
    GREY_500,
    BLUE_500,
    GREY_400,
    RED_500,
    WHITE_000,
    GREY_200,
    BLUE_700,
    GREY_100,
} from "./colors";
import { PRIMARY_FONT_FAMILY } from "./fonts";

declare module "@mui/material/Button/Button" {
    interface ButtonPropsVariantOverrides {
        primary: true;
        secondary: true;
        tertiary: true;
        checkboxLabel: true;
        toast: true;
    }
}

declare module "@mui/material/styles" {
    interface TypographyVariants {
        checkboxLabel: React.CSSProperties;
        toast: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        checkboxLabel?: React.CSSProperties;
        toast?: React.CSSProperties;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        checkboxLabel: true;
        toast: true;
    }
}

const theme = createTheme({
    // palette: {
    // }
    typography: {
        fontFamily: PRIMARY_FONT_FAMILY,
        h1: {
            fontSize: "1.75rem",
            lineHeight: "2.25rem",
            fontWeight: 500,
            margin: "1rem 0",
        },
        button: {
            textTransform: "none",
            fontWeight: 500,
            fontSize: "1rem",
        },
        body1: {
            color: GREY_500,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            fontWeight: 400,
        },
        body2: {
            color: GREY_500,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            fontWeight: 400,
        },
        checkboxLabel: {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            fontWeight: 500,
            padding: 0,
            color: BLACK_000,
            maxWidth: "17.25rem",
        },
        toast: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            maxWidth: "14.5rem",
        },
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        input: {
                            border: `2px solid ${RED_500}`,
                        },
                    },
                    "&.Mui-focused": {
                        input: {
                            border: `2px solid ${BLUE_500}`,
                        },
                    },
                    paddingBottom: 0,
                },
                input: {
                    border: `2px solid ${GREY_400}`,
                    borderRadius: "0.5rem",
                    padding: "10px 1rem",
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        fontWeight: 400,
                        fontSize: "0.875rem",
                        lineHeight: "1rem",
                        color: RED_500,
                    },
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "primary" },
                    style: {
                        "&:hover": {
                            background: "#5E77DD",
                        },
                        "&:disabled": {
                            background: GREY_100,
                            color: GREY_500,
                        },
                        background: BLUE_500,
                        borderRadius: "3rem",
                        color: WHITE_000,
                        width: "100%",
                        boxShadow:
                            "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
                    },
                },
                {
                    props: { variant: "secondary" },
                    style: {
                        "&:hover": {
                            background:
                                "linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFFFFF",
                        },
                        background: WHITE_000,
                        border: `2px solid ${GREY_200}`,
                        borderRadius: "3rem",
                        color: BLUE_700,
                        width: "100%",
                    },
                },
                {
                    props: { variant: "tertiary" },
                    style: {
                        "&:hover": {
                            borderRadius: "30px",
                        },
                        background: WHITE_000,
                        border: "none",
                        color: BLUE_700,
                        width: "100%",
                    },
                },
            ],
        },
    },
});

export default theme;

import {
    ComputePaymentDTO,
    ComputePaymentSummaryResponse,
    PaymentInstrument,
    PostPaymentDTO,
    PostPaymentResponse,
} from "./Payments.model";
import { IMeadowHttpFactory, meadowHttpFactory } from "./meadowHttpFactory";

export type IPaymentService = {
    makePaymentMethod(token: string): Promise<any>;
};

export class PaymentService implements IPaymentService {
    private http: IMeadowHttpFactory;
    public constructor(http?: IMeadowHttpFactory) {
        this.http = http || meadowHttpFactory({});
    }

    public async makePaymentMethod(token: string): Promise<PaymentInstrument> {
        const result = await this.http.post("/v1/payments/methods", { hostedFieldsToken: token });

        return result.data;
    }

    public async createTransfer(transfer: PostPaymentDTO): Promise<PostPaymentResponse> {
        const result = await this.http.post("/v1/payments", transfer);

        return result.data;
    }

    public async getSummary(
        summaryBody: ComputePaymentDTO
    ): Promise<ComputePaymentSummaryResponse> {
        const result = await this.http.post("/v1/payments/summary", summaryBody);

        return result.data;
    }
}
